import React from 'react'
import { withRouter } from 'react-router-dom'
import './Footer.sass'
import EmailSubscribe from './Components/EmailSubscribe'
import Company from './Components/Company'
import Resources from './Components/Resources'
import AppLinks from './Components/AppLinks'
import Logo from '../Logo/Logo'
import SocialLinks from '../SocialLinks/SocialLinks'

const Footer = ({ restricted }) => (
  <div className='footer' style={restricted ? { pointerEvents: 'none' } : {}}>
    <div className='topContent'>
      <EmailSubscribe />
      <Company />
      <Resources />
      <AppLinks />
    </div>
    <div className='bottomContent'>
      <Logo />
      <p>Copyright © {new Date().getFullYear()}, LivDemy LLC.</p>
      <SocialLinks />
    </div>
  </div>
)

export default withRouter(Footer)
