import { Container } from 'unstated'
import { getPremiumCourse } from '../../../API/Courses'

const initialState = {
  loading: true,
  loadFailed: false,
  PremiumCourse: [],
}

export default class PremiumCourseStore extends Container {
  name = 'premiumCourseStore'

  state = initialState

  init = async () => {
    const PremiumCourse = await getPremiumCourse()
    PremiumCourse = PremiumCourse.filter((course) => !course.isGroupCourse)
    if (PremiumCourse) {
      await this.setState({
        loading: false,
        PremiumCourse: PremiumCourse,
      })
    } else {
      await this.setState({ loading: false, loadFailed: true })
    }
  }
}
