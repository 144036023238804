export const accent = '#EC9871'
export const accentMid = '#E27E5D'
export const darkAccent = '#C7402D'
export const background0 = '#000000'
export const background1 = '#101010'
export const background2 = '#202020'
export const red = 'rgb(255,78,67)'
export const nobel = '#9C9C9C'
export const gray97 = '#f7f7f7'
export const lightgrey = '#A7A4A7'
export const lightText = '#9B9B9B'
