import { API } from './config'
import { getField } from '../Helpers/Misc'
import { getLocalCurrency } from './Payment'

export const getSearchResults = async (query) =>
  API.get(`/search?query=${query}`, {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then((res) => getField(res, 'data.data'))
    .catch(() => false)
