import React from 'react'

const Banner = () => (
  <div className='banner'>
    <div className='stat'>
      <div className='icon' />
      <div className='details'>
        <p className='heading'>300 Courses</p>
        <p className='description'>from the professional teachers</p>
      </div>
    </div>
    <div className='stat'>
      <div className='icon' />
      <div className='details'>
        <p className='heading'>50 Teachers</p>
        <p className='description'>professional teachers worldwide</p>
      </div>
    </div>
    <div className='stat'>
      <div className='icon' />
      <div className='details'>
        <p className='heading'>60 Minute Classes </p>
        <p className='description'>
          average per <br />
          lesson
        </p>
      </div>
    </div>
  </div>
)

export default Banner
