import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import Option from './Options/Option'
import '../SearchBox.sass'
import { Subscribe } from 'unstated'
import SearchBoxStore from '../Store/SearchBoxStore'
import { withRouter } from 'react-router-dom'

const SearchInput = ({ history }) => {
  const [query] = useState(null)

  return (
    <Subscribe to={[SearchBoxStore]}>
      {({ loadOptions }) => (
        <div className='searchInput'>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions={[]}
            value={query}
            placeholder={'Search for teacher, course or instrument'}
            onChange={(option) => {
              history.push(option.value)
            }}
            components={{
              Option: Option,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              NoOptionsMessage: () => (
                <p className={'noOptionsMessage'}>
                  {!query
                    ? 'Enter a keyword to search'
                    : 'Failed to load results'}
                </p>
              ),
            }}
          />
        </div>
      )}
    </Subscribe>
  )
}

export default withRouter(SearchInput)
