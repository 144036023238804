import React from 'react'
import { default as RatingComponent } from '../../Rating/Rating'

const Rating = ({ placeholder, field = {}, form = {} }) => {
  return (
    <div className='inputWrapper rating'>
      <p className={'placeholder active'}>{placeholder}</p>

      <RatingComponent
        value={field.value || 0}
        showValue
        onChange={(value) => form.setFieldValue(field.name, value)}
      />

      <p className={'error'}>
        {form.errors && form.touched[field.name] && form.errors[field.name]}
      </p>
    </div>
  )
}

export default Rating
