import React, { useEffect, useState } from 'react'
import Card from './components/Card'
import { TestimonialEl } from './elements'
import { H3 } from '../../../../Styles/typography'
import useTime from '../../../../Helpers/CustomHooks/useTime'
import { AnimatePresence } from 'framer-motion'
import HomeStore from '../../Store/home-store'

const Testimonials = () => {
  const { reviews: { data: testimonials = [] } = {} } = HomeStore.useContainer()

  const updateInterval = useTime(5000)
  const [active, setActive] = useState(0)

  useEffect(() => {
    if (testimonials.length) {
      setActive((old) => (old + 1) % testimonials.length)
    }
  }, [testimonials.length, updateInterval])

  const data = [...testimonials, ...testimonials, ...testimonials].slice(
    active + testimonials.length - 1,
    active + testimonials.length + 2,
  )

  return (
    <TestimonialEl>
      <H3>Student Spotlight</H3>
      <div className='items'>
        <AnimatePresence>
          {data.map((testimonial, i) => (
            <Card key={testimonial.id} {...testimonial} position={i - 1} />
          ))}
        </AnimatePresence>
      </div>
    </TestimonialEl>
  )
}

export default Testimonials
