import axios from 'axios'
import { API } from './config'
import { getField } from '../Helpers/Misc'

export const getInvoices = async () =>
  API.get('/bills', {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then((res) =>
      res.data.data.sort((a, b) => b.bill.createdAt - a.bill.createdAt),
    )
    .catch(() => false)

export const getBills = async () =>
  API.get(`/dashboard/bills`, {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then()
    .catch(() => false)

export const getBill = async (id) =>
  API.get(`/bill/${id}`, {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then(async (res) => {
      const bill = res.data.data
      const localCurrency = await getLocalCurrency()
      return {
        ...bill,
        amount: bill.localAmount || bill.amount,
        currency: bill.localAmount ? localCurrency : bill.currency,
      }
    })
    .catch(() => false)

export const getCourseDetails = (crid) =>
  API.get(`/courses/student/${crid}`)
    .then(({ data }) => ({
      courseName: data.data.course.name,
      courseFee: data.data.course.fee,
      teacher: data.data.teacher,
    }))
    .catch(() => false)

export const getInvoiceInfo = async (invoiceId) =>
  API.get(`/bill/${invoiceId}`, {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then((res) => res.data.data)
    .catch(() => false)

export const getLocalCurrency = async () => {
  const currency = window.localStorage.getItem('currency')

  if (currency) {
    return currency === 'false' ? 'USD' : currency
  } else {
    const currency = await axios
      .get('https://ipapi.co/json/')
      .then((res) => getField(res, 'data.currency', 'USD'))
      .catch(() => 'USD')

    window.localStorage.setItem('currency', currency)

    return currency
  }
}

export const getExchangeRate = (local, foreign) =>
  API.get(`/currency?local=${local}&foreign=${foreign}`)
    .then((res) => 1 / res.data.data)
    .catch(() => false)

//send payment information,
export const recordPayment = ({ amount, currency, source, billId }) =>
  API.post('/pay', {
    amount,
    currency,
    source,
    billId,
  })
    .then((res) => res)
    .catch((e) => console.log(e))
