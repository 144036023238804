import React from 'react'
import { Link } from 'react-router-dom'
import { Portal } from 'react-portal'
import Logout from '../../Logout/Logout'

const UserContextMenuList = ({ showMenu, toggleMenu, blockedRoute }) =>
  showMenu ? (
    <Portal>
      <div
        className='userContextMenuListOverlay'
        onClick={() => toggleMenu(false)}
      >
        <div
          className='userContextMenuList'
          onClick={(e) => e.stopPropagation()}
        >
          <div className='listItem'>
            <Link to={'/dashboard/student/profile'}>
              <div className='userContentMenuItem'>
                <div className='icon profile' />
                <p>Profile</p>
              </div>
            </Link>
          </div>
          <div className='listItem'>
            <Link to={'/dashboard/student/courses'}>
              <div className='userContentMenuItem'>
                <div className='icon courses' />
                <p>My Courses</p>
              </div>
            </Link>
          </div>
          <div className='listItem'>
            <Logout withConfirmation={blockedRoute}>
              <div className='userContentMenuItem'>
                <div className='icon logout' />
                <p>Logout</p>
              </div>
            </Logout>
          </div>
        </div>
      </div>
    </Portal>
  ) : null

export default UserContextMenuList
