import { Container } from 'unstated'

const initialState = () => ({
  loading: true,
  loadFailed: false,
  profile: {
    loading: true,
    loadFailed: false,
    editing: false,
  },
  count: { student: {}, teacher: {} },
  uploadingPicture: false,
  teacher: false,
  mode: 'student',
})

export default class DashboardStore extends Container {
  name = 'DashboardStore'

  state = initialState()

  linkedStores = {}

  init = async (role) => {
    const isTeacher = role === 'TEACHER'

    await this.setState({
      count: initialState().count,
      teacher: isTeacher,
    })

    await this.setState({ loading: false })
  }

  updateCounters = (role, key, value) => {
    const { count } = this.state

    count[role][key] = value

    return this.setState({ count })
  }

  updateData = async (section, key, value) => {
    const data = this.state
    data[section] = this.state[section]
    data[section][key] = value
    await this.setState(data)
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
