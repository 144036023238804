import React, { useEffect } from 'react'
import './PremiumCourse.sass'
import withStore from '../../Components/Unstated/withStore'
import PremiumCourseStore from './Store/PremiumCourseStore'
import ComponentLoader from '../../Components/Loading/ComponentLoader'
import CourseList from '../../Components/Lists/CourseList'
import Footer from '../../Components/Footer/Footer'

const PremiumCourse = ({
  premiumCourseStore: {
    state: { loading, loadFailed, PremiumCourse },
    init,
  },
}) => {
  useEffect(() => {
    init()
  }, [init])

  return (
    <ComponentLoader loading={loading} loadFailed={loadFailed}>
      <div className='PremiumCourse'>
        <h1 className='sectionHeader'>Premium Courses</h1>
        <h2 className='sectionIntro'>
          LivDemy offers a wide range of courses that cater to various interests
          and abilities. Courses run up to 12 weeks with new sessions starting
          throughout the year. Our philosophy is simple - impart high-quality
          music lessons to students with the best experts through the most
          innovative methods. We promise you that you’ll be getting on-board an
          exciting and rewarding journey.
        </h2>
        <CourseList
          data={PremiumCourse}
          name={'Premium Course'}
          limit={PremiumCourse.length < 6 ? PremiumCourse.length : 6}
        />
        <Footer />
      </div>
    </ComponentLoader>
  )
}

export default withStore([PremiumCourseStore])(PremiumCourse)
