import React from 'react'
import FeatureCard from '../../../Components/Cards/FeatureCard'
import createProfile from '../../../Assets/Icons/New User/Home/create_a_profile.svg'
import findInstructor from '../../../Assets/Icons/New User/Home/find_an_instructor.svg'
import takeLesson from '../../../Assets/Icons/New User/Home/take_a_lesson.svg'

const data = [
  {
    icon: createProfile,
    title: 'Become a part of LivDemy',
    description: 'Sign up for free and create a profile',
  },
  {
    icon: findInstructor,
    title: 'Find your calling',
    description: 'Choose your instrument and find a teacher',
  },
  {
    icon: takeLesson,
    title: 'Start learning',
    description: 'Take your first lesson and discover music',
  },
]

const HowItWorks = () => (
  <div className='howItWorks'>
    <h1 className='sectionTitle'>How it works</h1>
    <p className='intro'>There are 3 easy steps to get started:</p>
    <div className='process'>
      {data.map(({ icon, title, description }, i) => (
        <FeatureCard
          key={'HowItWorksCard' + i}
          icon={icon}
          description={description}
          title={title}
        />
      ))}
    </div>
  </div>
)

export default HowItWorks
