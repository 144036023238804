import React from 'react'
import { Portal } from 'react-portal'
import NotificationElement from './NotificationElement'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { parseNotificationText } from '../../../Helpers/Notifications'
import Loading from '../../../Components/Loading/Loading'
import NotificationStore from '../Store/NotificationStore'

const NotificationsList = () => {
  const {
    loading,
    loadFailed,
    data = [],
    showList,
    markAllRead,
    toggleList,
    notificationClicked,
  } = NotificationStore.useContainer()

  const unreadCount = data.filter((notification) => !notification.read).length

  return (
    showList && (
      <Portal>
        <div className='notificationsOverlay' onClick={() => toggleList(false)}>
          <div
            className='notificationsList'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='notificationsListHeader'>
              <h1>Notifications</h1>
              {!!unreadCount && (
                <p className='markRead' onClick={markAllRead}>
                  Mark as read
                </p>
              )}
            </div>

            <PerfectScrollbar>
              <div className='notifications'>
                {!loading && !loadFailed ? (
                  data.map((notification) => (
                    <NotificationElement
                      key={notification.key}
                      createdAt={notification.createdAt}
                      message={parseNotificationText(
                        notification.message,
                        notification.data,
                      )}
                      read={notification.read}
                      picture={
                        notification.icon?.location || 'https://unsplash.it/400'
                      }
                      onClick={() => notificationClicked(notification)}
                    />
                  ))
                ) : (
                  <Loading />
                )}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </Portal>
    )
  )
}

export default NotificationsList
