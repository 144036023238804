import React from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'

const Dropdown = ({
  placeholder,
  field = {},
  form = {},
  options,
  creatable,
  ...props
}) => {
  const onChange = (option) => {
    form.setFieldValue && form.setFieldValue(field.name, option)
    form.setFieldTouched && form.setFieldTouched(field.name, true)
    return props.onChange ? props.onChange(option) : field.onChange(option)
  }

  return (
    <div className='inputWrapper'>
      {creatable ? (
        <Creatable
          value={typeof field.value === 'object' ? field.value : undefined}
          placeholder={placeholder}
          options={options}
          isDisabled={props.disabled}
          className='react-select-container'
          classNamePrefix='react-select'
          {...props}
          onChange={onChange}
          styles={customStyles}
        />
      ) : (
        <Select
          value={typeof field.value === 'object' ? field.value : undefined}
          placeholder={placeholder}
          className='react-select-container'
          classNamePrefix='react-select'
          options={options}
          isDisabled={props.disabled}
          {...props}
          onChange={onChange}
          styles={customStyles}
        />
      )}
      <p className={'error'}>
        {form.errors &&
          form.touched[field.name] &&
          JSON.stringify(form.errors[field.name])}
      </p>
    </div>
  )
}

export default Dropdown

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderBottom: '1px solid #cdcbcd',
    borderRadius: 0,
    boxShadow: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#2D2D2D',
    fontWeight: 300,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
}
