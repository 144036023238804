import React from 'react'
import {
  AvatarEl,
  AvatarImageEl,
  AvatarNameEl,
  SubscriberBadgeEl,
} from './Elements'
import { getInitials } from './Helpers'

const Avatar = ({ name, image, size, isSubscriber = false, ...props }) => {
  return (
    <AvatarEl size={size} image={image} {...props}>
      <AvatarNameEl size={size}>{getInitials(name)}</AvatarNameEl>
      <AvatarImageEl image={image} />
      {isSubscriber && <SubscriberBadgeEl>Pro</SubscriberBadgeEl>}
    </AvatarEl>
  )
}

export default Avatar
