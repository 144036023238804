export const headerSlides = [
  {
    bannerId: '-M_UO4JL126Av0PL9GhK',
    heading: ['In conversation with a legend:', 'Zakir Hussain'],
    para: [
      'The legendary Zakir Hussain talks to renowned vocalist Mahesh Kale about his views on Music, his learnings from the past, and how he fell in love with the Rhythm of Music.',
    ],
    image:
      'https://livdemybucket.s3.amazonaws.com/zakir-LivDemy-Banner-Images.jpg%232021%235%2312%23undefined',
    cta: {
      name: 'Watch Now',
      route: '/maheshkale/btm/in-conversation-with-zakir-hussain',
    },
    position: 6,
  },
  {
    bannerId: '-Mh9aZ3GRkmmIJG7jsoK',
    heading: ['Elevate your Bass Skills with Mohini Dey'],
    para: ['Join Mohini Dey as she breaks down two Indian classics'],
    image:
      'https://livdemybucket.s3.amazonaws.com/website+cover+mohini.png%232021%238%2314%23undefined',
    cta: {
      name: 'Join Now',
      route: '/mohinidey/masterclass/elevate-your-bass-skills/1',
    },
    position: 3,
  },
  {
    bannerId: '-Mh9bEYMX4FdxmjFTDLw',
    heading: ['Rhythm and Taal in Hindustani Music'],
    para: [
      'Ronkini Gupta teaches what are the Rhythms and Taals in Hindustani Music',
    ],
    image:
      'https://livdemybucket.s3.amazonaws.com/website+cover+ronkini.png%232021%238%2314%23undefined',
    cta: {
      name: 'Sign Up',
      route: '/ronkinigupta/workshop/rhythm-and-taal-in-hindustani-music/1',
    },
    position: 4,
  },
  {
    bannerId: '-MiaJfi2V5yqatbE066R',
    heading: ['', 'Ghazal - From Poem to Performance'],
    para: ['Hariharan is back on LivDemy breaking down Ghazal!'],
    image:
      'https://livdemybucket.s3.amazonaws.com/hariharan+banner.png%232021%239%231%23undefined',
    cta: {
      name: 'Join Now',
      route: '/hariharan/masterclass/ghazal-from-poem-to-performance/1',
    },
    position: '1',
  },
  {
    bannerId: '-MikQ0TNUoCx2e9FXHwA',
    heading: ['Yashraj Mukhate on LivDemy'],
    para: [
      'Attend this session to know about Yashraj and how his music made him viral!',
    ],
    image:
      'https://livdemybucket.s3.amazonaws.com/Yashraj+Banner.png%232021%239%234%23undefined',
    cta: {
      name: 'Register For Free',
      route: '/blitzkreigm/btm/behind-the-music-and-the-virality/1',
    },
    position: 2,
  },
  {
    bannerId: '-MjIU3BINnufF9YNEJir',
    heading: ['Sniti Mishra teaches us Yaad Piya ki Aye'],
    para: ['Learn Yaad Piya ki Aye via the Raag Approach by Sniti Mishra'],
    image:
      'https://livdemybucket.s3.amazonaws.com/Sniti+Banner.png%232021%239%2310%23undefined',
    cta: {
      name: 'Join Now',
      route: '/snitimishra/workshop/yaad-piya-ki-aye-the-raag-approach/1',
    },
    position: '4',
  },
  {
    bannerId: '-MjnCv73WiVOw6-au-s5',
    heading: ['Become a LivDemy Pro!'],
    para: ['Get unlimited access to all of LivDemy.'],
    image:
      'https://livdemybucket.s3.amazonaws.com/Livdemy+Pro+Banner.png%232021%239%2317%23undefined',
    cta: { name: 'Learn More', route: '/pricing' },
    position: '7',
  },
].sort((a, b) => (a?.position || 0) - (b?.position || 0))
