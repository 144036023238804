import React from 'react'
import '../UserAuth.sass'
import SectionDesign from './SectionDesign'
import { Link, withRouter } from 'react-router-dom'
import { Field, Form, withFormik } from 'formik'
import * as Yup from 'yup'
import {
  isRequired,
  string,
} from '../../../Helpers/Form Validations/Validation Error Messages'
import withStore from '../../../Components/Unstated/withStore'
import UserStore from '../Store/UserStore'
import Input from '../../../Components/Input/Input'
import Button from '../../../Components/Buttons/Button'
import { notify } from '../../../Helpers/Notifications'

const ResetPassword = () => {
  return (
    <div className='resetPassword'>
      <SectionDesign
        subTitle={<span>Create a new password</span>}
        footer={
          <>
            <span>
              Already a member? <Link to={'/auth/login'}>Sign in.</Link>
            </span>
          </>
        }
      >
        <div className='passwordLogin'>
          <Form>
            <Field
              name='password'
              render={(props) => (
                <Input {...props} type='password' placeholder='New Password' />
              )}
            />
            <Field
              name='confirmPassword'
              render={(props) => (
                <Input
                  {...props}
                  type='password'
                  placeholder='Confirm password'
                />
              )}
            />
            <Button
              onClick={() => {}}
              name='Submit'
              type={'primary'}
              buttonType='submit'
            />
          </Form>
        </div>
      </SectionDesign>
    </div>
  )
}

export default withRouter(
  withStore([UserStore])(
    withFormik({
      mapPropsToValues: () => ({
        password: '',
        confirmPassword: '',
      }),
      validationSchema: () =>
        Yup.object().shape({
          password: Yup.string(string)
            .min(6, 'Password should be greater than 6 characters in length')
            .required(isRequired('New password')),
          confirmPassword: Yup.string(string).required(
            isRequired('Confirm password'),
          ),
        }),
      handleSubmit: async (values, { props, setSubmitting }) => {
        if (values.password !== values.confirmPassword) {
          notify('error', 'The passwords do not match')
          return
        }

        setSubmitting(true)

        const success = await props.userStore.resetPassword(
          values.password,
          props.match.params.token,
        )

        if (success) {
          props.history.push('/auth/login')
        }

        setSubmitting(false)
      },
    })(ResetPassword),
  ),
)
