import { API } from './config'
import { parseCourseListData } from '../Helpers/API'
import { getField } from '../Helpers/Misc'
import { getLocalCurrency } from './Billing'

export const getAllCourses = async () =>
  API.get(`/courses`, {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then(async (res) =>
      parseCourseListData(getField(res, 'data.data.courses'), undefined, true),
    )
    .catch(() => false)
export const getPremiumCourse = async () =>
  API.get(`/courses/premium`, {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then(async (res) => {
      return parseCourseListData(
        getField(res, 'data.data.recordedCourses'),
        true,
        undefined,
      )
    })
    .catch(() => false)
