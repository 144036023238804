import React, { useEffect, useState } from 'react'
import './UpcomingClass.sass'
import { Link, withRouter } from 'react-router-dom'
import { Portal } from 'react-portal'
import withStore from '../../Components/Unstated/withStore'
import UpcomingClassStore from './Store/UpcomingClassStore'
import UserStore from '../../App/UserAuth/Store/UserStore'
import Button from '../../Components/Buttons/Button'

const UpcomingClass = ({
  upcomingClassStore: { state, init },
  userStore: {
    state: {
      id: userID,
      role,
      profile: { name: userName },
    },
  },
  location,
}) => {
  const [isMinimized, minimize] = useState(false)

  useEffect(() => {
    init(userID, role, userName)
  }, [userID, role, userName, init])

  return !(
    location.pathname.includes('/class') || location.pathname.includes('/entry')
  ) && state.upcoming ? (
    <Portal>
      <div className={'upcomingClass' + (isMinimized ? ' minimized' : '')}>
        <div className='top' onClick={() => minimize(false)}>
          <h1>
            {state.upcoming.startTime > new Date().getTime()
              ? 'Upcoming'
              : 'Ongoing'}{' '}
            Class Alert!
          </h1>
          <Link to={state.upcoming.url}>
            <Button onClick={() => {}} name={'Go to class'} type={'primary'} />
          </Link>
          <div
            className='minimize'
            onClick={(e) => {
              e.stopPropagation()
              minimize(true)
            }}
          />
        </div>

        <div className='content'>
          {state.upcoming.demo ? (
            <p>
              It's time for a demo class with <span>{state.upcoming.with}</span>
              . Head to the classroom now!
            </p>
          ) : (
            <p>It's time for a class. Head to the classroom now!</p>
          )}

          <Link to={state.upcoming.url}>
            <Button onClick={() => {}} name={'Go to class'} type={'primary'} />
          </Link>
        </div>
      </div>
    </Portal>
  ) : null
}

export default withStore([UserStore, UpcomingClassStore])(
  withRouter(UpcomingClass),
)
