import React, { useState } from 'react'
import { Subscribe } from 'unstated'
import UserStore from '../../App/UserAuth/Store/UserStore'
import { withRouter } from 'react-router-dom'
import Confirmation from '../Confirmation/Confirmation'
import { generateConfirmation } from '../Confirmation/Helpers'

const Logout = ({ children, history, withConfirmation }) => {
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Subscribe to={[UserStore]}>
      {({ logout }) => (
        <div
          className='logout'
          onClick={async () => {
            if (!withConfirmation) {
              await logout()
              history.push('/')
            } else {
              const confirmation = generateConfirmation(
                async () => {
                  await logout()
                  history.push('/')
                  setConfirmation(null)
                },
                () => {
                  setConfirmation(null)
                },
              )

              setConfirmation(confirmation)
            }
          }}
        >
          {children}
          {confirmation && <Confirmation promise={confirmation} />}
        </div>
      )}
    </Subscribe>
  )
}

export default withRouter(Logout)
