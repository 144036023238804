import { Container } from 'unstated'
import { startMasterclass } from '../../../API/Broadcast'
import { notify } from '../../../Helpers/Notifications'

const initialState = () => ({
  loading: true,
  loadFailed: false,
  userId: '',
  token: '',
  sessionId: '',
  teacherID: '',
  teacher: {
    name: '',
  },
  canRegister: false,
  livdemyId: '',
  createdAt: 0,
  description: '',
  endTime: 0,
  id: '',
  name: '',
  session: null,
  startTime: 0,
  teacherId: '',
  updatedAt: 0,
  count: 0,
  paidCount: 0,
  registrations: [],
  groupChatId: '',
  students: [],
  localFee: null,
  questions: [],
  classSnippets: [],
  participants: [],
  extended: 0,
  liveChatStudent: false,
  liveChatTeacher: false,
  raiseHandRequests: [],
  liveChatParticipant: null,
})

export default class MasterclassStore extends Container {
  name = 'MasterclassStore'

  state = initialState()

  linkedStores = {}

  start = async (livDemyID) => {
    const { teacherID } = this.state
    if (teacherID === this.linkedStores.UserStore.state.id) {
      const started = await startMasterclass(livDemyID)

      if (started) {
        notify('success', 'Masterclass started!')
      }
    }
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
