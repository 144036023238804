import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'

const RTFEditor = ({
  placeholder,
  field = {},
  form = {},
  options,
  type,
  ...props
}) => {
  const [editorText, updateEditor] = useState('')
  const [loading, setLoading] = useState(false)

  const valueHandler = useRef()

  useEffect(() => {
    if (type === 'editor') valueHandler.current = field.value || props.value
  }, [type, props.value, field.value])

  let className =
    loading && props.editstate
      ? 'loadingEditor'
      : !loading && props.editstate && form.touched[field.name]
      ? 'savedEditor'
      : props.className

  return (
    <div className='inputWrapper editor'>
      <span className='placeholder'>{placeholder}</span>
      <ReactQuill
        defaultValue={field.value || editorText}
        placeholder={placeholder}
        theme={'snow'}
        readOnly={props.disabled}
        modules={
          props.seperator
            ? {
                toolbar: [
                  ['bold', 'italic', 'underline'],
                  ['separator'],
                  ['link', { list: 'ordered' }, { list: 'bullet' }],
                ],
              }
            : {
                toolbar: [
                  ['bold', 'italic', 'underline'],
                  ['link', { list: 'ordered' }, { list: 'bullet' }],
                ],
              }
        }
        {...props}
        className={className}
        onChange={async (value) => {
          updateEditor(value)
          setLoading(true)
          form.setFieldValue && form.setFieldValue(field.name, value)
          form.setFieldTouched && form.setFieldTouched(field.name, true)
          props.onChange
            ? await props.onChange(value)
            : await field.onChange(value)
          setLoading(false)
        }}
        onBlur={(_, __, editor) => {
          props.onChange && props.onChange(editor.getHTML())
          form.setFieldValue &&
            form.setFieldValue(
              field.name,
              editor.getLength() > 1 ? editor.getHTML() : '',
            )
          form.setFieldTouched && form.setFieldTouched(field.name, true)
        }}
      />
      <p className={'error'}>
        {form.errors && form.touched[field.name] && form.errors[field.name]}
      </p>
    </div>
  )
}

export default RTFEditor
