import React from 'react'
import { BackgroundEl } from '../elements'
import { AnimatePresence, motion } from 'framer-motion'

const Background = ({ bg }) => (
  <AnimatePresence exitBeforeEnter>
    <BackgroundEl
      key={bg}
      as={motion.img}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      src={bg}
    />
  </AnimatePresence>
)

export default Background
