import axios from 'axios'
import baseUrl from '../config'
import { getField } from '../../Helpers/Misc'
import { debounce } from 'throttle-debounce'

export const getSignedInUser = () => {
  const user = JSON.parse(window.localStorage.getItem('user'))
  return user ? user : null
}

export const verifyEmailForLogin = (email) =>
  axios
    .post(baseUrl + '/auth/login/regular/verify-email', { email })
    .then(() => true)
    .catch(() => false)

export const verifyEmailForLoginDebounced = debounce(500, verifyEmailForLogin)

export const verifyEmailForSignUp = (email) =>
  axios
    .post(baseUrl + '/auth/signup/regular/verify-email', { email })
    .then(() => true)
    .catch(() => false)

export const verifyEmailForSignUpDebounced = debounce(500, verifyEmailForSignUp)

export const loginWithEmail = (data) =>
  axios
    .post(baseUrl + '/auth/login/regular/step1', data)
    .then((response) => ({
      ...response.data.data,
      emailLogin: true,
      picture: getField(response, 'data.data.profile.profilePhoto.location'),
    }))
    .catch(() => false)

export const googleAuth = (token) =>
  axios
    .post(baseUrl + '/auth/signup/google/step1', { token })
    .then((response) => {
      const user = getField(response, 'data.data')
      return (
        {
          ...user,
          picture: getField(user, 'profile.profilePhoto.location'),
        } || false
      )
    })
    .catch(() => false)

export const fbAuth = (token) =>
  axios
    .post(baseUrl + '/auth/signup/fb/step1', { token })
    .then((response) => {
      const user = getField(response, 'data.data')
      return (
        {
          ...user,
          picture: getField(user, 'profile.profilePhoto.location'),
        } || false
      )
    })
    .catch(() => false)

export const signUpWithEmail = (data) =>
  axios
    .post(baseUrl + '/auth/signup/regular/step1', data)
    .then(() => true)
    .catch(() => false)

export const saveDetails = (data) =>
  axios
    .post(baseUrl + '/auth/step2', data)
    .then((response) => getField(response, 'data.data'))
    .catch(() => false)

export const geocode = (address) =>
  axios
    .get(
      'https://open.mapquestapi.com/geocoding/v1/address?key=kSQ6PlzSH8JcqcmkKYGQxd8F7tpgi25Z&location=' +
        address,
    )
    .then((results) =>
      results.data.results.length
        ? results.data.results[0].locations[0].latLng
        : false,
    )
    .catch(() => false)
