export const generateConfirmation = (
  affirmative = () => {},
  negative = () => {},
) => {
  let responses = {}

  const confirmation = new Promise((resolve, reject) => {
    responses = {
      resolve,
      reject,
    }
  })

  confirmation.then(affirmative).catch(negative)

  return responses
}
