import { API } from './config'
import { getField } from '../Helpers/Misc'
import { parseCourseListData } from '../Helpers/API'

export const recordedClasses = [
  {
    amount: 800,
    canSellRecording: true,
    coverImage: {
      location:
        'https://livdemybucket.s3.amazonaws.com/Ronkini+Gupta+02.jpg%232021%231%2330%23undefined',
    },
    currency: 'INR',
    discount: 25,
    endTime: 1612072800000,
    fee: 800,
    id: '-MP3WDawfyO152-BawZM',
    livdemyId: 'ronkinigupta',
    name: 'Techniques of Improvisation in Khayal',
    recordingForSale: true,
    startTime: 1612065600000,
    teacherName: 'Ronkini Gupta',
    type: 'workshop',
    url: '/ronkinigupta/workshop/techniques-of-improvisation-in-khayal/1',
    version: '1',
  },
  {
    amount: 800,
    canSellRecording: true,
    coverImage: {
      location:
        'https://livdemybucket.s3.amazonaws.com/Rasika-Shekar-08.jpg%232020%238%2317%23undefined',
    },
    currency: 'INR',
    discount: 25,
    endTime: 1598801400000,
    fee: 800,
    id: '-MEi6V1nFZxaZjc2sAwe',
    livdemyId: 'rasikashekar',
    name: 'The Bamboo Flute - Techniques and Practice Methods',
    recordingForSale: true,
    startTime: 1598796000000,
    teacherName: 'Rasika Shekar',
    type: 'workshop',
    url:
      '/rasikashekar/workshop/the-bamboo-flute-techniques-and-practice-methods/1',
    version: '1',
  },
  {
    amount: 800,
    amountAfterDiscount: 750,
    canSellRecording: true,
    coverImage: {
      location:
        'https://s3.us-east-1.amazonaws.com/livdemybucket/13+%281%29.jpeg%232021%239%232%23undefined',
    },
    currency: 'INR',
    discount: 25,
    endTime: 1632673800000,
    fee: 800,
    id: '-MiVVqaEMA3Z5Kf0ZeQn',
    livdemyId: 'hariharan',
    name: 'Ghazal - From Poem to Performance',
    recordingForSale: true,
    startTime: 1632668400000,
    teacherName: 'Hariharan',
    type: 'master-class',
    url: '/hariharan/masterclass/ghazal-from-poem-to-performance/1',
    version: 1,
  },
  {
    amount: 800,
    amountAfterDiscount: 374.75,
    canSellRecording: true,
    coverImage: {
      location:
        'https://livdemybucket.s3.amazonaws.com/salim.png%232021%235%231%23undefined',
    },
    currency: 'INR',
    discount: 25,
    endTime: 1622394000000,
    fee: 800,
    id: '-MZNtF9dmhgGhirwTotw',
    livdemyId: 'salimmerchant',
    name: 'Deconstructing O Re Piya - Production, Composition and Arrangement',
    recordingForSale: true,
    startTime: 1622388600000,
    teacherName: 'Salim Merchant',
    type: 'master-class',
    url:
      '/salimmerchant/masterclass/deconstructing-o-re-piya-production-composition-and-arrangement/1',
    version: 1,
  },
  {
    amount: 800,
    amountAfterDiscount: 562.5,
    canSellRecording: true,
    coverImage: {
      location:
        'https://livdemybucket.s3.amazonaws.com/DSC_0004Emkmkmk.JPG%232020%2311%2323%23undefined',
    },
    currency: 'INR',
    discount: 25,
    endTime: 1607790600000,
    fee: 800,
    id: '-MMqsAmsxnu4u0k_0aN8',
    livdemyId: 'maheshkale',
    name: 'Scaling up: Alankar methodology for fluency in music skills',
    recordingForSale: true,
    startTime: 1607785200000,
    teacherName: 'Mahesh Kale',
    type: 'master-class',
    url:
      '/maheshkale/masterclass/scaling-up-alankar-methodology-for-fluency-in-music-skills/1',
    version: 1,
  },
  {
    id: '-M6Zt5rXAfp9jJ_GDkwg',
    coverImage: {
      location:
        'https://livdemybucket.s3.amazonaws.com/Untitled+design.png%232021%238%2326%23undefined',
    },
    name: 'GeoShred Masterclass - Learn to play music on your iPad',
    artist: 'Mahesh Raghvan',
    fee: 800,
    amount: 800,
    currency: 'INR',
    discount: 25,
    startTime: 1589553000000,
    endTime: 1589558400000,
    type: 'master-class',
    medium: 'English',
    livdemyId: 'followingmahesh',
    url:
      '/followingmahesh/masterclass/geoshred-masterclass-1-learn-to-play-music-on-the-ipad/1',
    version: '1',
    canSellRecording: true,
    isSeries: false,
  },
]

export const getAllReviews = async () =>
  API.get(`/review/v2/WHITELISTED/limit/20`)
    .then((res) =>
      res.data.data.map(({ entityInfo, review, teacherInfo, userInfo }, i) => ({
        id: `review-${i}`,
        courseName: entityInfo?.title,
        review: review,
        teacherName: teacherInfo?.userInfo?.name,
        userName: userInfo?.name,
        userProfilePicture: userInfo?.profilePicture,
      })),
    )
    .catch(() => false)

export const getFeaturedCourses = async () =>
  API.get(`/home/landing`)
    .then(async (res) => {
      const courses = parseCourseListData(getField(res, 'data.data.courses'))
      const recordedCourses = parseCourseListData(
        getField(res, 'data.data.recordedCourses', []),
        true,
      )

      return {
        reviews: res.data.data.reviews,
        masterclasses: (res.data.data.masterClasses || []).map((mc) => ({
          ...mc,
          fee: mc.amount,
          teacher: {
            name: mc.teacherName,
            livdemyId: mc.livdemyId,
            coverImage: mc.coverImage,
          },
        })),
        workshops: (res.data.data.workshop || []).map((ws) => ({
          ...ws,
          fee: ws.amount,
          type: 'workshop',
          teacher: {
            name: ws.teacherName,
            livdemyId: ws.livdemyId,
            coverImage: ws.coverImage,
          },
        })),
        demo: (res.data.data.demo || []).map((ws) => ({
          ...ws,
          fee: ws.amount,
          type: 'demo',
          teacher: {
            name: ws.teacherName,
            livdemyId: ws.livdemyId,
            coverImage: ws.coverImage,
          },
        })),
        btm: (res.data.data.btm || []).map((ws) => ({
          ...ws,
          fee: ws.amount,
          type: 'btm',
          teacher: {
            name: ws.teacherName,
            livdemyId: ws.livdemyId,
            coverImage: ws.coverImage,
          },
        })),
        qna: (res.data.data.qna || []).map((qna) => ({
          ...qna,
          fee: qna.amount,
          type: 'qna',
          teacher: {
            name: qna.teacherName,
            livdemyId: qna.livdemyId,
            coverImage: qna.coverImage,
          },
        })),
        concert: (res.data.data.concert || []).map((concert) => ({
          ...concert,
          fee: concert.amount,
          type: 'concert',
          teacher: {
            name: concert.teacherName,
            livdemyId: concert.livdemyId,
            coverImage: concert.coverImage,
          },
        })),
        recordedClasses,
        legends: (res.data.data.legend || []).map((ws) => ({
          ...ws,
          fee: ws.amount,
          type: 'master-class',
          teacher: {
            name: ws.teacherName,
            livdemyId: ws.livdemyId,
            coverImage: ws.coverImage,
          },
        })),
        courses,
        recordedCourses,
      }
    })
    .catch(() => false)
