import { Container } from 'unstated'
import { getSearchResults } from '../../../../API/Search'
import { getField, getUrlFriendlyString } from '../../../../Helpers/Misc'
import { Event } from '../../../../Helpers/GoogleAnalytics'
import { parseFromSearchKey } from '../../../../App/BroadcastClass/Helpers'

const initialState = () => ({
  searching: false,
  searchFailed: false,
  courses: [],
  teachers: [],
  instruments: [],
  masterclasses: [],
})

export default class SearchBoxStore extends Container {
  name = 'SearchBoxStore'

  state = initialState()

  search = async (query) => {
    await this.setState({
      ...initialState(),
      searching: true,
      searchFailed: false,
    })

    const results = await getSearchResults(query)

    if (results) {
      Event('Search', query)
      const { courses, teachers, instruments, masterClass } = results

      const parsedCourses = [
        ...courses.slice(0, 3).map(({ _source: { info, teacher, fee } }) => ({
          name: info.title,
          teacher: teacher.profile.name,
          fee: `${fee.currency} ${fee.amount}`,
          duration: info.noOfClasses + ' hours',
          url: `/${teacher.approved.livdemyId}/course/${info.courseUrl}`,
          cover: info.courseCover
            ? getField(
                info.courseCover[Object.keys(info.courseCover).slice(-1)[0]],
                'metaData.s3.extra.Location',
              )
            : '',
        })),
        ...masterClass.map(
          ({
            currency,
            fee: { amount: fee },
            name,
            startTime,
            endTime,
            teacher,
            type,
            url,
            version = 1,
          }) => ({
            name: name,
            teacher: teacher?.userInfo?.name,
            fee: `${currency} ${fee?.amount}`,
            duration: `${((endTime - startTime) / 60) * 1000} hour`,
            cover: teacher?.coverImage?.location,
            url: `/${teacher?.livdemyId}/${
              parseFromSearchKey(type)?.url
            }/${url}/${version}`,
          }),
        ),
      ]

      const parsedTeachers = teachers
        .slice(0, 3)
        .map(({ _source: { profile, approved } }) => ({
          name: profile.name,
          picture: profile.profilePhoto
            ? getField(
                profile.profilePhoto[
                  Object.keys(profile.profilePhoto).slice(-1)[0]
                ],
                'metaData.s3.extra.Location',
              )
            : '',
          place: profile.city,
          rating: 0,
          instruments: [],
          url: `/${approved.livdemyId}`,
        }))

      const parsedInstruments = instruments
        .slice(0, 3)
        .map(({ _source: { cat } }) => {
          return {
            name: cat,
            url: `/categories/${getUrlFriendlyString(cat)}`,
          }
        })

      await this.setState({
        courses: parsedCourses,
        teachers: parsedTeachers,
        instruments: parsedInstruments,
        searching: false,
        searchFailed: false,
      })

      return {
        teachers: parsedTeachers,
        courses: parsedCourses,
        instruments: parsedInstruments,
      }
    } else {
      await this.setState({
        searching: false,
        searchFailed: true,
      })
    }
  }

  searchResults = (inputValue, options) => {
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    )
  }

  loadOptions = async (inputValue, callback) => {
    const results = await this.search(inputValue)

    if (results) {
      callback([
        {
          label: `Search all for ${inputValue}`,
          value: `/search/${inputValue}`,
          isCourse: false,
          isTeacher: false,
        },
        ...results.courses.map(({ name, url }) => ({
          label: name,
          value: url,
          isCourse: true,
          isTeacher: false,
          isInstrument: false,
        })),
        ...results.teachers.map(({ name, url }) => ({
          label: name,
          value: url,
          isCourse: false,
          isTeacher: true,
          isInstrument: false,
        })),
        ...results.instruments.map(({ name, url }) => ({
          label: name,
          value: url,
          isCourse: false,
          isTeacher: false,
          isInstrument: true,
        })),
      ])
    } else {
      callback([])
    }
  }
}
