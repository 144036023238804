import React from 'react'
import { Link } from 'react-router-dom'
import { getPriceFromMatrix } from '../../Helpers/Price'
import './Card.sass'

const CourseCard = ({
  cover,
  name,
  teacher,
  artist,
  teacherName,
  fee,
  currency,
  type,
  duration,
  discount = 0,
  onClick = () => {},
  url,
  index,
  isGroupCourse,
  priceMatrix,
}) => {
  const recorded = !duration

  fee = getPriceFromMatrix(
    priceMatrix,
    typeof fee === 'number' ? { amount: fee, currency } : fee,
  )

  return (
    <Link to={url}>
      <div
        className={'courseCard type-' + (isGroupCourse ? 'group' : '')}
        onClick={() => onClick(index)}
      >
        {isGroupCourse && (
          <div className='groupCourseTag'>
            <span /> Group Course
          </div>
        )}
        <div
          className='courseImage'
          style={{
            background: `url(${cover}) center center /cover no-repeat`,
          }}
        />
        <div className='courseDetails'>
          <h1 className='courseName'>{name}</h1>
          <p className='teacherName'>
            By <span>{teacher || artist || teacherName}</span>
          </p>
          <div className='row'>
            <div className='duration'>
              {type !== 'recorded' && !recorded && (
                <>
                  <span /> {duration} hours
                </>
              )}
            </div>
            <div className={'fee' + (discount ? ' withDiscount' : '')}>
              <span className='original'>
                {fee?.currency} {Math.ceil(fee?.amount / Number(duration || 1))}
                {!recorded && `/class`}
              </span>
              <br />
              {!!discount && (
                <span className={'discounted'}>
                  {fee?.currency}
                  {Math.ceil(
                    (fee?.amount * (1 - discount / 100)) /
                      Number(duration || 1),
                  )}
                  {!recorded && `/class`}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default CourseCard
