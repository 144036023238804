import React from 'react'
import axios from 'axios'
import { notify } from '../../../Helpers/Notifications'
import Button from '../../Buttons/Button'

let jsonpAdapter = require('axios-jsonp')

const EmailSubscribe = () => (
  <div className='subscribe'>
    <p>
      Get LivDemy updates to your inbox. <br />
      Learn from creative experts at your convenience!
    </p>
    <div id='mc_embed_signup'>
      <form
        id='mc-embedded-subscribe-form'
        name='mc-embedded-subscribe-form'
        className='validate'
        target='_blank'
        noValidate
        onSubmit={(e) => {
          e.preventDefault()

          axios({
            url: `https://livdemy.us18.list-manage.com/subscribe/post-json?u=b48b247322ac46f5981a9e716&amp;id=b89d49837a&EMAIL=${e.target.EMAIL.value.trim()}`,
            adapter: jsonpAdapter,
            callbackParamName: 'c',
          })
            .then(({ data }) => {
              notify(data.result === 'error' ? 'error' : 'success', data.msg)
            })
            .catch((e) => console.log(e.message))

          e.target.reset()
        }}
      >
        <div id='mc_embed_signup_scroll'>
          <div className='mc-field-group'>
            <input
              type='email'
              name='EMAIL'
              className='required email'
              id='mce-EMAIL'
              placeholder={'Email Address'}
            />
          </div>
          <div id='mce-responses' className='clear'>
            <div
              className='response'
              id='mce-error-response'
              style={{ display: 'none' }}
            />
            <div
              className='response'
              id='mce-success-response'
              style={{ display: 'none' }}
            />
          </div>
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden='true'
          >
            <input type='text' name='b_b48b247322ac46f5981a9e716_b89d49837a' />
          </div>
          <div className='clear'>
            <Button
              onClick={() => {}}
              type={'primary'}
              buttonType='submit'
              name='Subscribe'
              id='mc-embedded-subscribe'
            />
          </div>
        </div>
      </form>
    </div>
  </div>
)

export default EmailSubscribe
