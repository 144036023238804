import { API } from './config'

export const unsubscribeEmail = (token) =>
  API.put(`/user/unsubscribe/emails/${token}`)
    .then(() => true)
    .catch(() => false)

export const notificationPref = (data) =>
  API.put(`/user/notification/preference`, data)
    .then(() => true)
    .catch(() => false)
