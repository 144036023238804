import ReactPixel from 'react-facebook-pixel'

const options = {
  autoConfig: true,
  debug: false,
}

const initPixel = () => {
  if (process.env.REACT_APP_PIXEL_ID) {
    ReactPixel.init(process.env.REACT_APP_PIXEL_ID, {}, options)

    ReactPixel.pageView()
  }
}

// ReactPixel.track(event, data);
// ReactPixel.trackSingle("PixelId", event, data);
// ReactPixel.trackCustom(event, data);
// ReactPixel.trackSingleCustom("PixelId", event, data);

export default initPixel
