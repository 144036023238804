import React from 'react'
import PropTypes from 'prop-types'
import Loading from './Loading'
import LoadFailed from './LoadFailed'

const ComponentLoader = ({ loading, loadFailed, children }) => {
  return loading ? <Loading full /> : loadFailed ? <LoadFailed /> : children
}

export default ComponentLoader

ComponentLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadFailed: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
}
