import { Container } from 'unstated'
import {
  initCourse,
  submitCourseReview,
  submitTeacherReview,
} from '../../../../../API/Course'
import { notify } from '../../../../../Helpers/Notifications'

const initialState = () => ({
  loading: true,
  loadFailed: false,
  submittedReview: null,
  courseID: '',
  teacherID: '',
  courseInfo: {},
  success: false,
})

export default class LeaveReviewStore extends Container {
  name = 'LeaveReviewStore'

  state = initialState()

  linkedStores = {}

  init = async ({ livDemyID, courseURL }) => {
    console.log('initializing LeaveMasterclassReviewStore')
    await this.setState(initialState())

    const {
      teacher: { id: teacherID, name: teacherName },
      id: courseID,
      cover,
      name,
      description,
      rating: { value: rating },
      instruments,
    } = await initCourse(courseURL, livDemyID)

    const submittedReview = {}

    if (teacherID && courseID && submittedReview) {
      await this.setState({
        teacherID,
        courseID,
        submittedReview,
        courseInfo: {
          teacherName,
          instruments,
          cover,
          name,
          description,
          rating,
        },
        loading: false,
      })
    } else {
      await this.setState({ loading: false, loadFailed: true })
    }
  }

  submitReview = async (values) => {
    const { teacherID, courseID } = this.state
    const userID = this.linkedStores.UserStore.state.id

    let isValid = !!(values.courseReview || values.teacherReview)

    if (!isValid) {
      notify(
        'error',
        `At least one of 'Teacher Review' or 'Course Review must be filled'`,
      )
      return
    }
    const success = await Promise.all([
      values.teacherReview || values.teacherRating
        ? submitTeacherReview({
            teacherId: teacherID,
            courseId: courseID,
            userId: userID,
            rating: values.teacherRating,
            review: values.teacherReview,
          })
        : true,
      values.courseReview || values.courseRating
        ? submitCourseReview({
            teacherId: teacherID,
            courseId: courseID,
            userId: userID,
            rating: values.courseRating,
            review: values.courseReview,
          })
        : true,
    ])

    await this.setState({ success: success.every((value) => value) })

    return success.every((value) => value)
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
