import styled from 'styled-components'
import { hFlex, vFlex } from '../../../Styles/style-helpers'

export const EmailUnsubscribeEl = styled.div`
  ${vFlex};
  width: 100%;
  position: fixed;
  top: 0;
  height: 100%;
  background: white;
  padding: 0;
  z-index: 9999999999;

  .content {
    ${vFlex}
    justify-content: flex-start;
    width: 40%;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25);
    padding: 1em;

    > * {
      padding-bottom: 0.5em;
    }

    .btns {
      padding-top: 3.5em;
      width: 100%;
      ${hFlex}
      justify-content: space-evenly;
    }
  }
`
