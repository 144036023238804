import React from 'react'
import MasterclassList from '../../../Components/Lists/MasterclassList'
import { withRouter } from 'react-router-dom'
import { recordedClasses } from '../../../API/Landing'

const ClassRecordings = ({ history }) => {
  return (
    <div className='featuredMasterclasses'>
      <div className='sectionHeaderContainer'>
        <h1 className={'sectionHeader'}>Class Recordings</h1>

        <p className='sectionSubHeader'>
          Dive into the art of music at your own pace with our recorded
          masterclasses and workshops—expertly curated sessions delivering
          in-depth insights, demonstrations, and valuable knowledge to fuel your
          musical journey.
        </p>
      </div>
      <MasterclassList
        data={recordedClasses}
        name={`Class Recordings`}
        limit={6}
        viewMore={() => history.push('/recorded-classes')}
        forceViewMore
      />
    </div>
  )
}

export default withRouter(ClassRecordings)
