import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from '../../Buttons/Button'
import { Event } from '../../../Helpers/GoogleAnalytics'
import useSWR from 'swr'
import { accent } from '../../../Styles/colors'

const Links = ({ loggedIn }) => {
  const { data: activeSubscription } = useSWR(
    loggedIn
      ? [
          `/subscriptions`,
          (subs) => subs.find((sub) => sub.status === 'ACTIVE'),
        ]
      : null,
  )

  return (
    <div className='links'>
      {loggedIn && (
        <Link to={'/dashboard'}>
          <Button onClick={() => {}} name={'Dashboard'} type={'border'} />
        </Link>
      )}
      <Link to='/artists' rel='canonical'>
        <Button
          onClick={() => {
            Event('Meet our Artists', 'Clicked Meet our Artists')
          }}
          name={'Meet our Artists'}
          type={'border'}
        />
      </Link>
      {!activeSubscription?.id && (
        <Link to={'/pricing'}>
          <Button
            type={'border'}
            onClick={() => {}}
            name={'LivDemy Pro'}
            style={{
              color: accent,
              borderColor: accent,
            }}
          />
        </Link>
      )}
    </div>
  )
}

export default withRouter(Links)
