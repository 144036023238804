import { Container } from 'unstated'
import {
  endLiveChat,
  getClassroomClasses,
  grantLiveChatAccess,
  joinClass,
  lowerHandRequest,
  raiseHandRequest,
  sendRecordingToStudent,
  startRecording,
  startRecordingGroup,
} from '../../../API/Classroom'
import { getDemoClasses } from '../../../API/Dashboard'
import { joinDemoClass } from '../../../API/DemoClass'
import { extendClass } from '../../../API/VideoCall'
import { Realtime } from 'ably'

export const extendDuration = 30 * 60 * 1000

const initialState = () => ({
  loading: false,
  loadFailed: false,
  initializing: true,
  initFailed: false,
  upcomingClass: null,
  teacherId: '',
  allClasses: [],
  activeClasses: [],
  nextClass: null,
  joiningData: null,
  extended: 0,
  registration: { registrationID: '', classID: '', batchId: '' },
  liveChatStudent: false,
  liveChatTeacher: false,
  raiseHandRequests: [],
  liveChatParticipant: null,
})

export default class ClassroomStore extends Container {
  name = 'ClassroomStore'

  state = initialState()

  linkedStores = {}

  getUpcomingClasses = async () => {
    const classes = await getClassroomClasses()

    if (classes) {
      const { allClasses = [], activeClasses = [] } = classes

      await this.setState({
        initializing: false,
        allClasses,
        activeClasses,
      })
    } else {
      await this.setState({
        initializing: false,
        initFailed: true,
      })
    }
  }

  initClassroom = async (
    courseURL,
    livDemyID,
    userID,
    classID,
    registrationID,
    batchId,
    teacherID,
    chapters,
  ) => {
    console.log('Initializing ClassroomStore')

    await this.setState({
      ...initialState(),
      loading: true,
      teacherId: teacherID,
      registration: {
        registrationID,
        classID,
        batchId,
      },
    })

    const connection = new Realtime(process.env.REACT_APP_ABLY_KEY)
    const channel = userID ? connection.channels.get(userID) : null

    await this.getUpcomingClasses()

    const userType = (() => {
      if (!userID) {
        return 'anonymous'
      } else if (userID === teacherID) {
        return 'teacher'
      } else {
        return 'student'
      }
    })()

    const joiningData = await joinClass(
      Boolean(batchId) && userType === 'teacher' ? undefined : registrationID,
      classID,
      batchId,
    )

    if (!joiningData.canJoin) {
      return joiningData
    }

    if (joiningData) {
      await this.setupRealtimeUpdates({
        extended: joiningData.extend === '' ? 0 : 30,
        registration: {
          registrationID,
          classID,
          batchId,
        },
        userID,
        channel,
      })

      await this.setState({
        chapters,
        userType,
        joiningData,
        loading: false,
      })

      return joiningData
    } else {
      await this.setState({ loading: false, loadFailed: true })
      return joiningData
    }
  }

  setupRealtimeUpdates = async ({ extended, channel }) => {
    await this.setState({ extended: extended })
    const classExtendHandler = () => {
      console.log('extend event received')
      this.setState({ extended: 30 })
    }
    channel.subscribe('BATCH_CLASS_EXTENDED', classExtendHandler)
    channel.subscribe('ONE_ON_ONE_CLASS_EXTENDED', classExtendHandler)
  }

  initDemoClass = async (classID, userID) => {
    await this.setState({ ...initialState(), loading: true })

    const studentDemoClasses = await getDemoClasses('student', userID)
    const teacherDemoClasses = await getDemoClasses('teacher', userID)

    const userType = studentDemoClasses.find(
      (demoClass) => demoClass.id === classID,
    )
      ? 'student'
      : 'teacher'

    const startTime = [...studentDemoClasses, ...teacherDemoClasses].find(
      (demoClass) => demoClass.id === classID,
    ).startTime

    const data = await joinDemoClass(classID)

    if (data) {
      await this.setState({
        joiningData: { ...data, startTime },
        userType,
        loading: false,
      })
    } else {
      await this.setState({ loading: false, loadFailed: true })
      return false
    }
  }

  onConnected = async (isGroup, batchId, registrationID, classID) => {
    return await (isGroup
      ? startRecordingGroup({ batchId, registrationID, classID })
      : startRecording({ registrationID, classID }))
  }

  extendClass = async () => {
    const {
      registration: { registrationID, classID, batchId },
    } = this.state
    const success = await extendClass(registrationID, classID, batchId)
    if (success) {
      await this.setState({ extended: 30 })
    }
  }

  raiseHand = async () => {
    const {
      registration: { classID, batchId },
    } = this.state

    return await raiseHandRequest(batchId, classID)
  }

  lowerHand = async () => {
    const {
      registration: { classID, batchId },
    } = this.state

    return await lowerHandRequest(batchId, classID)
  }

  grantLiveChatPermission = async (request, grant) => {
    const {
      registration: { classID, batchId },
    } = this.state

    return await grantLiveChatAccess(batchId, classID, request, grant)
  }

  endLiveChat = async () => {
    const {
      registration: { classID, batchId },
    } = this.state

    return await endLiveChat(batchId, classID)
  }

  sendRecordedFile = async (document) => {
    const {
      upcomingClass: {
        teacher: { id: teacherID },
        student: { id: studentID },
        course: { id: courseID },
      },
      registration: { batchId },
    } = this.state

    return await sendRecordingToStudent({
      courseID,
      studentID,
      teacherID,
      data: {
        ...document,
        batchId,
      },
    })
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
