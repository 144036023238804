import React, { useState } from 'react'
import './List.sass'
import PropTypes from 'prop-types'
import Button from '../Buttons/Button'
import Loading from '../Loading/Loading'
import { Event } from '../../Helpers/GoogleAnalytics'
import MasterclassCard from '../Cards/MasterclassCard'

const MasterclassList = ({
  data = [],
  name,
  loading,
  loadFailed,
  limit = 9,
  onCardClick,
  showTitle,
  viewMore,
  forceViewMore = false,
}) => {
  const [displaying, updateDisplaying] = useState(limit)

  return (
    <>
      {loading && <Loading full />}
      {loadFailed && <p>Failed to load {name}</p>}
      {!loading &&
        !loadFailed &&
        (data.length ? (
          <>
            {showTitle && <h1>{name}</h1>}
            <div className='masterclassList'>
              {data.slice(0, displaying).map((masterclass, i) => (
                <MasterclassCard
                  key={`masterClassCard${i}`}
                  index={i}
                  {...masterclass}
                  onClick={onCardClick}
                />
              ))}
            </div>
            {(forceViewMore || displaying < data.length) && (
              <div className='viewMore'>
                <Button
                  onClick={() => {
                    Event('View More', 'Clicked View More Masterclasses')

                    if (viewMore) {
                      viewMore()
                      return
                    }

                    updateDisplaying(displaying + limit)
                  }}
                  name={'View More'}
                  type={'primary big'}
                />
              </div>
            )}
          </>
        ) : null)}
    </>
  )
}

MasterclassList.propTypes = {
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  limit: PropTypes.number,
  loadFailed: PropTypes.bool.isRequired,
  showTitle: PropTypes.bool,
  viewMore: PropTypes.func,
}

MasterclassList.defaultProps = {
  data: [],
  name: '',
  loading: false,
  loadFailed: false,
  limit: 9,
  showTitle: false,
  onCardClick: () => {},
}

export default MasterclassList
