import { createContainer } from "unstated-next";
import useSWR from "swr";
import { parseBecomeTeacherStatusData } from "../../../Helpers/BecomeTeacher";
import StudentProfileStore
  from "../../Dashboard/Components/Student/Components/StudentProfile/Store/StudentProfileStore";

const TeacherInfoStore = createContainer(() => {
  const { profileData } = StudentProfileStore.useContainer();

  const { data: info, error } = useSWR(
    profileData?.isTeacher ? ["/teacher-info", parseBecomeTeacherStatusData] : null
  );

  return {
    loading: !info,
    loadFailed: Boolean(error),
    info: info || {}
  };
});

export default TeacherInfoStore;
