import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { useLocation } from 'react-router-dom'

const PaymentCoupon = ({ children }) => {
  const { pathname, search } = useLocation()
  const [, setSubscriptionCouponStorage] = useLocalStorage(
    'subscription-coupon',
    '',
  )

  useEffect(() => {
    const url = new URL(pathname + search, window.location.origin)

    const subscriptionCoupon = url.searchParams.get('subscription-coupon')

    if (subscriptionCoupon) {
      setSubscriptionCouponStorage(subscriptionCoupon)
      url.searchParams.delete('subscription-coupon')
      window.location.replace(url.href)
    }
  }, [pathname, search, setSubscriptionCouponStorage])

  return children
}

export default PaymentCoupon

PaymentCoupon.propTypes = {
  children: PropTypes.element.isRequired,
}
