import React from 'react'
import { featuredClassesHeader } from '../Content/FeaturedClasses'
import CourseList from '../../../Components/Lists/CourseList'
import { Event } from '../../../Helpers/GoogleAnalytics'
import HomeStore from '../Store/home-store'

const FeaturedClasses = () => {
  const { loading, loadFailed, data } = HomeStore.useContainer()

  return (
    <div className='featuredClasses'>
      <div className='sectionHeaderContainer'>
        <h1 className={'sectionHeader'}>{featuredClassesHeader}</h1>

        <p className='sectionSubHeader'>
          Harmonize your learning experience in our dynamic group music courses
          online, blending live interaction and recorded reference materials for
          a comprehensive and flexible musical education.
        </p>
      </div>
      <CourseList
        data={data?.liveCourses || []}
        loading={loading}
        loadFailed={loadFailed}
        name={'Featured Courses'}
        limit={6}
        onCardClick={(index) =>
          Event(
            'Featured Course Card',
            'Clicked Featured Course',
            (index + 1).toString(),
          )
        }
      />
    </div>
  )
}

export default FeaturedClasses
