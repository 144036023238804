import React, { useEffect, useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { geocode } from '../../../API/v1/Auth'
import { getLocationFromCoordinate } from '../../../API/UserAuth'

const { tzlookup: tzLookup } = window

const PlacesInput = ({ placeholder, form, field }) => {
  const [address, updateAddress] = useState('')
  const [inFocus, updateInFocus] = useState(false)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (pos) => {
        const tz = tzLookup(pos.coords.latitude, pos.coords.longitude)
        const address = await getLocationFromCoordinate({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        })
        updateAddress(address)
        form.setFieldValue(field.name, address)
        form.setFieldValue('timezone', tz)
      })
    }
  }, [field.name, form])

  return (
    <div className='inputWrapper'>
      <PlacesAutocomplete
        value={address}
        highlightFirstSuggestion={true}
        onChange={(address) => {
          updateAddress(address)
          form.setFieldValue(field.name, address)
          form.setFieldValue('timezone', '')
        }}
        onSelect={async (address) => {
          updateAddress(address)
          form.setFieldValue(field.name, address)
          form.setFieldValue('timezone', '')

          const coordinates = await geocode(address)
          if (coordinates) {
            const tz = tzLookup(coordinates.lat, coordinates.lng)
            form.setFieldValue('timezone', tz)
          }
        }}
        searchOptions={{
          types: ['(cities)'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: '',
              })}
              onFocus={() => updateInFocus(true)}
              onBlur={() => updateInFocus(false)}
            />
            <div className='autocomplete-dropdown-container'>
              {suggestions.map((suggestion) => {
                return (
                  <div {...getSuggestionItemProps(suggestion)}>
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <p className={'placeholder' + (inFocus || address ? ' active' : '')}>
        {placeholder}
      </p>
      <p className={'error'}>
        {form.errors && form.touched[field.name] && form.errors[field.name]}
      </p>
    </div>
  )
}

export default PlacesInput
