import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Button.sass'
import Loading from '../Loading/Loading'
import Confirmation from '../Confirmation/Confirmation'
import { generateConfirmation } from '../Confirmation/Helpers'
import UserStore from '../../App/UserAuth/Store/UserStore'
import { Subscribe } from 'unstated'
import { withRouter } from 'react-router-dom'

const Button = ({
  name,
  onClick = () => {},
  disabled,
  loading,
  type,
  className,
  buttonType,
  icon,
  withConfirmation,
  requiresLogin,
  history,
  location,
  staticContext,
  children,
  ...props
}) => {
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Subscribe to={[UserStore]}>
      {({ state: { loggedIn } }) => (
        <button
          onClick={(e) => {
            if (requiresLogin && !loggedIn) {
              history.push('/auth/register/form', { from: location })
              return
            }
            if (!withConfirmation) {
              onClick(e)
            } else {
              const confirmation = generateConfirmation(
                () => {
                  onClick(e)
                  setConfirmation(null)
                },
                () => {
                  setConfirmation(null)
                },
              )

              setConfirmation(confirmation)
            }
          }}
          disabled={disabled}
          className={
            type +
            ' defaultButton ' +
            className +
            (disabled ? ' disabled' : '') +
            (icon ? ' withIcon' : '')
          }
          type={buttonType}
          {...props}
        >
          {confirmation && (
            <Confirmation
              promise={confirmation}
              question={
                typeof withConfirmation === 'string'
                  ? withConfirmation
                  : undefined
              }
            />
          )}
          {loading ? (
            <Loading color={type.includes('secondary') && 'white'} small />
          ) : (
            <>
              {icon && <span className={icon} />}
              {name || children}
            </>
          )}
        </button>
      )}
    </Subscribe>
  )
}

Button.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  requiresLogin: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'primary big',
    'secondary',
    'secondary big',
    'border',
    'border big',
  ]).isRequired,
}

export default withRouter(Button)
