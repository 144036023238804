import { Container } from 'unstated'
import { database } from 'firebase'
import { sendMasterclassChatMessage } from '../../../API/Broadcast'

const initialState = () => ({
  loading: true,
  loadFailed: false,
  id: '',
  messages: [],
  unreadCount: 0,
  participants: {},
})

export default class MasterclassMessagesStore extends Container {
  name = 'MasterclassMessagesStore'

  state = initialState()

  linkedStores = {}

  init = async (groupChatId) => {
    console.log('initializing MasterclassMessagesStore')

    await this.setState({ ...initialState(), id: groupChatId })

    let db = database()
    let chatData = db.ref(`/messaging/chats/${groupChatId}`)

    chatData.on('value', async (snap) => {
      let { participants = {}, messages = {} } = snap.val() || {}

      if (!this.linkedStores.VideoCallTokboxInterfaceStore.state.sidebarOpen) {
        this.setUnreadCount(
          this.state.unreadCount +
            (Object.entries(messages).length - this.state.messages.length),
        )
      }

      await this.setState({
        participants: new Map(Object.entries(participants)),
        messages: Object.entries(messages),
      })
    })
  }

  setUnreadCount = (value) => this.setState({ unreadCount: value })

  sendMessage = async (message) => {
    const { id } = this.state

    return await sendMasterclassChatMessage(id, message)
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
