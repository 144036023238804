import React from 'react'
import { Link } from 'react-router-dom'
import './Card.sass'

const ResumeCourseCard = ({
  cover,
  name,
  teacher,
  artist,
  teacherName,
  onClick = () => {},
  url,
  index,
  isGroupCourse,
  currentChapterIndex,
  currentLessonIndex,
  type,
  currentContent,
}) => {
  return (
    <Link to={url}>
      <div
        className={'courseCard type-' + (isGroupCourse ? 'group' : '')}
        onClick={() => onClick(index)}
      >
        {isGroupCourse && (
          <div className='groupCourseTag'>
            <span /> Group Course
          </div>
        )}
        <div
          className='courseImage'
          style={{
            background: `url(${cover}) center center /cover no-repeat`,
          }}
        />
        <div className='courseDetails'>
          <h1 className='courseName'>{name}</h1>
          <p className='teacherName'>
            By <span>{teacher || artist || teacherName}</span>
          </p>
          <div className='row'>
            {type === 'recorded' ? (
              <>
                <div className='chapter'>
                  <div>{'Chapter ' + currentChapterIndex || ''}</div>
                  {/* <div>{currentChapter.title}</div> */}
                </div>
                <div className='lesson'>
                  <div>{'Lesson ' + currentLessonIndex || ''}</div>
                  {/* <div>{currentLesson.title}</div> */}
                </div>
              </>
            ) : (
              <div className='chapter'>
                {currentContent?.title}
                {/* <div>{currentChapter.title}</div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ResumeCourseCard
