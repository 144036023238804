import ClassroomStore from '../../App/Classroom/Store/ClassroomStore'
import NotesStore from '../../App/Classroom/Store/NotesStore'
import LeaveReviewStore from '../../App/Course/Components/LeaveReview/Store/LeaveReviewStore'
import MyCalendarStore from '../../App/Dashboard/Components/MyCalendar/Store/MyCalendarStore'
import DashboardStore from '../../App/Dashboard/Store/DashboardStore'
import MasterclassMessagesStore from '../../App/BroadcastClass/Store/MasterclassMessagesStore'
import MasterclassStore from '../../App/BroadcastClass/Store/MasterclassStore'
import UserStore from '../../App/UserAuth/Store/UserStore'
import DocumentsViewerStore from '../../Modules/Documents/Viewer/Store/DocumentsViewerStore'
import MessagingCoreStore from '../../Modules/Messaging/Store/MessagingCoreStore'
import UpcomingClassStore from '../../Modules/UpcomingClass/Store/UpcomingClassStore'
import LeaveMasterclassReviewStore from '../../App/BroadcastClass/Components/LeaveReview/Store/LeaveMasterclassReviewStore'

export const linkedStores = () => {
  // Create instances of all stores which need linking here

  const stores = {
    userStore: new UserStore(),
    upcomingClassStore: new UpcomingClassStore(),
    dashboardStore: new DashboardStore(),
    messagingCoreStore: new MessagingCoreStore(),
    myCalendarStore: new MyCalendarStore(),
    documentsViewerStore: new DocumentsViewerStore(),
    masterclassStore: new MasterclassStore(),
    leaveReviewStore: new LeaveReviewStore(),
    masterclassMessagesStore: new MasterclassMessagesStore(),
    classroomStore: new ClassroomStore(),
    notesStore: new NotesStore(),
    leaveMasterclassReviewStore: new LeaveMasterclassReviewStore(),
  }
  //Link all the stores

  //UpcomingClassStore Store
  stores.upcomingClassStore.bindStore(stores.userStore)

  //User Store
  stores.userStore.bindStore(stores.documentsViewerStore)

  //Dashboard Store
  stores.dashboardStore.bindStore(stores.userStore)
  stores.dashboardStore.bindStore(stores.messagingCoreStore)
  stores.dashboardStore.bindStore(stores.documentsViewerStore)

  //Messaging Store
  stores.messagingCoreStore.bindStore(stores.dashboardStore)
  stores.messagingCoreStore.bindStore(stores.upcomingClassStore)
  stores.messagingCoreStore.bindStore(stores.userStore)
  stores.messagingCoreStore.bindStore(stores.documentsViewerStore)

  //MyCalendarStore
  stores.myCalendarStore.bindStore(stores.userStore)

  //DashboardBroadcastClass Store
  stores.masterclassStore.bindStore(stores.userStore)
  stores.masterclassStore.bindStore(stores.masterclassMessagesStore)
  stores.masterclassStore.bindStore(stores.messagingCoreStore)

  //Leave Review Store
  stores.leaveReviewStore.bindStore(stores.userStore)

  //Notes Store
  stores.notesStore.bindStore(stores.classroomStore)

  //Classroom Store
  stores.classroomStore.bindStore(stores.userStore)

  //LeaveMasterclassReviewStore Store
  stores.leaveMasterclassReviewStore.bindStore(stores.userStore)

  return Object.keys(stores).map((key) => stores[key])
}
