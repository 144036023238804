import React from 'react'
import { withRouter } from 'react-router-dom'
import './Notification.sass'
import withStore from '../../Components/Unstated/withStore'
import NotificationsList from './Components/NotificationsList'
import UserStore from '../../App/UserAuth/Store/UserStore'
import { withStoreProvider } from '../../Helpers/withStoreProvider'
import NotificationStore from './Store/NotificationStore'

const Notifications = () => {
  const { data = [], toggleList } = NotificationStore.useContainer()

  const unreadCount = data.filter((notification) => !notification.read).length

  return (
    <div className='notifications'>
      <div className='icon' onClick={() => toggleList()}>
        {!!unreadCount && <p className={'unread'}>{unreadCount}</p>}
      </div>
      <NotificationsList />
    </div>
  )
}

export default withStoreProvider([NotificationStore])(
  withStore([UserStore])(withRouter(Notifications)),
)
