import React from 'react'
import { Redirect, Route as BrowserRoute } from 'react-router-dom'
import { Subscribe } from 'unstated'
import UserStore from '../../App/UserAuth/Store/UserStore'

const Route = ({ render, isPrivate, ifGuest, ...rest }) =>
  isPrivate || ifGuest ? (
    <Subscribe to={[UserStore]}>
      {({ state: { loggedIn } }) =>
        (loggedIn && isPrivate) || (!loggedIn && ifGuest) ? (
          <BrowserRoute {...rest} render={render} />
        ) : isPrivate ? (
          <BrowserRoute
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: '/auth',
                  state: { from: props.location },
                }}
              />
            )}
          />
        ) : (
          <BrowserRoute
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            )}
          />
        )
      }
    </Subscribe>
  ) : (
    <BrowserRoute {...rest} render={render} />
  )

export default Route
