import React, { Component } from 'react'
import Loading from '../Loading/Loading'

const asyncComponent = (importComponent) => {
  return class extends Component {
    state = {
      Component: null,
    }

    componentDidMount() {
      importComponent()
        .then((cmp) => {
          this.setState({ Component: cmp.default })
        })
        .catch(() => {
          setTimeout(() => {
            window.location.reload()
          }, 250)
        })
    }

    render() {
      const { Component } = this.state
      return Component ? <Component {...this.props} /> : <Loading full />
    }
  }
}

export default asyncComponent
