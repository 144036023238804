import styled from 'styled-components'
import { H3 } from '../../../../../Styles/typography'
import {
  background,
  hFlex,
  square,
  vFlex,
} from '../../../../../Styles/style-helpers'
import quoteIcon from '../../../../../Assets/Icons/New User/Home/testimonial_quotes.svg'
import { accent } from '../../../../../Styles/colors'

export const TestimonialEl = styled.div`
  width: 100%;
  min-height: 40em;
  padding: 2.5em 0;
  ${vFlex};
  overflow: hidden;

  ${H3} {
    font-weight: bold;
  }

  .items {
    position: relative;
    width: 100%;
    flex: 1;
    height: 100%;
    padding: 2em 0 0;
    ${vFlex};
  }
`

export const CardEl = styled.div`
  width: 35%;
  background: white;
  position: absolute;
  padding: 1.5em 3.5em;
  box-shadow: 0 0 12px 0 rgba(105, 105, 105, 0.13);
  border-radius: 1em;

  & > p {
    margin-top: 1.5em;
    line-height: 1.625;
    opacity: 0.75;

    .quoteMarks {
      ${square('2em')};
      ${background(quoteIcon, 'contain')};
      display: inline-block;
      transform: translate(0, 10%);
      margin-right: 1em;
    }
  }

  .student {
    margin-top: 0.75em;
    ${hFlex};
    justify-content: flex-start;

    & > p {
      color: ${accent};
      margin-left: 0.75em;
    }
  }
`
