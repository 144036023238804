import React from 'react'
import { withRouter } from 'react-router-dom'
import './SearchBox.sass'
import SearchInput from './Components/SearchInput'

const SearchBox = () => {
  return (
    <div className='searchBox'>
      <SearchInput />
      <div className='icon' />
    </div>
  )
}

export default withRouter(SearchBox)
