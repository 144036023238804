import { createContainer } from 'unstated-next'
import { useState } from 'react'

const UserStoreNext = createContainer(() => {
  const [data, update] = useState()

  return {
    data,
    update,
  }
})

export default UserStoreNext
