import { createContainer } from 'unstated-next'
import { useHistory } from 'react-router-dom'
import { useEffectOnce, useToggle } from 'react-use'

const TrackNavigationStore = createContainer(() => {
  const history = useHistory()
  const [userHasNavigated, toggleUserHasNavigated] = useToggle(false)

  useEffectOnce(() => {
    history.listen(() => toggleUserHasNavigated(true))
  })

  return { userHasNavigated }
})

export default TrackNavigationStore
