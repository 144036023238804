import { API, slsCoreBaseUrl } from './config'
import { getLocalCurrency } from './Billing'
import { MASTERCLASS } from '../Constants/broadcasts'

export const getMasterclassDetails = async (
  livDemyId,
  broadcastClassId,
  version,
  broadcastType = MASTERCLASS,
) =>
  API.get(
    `/broadcast-class/${broadcastType}/${livDemyId}/${broadcastClassId}/${version}`,
    {
      headers: {
        'local-currency': await getLocalCurrency(),
      },
    },
  )
    .then((res) => res.data.data)
    .catch(() => false)

export const getMasterclassQuestions = (
  broadcastClassId,
  broadcastType = MASTERCLASS,
) =>
  API.get(`/broadcast-class/${broadcastType}/${broadcastClassId}/questions`)
    .then((res) => res.data.data.question)
    .catch(() => false)

export const askBroadcastClassQuestion = (
  broadcastClassId,
  question,
  broadcastType = MASTERCLASS,
) =>
  API.post(`/broadcast-class/${broadcastType}/${broadcastClassId}/question`, {
    question,
  })
    .then(() => true)
    .catch(() => false)

export const deleteBroadcastClassQuestion = (
  broadcastClassId,
  questionID,
  broadcastType = MASTERCLASS,
) =>
  API.delete(
    `/broadcast-class/${broadcastType}/${broadcastClassId}/question/${questionID}`,
  )
    .then(() => true)
    .catch(() => false)

export const answerMasterclassQuestion = (
  answer,
  id,
  broadcastClassId,
  broadcastType = MASTERCLASS,
) =>
  API.post(
    `/broadcast-class/${broadcastType}/${broadcastClassId}/question/${id}/answer`,
    {
      answer,
      broadcastClassId,
    },
  )
    .then(() => true)
    .catch(() => false)

export const joinMasterclass = (
  livDemyID,
  broadcastClassId,
  broadcastType = MASTERCLASS,
) =>
  API.post(
    `/${livDemyID}/broadcast-class/${broadcastType}/${broadcastClassId}/join`,
  )
    .then((res) => res.data.data)
    .catch(() => false)

export const startMasterclass = (livDemyID, broadcastType = MASTERCLASS) =>
  API.post(`/${livDemyID}/broadcast-class/${broadcastType}/started`)
    .then(() => true)
    .catch(() => false)

export const archiveBroadcastClass = (
  broadcastClassId,
  broadcastType = MASTERCLASS,
) =>
  API.put(`/broadcast-class/${broadcastType}/archive`, { broadcastClassId })
    .then(() => true)
    .catch(() => false)

export const scheduleMasterclass = (
  livDemyID,
  data,
  broadcastType = MASTERCLASS,
) =>
  API.post(`/${livDemyID}/broadcast-class/${broadcastType}`, data)
    .then((res) => res.data.data)
    .catch(() => false)

export const editMasterclasses = (id, data, broadcastType = MASTERCLASS) =>
  API.put(`/broadcast-class/${broadcastType}`, {
    ...data,
    broadcastClassId: id,
  })
    .then((res) => res.data.data)
    .catch(() => false)

export const getAllMasterclass = (livdemyID, broadcastType = MASTERCLASS) =>
  API.get(`/dashboard/teacher/broadcast-class/${broadcastType}/${livdemyID}`)
    .then((res) => res.data.data)
    .catch(() => false)

export const registerMasterclass = async (
  livdemyID,
  broadcastClassId,
  broadcastType = MASTERCLASS,
) =>
  API.post(
    `/${livdemyID}/broadcast-class/${broadcastType}/register`,
    {
      broadcastClassId,
    },
    {
      headers: {
        'local-currency': await getLocalCurrency(),
      },
    },
  )
    .then((res) => res.data.data)
    .catch(() => false)

export const getRegisteredMasterclasses = (broadcastType = MASTERCLASS) =>
  API.get(`/dashboard/broadcast-class/${broadcastType}`)
    .then((res) => res.data.data)
    .catch(() => false)

export const getAllUpcomingMasterclasses = (broadcastType = MASTERCLASS) =>
  API.get(`/upcoming/broadcast-class/${broadcastType}/`)
    .then((res) => res.data.data.filter((mc) => mc.endTime > Date.now()))
    .catch(() => false)

export const getAllBroadcastClasses = async (broadcastType = MASTERCLASS) =>
  API.get(`/upcoming/broadcast-class/${broadcastType}/`, {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then(async (res) => {
      const classes = res.data.data
        .sort((a, b) => Number(a.startTime) - Number(b.startTime))
        .map((mc) => ({
          ...mc,
          teacher: {
            name: mc.teacherName,
            livdemyId: mc.livdemyId,
            coverImage: mc.coverImage,
          },
        }))

      const upcoming = classes.filter((cls) => cls.endTime > Date.now())

      const completed = classes
        .filter((cls) => cls.endTime < Date.now())
        .reverse()
      return [...upcoming, ...completed]
    })
    .catch(() => false)

export const sendMasterclassChatMessage = (
  groupChatId,
  message,
  broadcastType = MASTERCLASS,
) =>
  API.post(`/broadcast-class/${broadcastType}/chat`, {
    groupChatId,
    message,
  })
    .then(() => true)
    .catch(() => false)

export const extendBroadcastClass = ({
  id: broadcastClassId,
  time,
  broadcastType = MASTERCLASS,
}) =>
  API.post(`/broadcast-class/${broadcastType}/extend`, {
    broadcastClassId,
    time,
  })
    .then(() => true)
    .catch(() => false)

export const raiseHandRequest = (
  broadcastClassId,
  broadcastType = MASTERCLASS,
) =>
  API.post(
    `${slsCoreBaseUrl}/broadcast-class/id/${broadcastClassId}/type/${broadcastType}/live-chat/request`,
  )
    .then(() => true)
    .catch(() => false)

export const lowerHandRequest = (
  broadcastClassId,
  broadcastType = MASTERCLASS,
) =>
  API.delete(
    `${slsCoreBaseUrl}/broadcast-class/id/${broadcastClassId}/type/${broadcastType}/live-chat/request`,
  )
    .then(() => true)
    .catch(() => false)

export const grantLiveChatAccess = (
  broadcastClassId,
  liveRequestId,
  grant,
  broadcastType = MASTERCLASS,
) =>
  API.put(
    `${slsCoreBaseUrl}/broadcast-class/id/${broadcastClassId}/type/${broadcastType}/live-chat/request`,
    {
      broadcastClassId,
      liveRequestId,
      grant,
    },
  )
    .then(() => true)
    .catch(() => false)

export const endLiveChatRequest = (
  broadcastClassId,
  broadcastType = MASTERCLASS,
  requestId,
) =>
  API.delete(
    `${slsCoreBaseUrl}/broadcast-class/id/${broadcastClassId}/type/${broadcastType}/live-chat/request/${requestId}/end`,
  )
    .then(() => true)
    .catch(() => false)

export const broadcastClassPreroll = (
  livDemyId,
  masterclassUrl,
  version,
  broadcastType = MASTERCLASS,
) =>
  API.get(
    `/broadcast-class/pre-roll/${broadcastType}/${livDemyId}/${masterclassUrl}/${version}`,
  )
    .then((res) => res.data.data)
    .catch(() => false)

export const createSeriesRequest = (data, type) =>
  API.post(`/series/${type}`, data)
    .then((res) => res.data.data)
    .catch(() => false)

export const editSeriesRequest = (id, data, type) =>
  API.put(`/series/${id}/${type}`, data)
    .then((res) => res.data.data)
    .catch(() => false)

export const registerSeriesRequest = (seriesId, type) =>
  API.post(`/series/register/${type}`, { seriesId })
    .then((res) => res.data.data)
    .catch(() => false)

export const submitSeriesRequest = (seriesId, type) =>
  API.put(`/submit/series/${seriesId}/${type}`)
    .then(() => true)
    .catch(() => false)

export const deleteBroadcastClass = (id, type) =>
  API.delete(`/broadcast-class/${type}/${id}`)
    .then(() => true)
    .catch(() => false)

export const makeCoHostRequest = (id, type, requestId) =>
  API.post(`${slsCoreBaseUrl}/broadcast-class/id/${id}/type/${type}/co-host/make`, {
    liveRequestId: requestId,
  })
    .then(() => true)
    .catch(() => false)

export const endCoHostRequest = (id, type) =>
  API.put(`/broadcast-class/${type}/${id}/end-co-host`)
    .then(() => true)
    .catch(() => false)

export const highlightMessageRequest = (chatId, messageId, status) =>
  API.put(
    `${slsCoreBaseUrl}/chat/id/${chatId}/message/id/${messageId}/highlight`,
    {
      highlighted: status,
    },
  )
    .then(() => true)
    .catch(() => false)

export const buyRecording = (broadcastClassId, type = MASTERCLASS) =>
  API.post(`/broadcast-class/${type}/recording/purchase`, { broadcastClassId })
    .then((res) => res.data.data)
    .catch(() => false)

export const endSessionRequest = ({ broadcastType, broadcastClassId, ended }) =>
  API.put(`broadcast-class/${broadcastType}/end`, {
    broadcastClassId,
    ended,
  })

export const startSessionRequest = ({
  broadcastType,
  broadcastClassId,
  start,
}) =>
  API.post(
    `${slsCoreBaseUrl}/broadcast-class/id/${broadcastClassId}/type/${broadcastType}/class/start`,
    { start },
  )

export const getRecordingFile = (id) =>
  API.get(`util/recording/${id}/playback`)
    .then((res) => res.data.data)
    .catch(() => false)

export const restartBroadcastRequest = ({ id, type }) =>
  API.post(
    `${slsCoreBaseUrl}/broadcast-class/id/${id}/type/${type}/class/restart`,
  )
    .then((res) => res.data.data)
    .catch(() => false)
