import React from 'react'
import './Landing.sass'
import Header from './Components/Header'
import FeaturedClasses from './Components/FeaturedClasses'
import Banner from './Components/Banner'
import HowItWorks from './Components/HowItWorks'
import withStore from '../../Components/Unstated/withStore'
import Footer from '../../Components/Footer/Footer'
import UserStore from '../UserAuth/Store/UserStore'
import Helmet from '../../Components/Helmet/Helmet'
import Testimonials from './Components/Testimonials'
import FeaturedMasterclasses from './Components/FeaturedMasterclasses'
import FacebookChat from '../../Components/FacebookChat'
import FeaturedRecordedCourses from './Components/FeaturedRecordedCourses'
import ClassRecordings from './Components/ClassRecordings'
import { withStoreProvider } from '../../Helpers/withStoreProvider'
import HomeStore from './Store/home-store'
import ResumeCourses from './Components/ResumeCourses'

const Landing = ({ userStore: { state: user } }) => {
  return (
    <div className='Landing'>
      <Helmet route={'/'} />
      <Header />
      <Banner />
      <ResumeCourses />
      <FeaturedRecordedCourses />
      <FeaturedMasterclasses />
      <ClassRecordings />
      <FeaturedClasses />
      <HowItWorks />
      <Testimonials />
      <FacebookChat />
      <Footer />
    </div>
  )
}

export default withStore([UserStore])(withStoreProvider([HomeStore])(Landing))
