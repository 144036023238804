import React, { useState } from 'react'

const Password = ({
  placeholder,
  field = {},
  form = {},
  options,
  ...props
}) => {
  const [showPassword, togglePasswordVisibility] = useState(false)

  return (
    <div className='inputWrapper'>
      <span
        className={'visibilityToggle' + (showPassword ? ' active' : '')}
        onClick={() => togglePasswordVisibility(!showPassword)}
      />
      <input
        placeholder=' '
        {...props}
        type={showPassword ? 'text' : 'password'}
        {...field}
      />
      <p className={'placeholder'}>{placeholder}</p>
      <p className={'error'}>
        {form.errors && form.touched[field.name] && form.errors[field.name]}
      </p>
    </div>
  )
}

export default Password
