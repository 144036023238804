import { capitalize } from '../Modules/Messaging/Helpers/Text'
import {
  applySpec,
  ascend,
  descend,
  filter,
  flatten,
  gt,
  juxt,
  lte,
  pipe,
  propOr,
  sort,
} from 'ramda'

export const getField = (obj = {}, path, def) =>
  path
    .replace(/\[/g, '.')
    .replace(/]/g, '')
    .split('.')
    .filter(Boolean)
    .every((step) => !(step && [undefined, null].includes((obj = obj[step]))))
    ? obj
    : def

export const stripHtmlTags = (str) => {
  if (!str) return ''
  else str = str.toString()
  return str.replace(/<[^>]*>/g, ' ')
}

export const objectToArray = (object) =>
  !object ? [] : Object.keys(object).map((key) => ({ key, ...object[key] }))

export const rotateArray = (arr, items) => [
  ...arr.slice(items),
  ...arr.slice(0, items),
]

export const groupBy = (ungrouped, key) =>
  ungrouped.reduce((grouped, current) => {
    ;(grouped[current[key]] = grouped[current[key]] || []).push(current)
    return grouped
  }, {})

export const getLastProfilePhoto = (profilePhotos) =>
  profilePhotos &&
  profilePhotos[Object.keys(profilePhotos).slice(-1)[0]].metaData.s3.extra
    .Location

export const generateOptions = (options = []) =>
  options.map((option) => ({ label: capitalize(option), value: option }))

export const mergeOptionLabels = (options = []) =>
  options
    .map((option) => option.label)
    .reduce((merged, current) => `${merged}, ${current}`, '')
    .slice(2)

export const isArray = (x) => x.constructor.name === 'Array'

export const isEmpty = (elem) =>
  elem === null ||
  elem === undefined ||
  elem === '' ||
  (elem.constructor === Array && elem.length === 0) ||
  (elem.constructor === Object && Object.keys(elem).length === 0)

export const getQueryStringValue = (key) => {
  // noinspection RegExpRedundantEscape
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        '^(?:.*[&\\?]' + // eslint-disable-line
        encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + // eslint-disable-line
          '(?:\\=([^&]*))?)?.*$', // eslint-disable-line
        'i',
      ),
      '$1',
    ),
  ) // eslint-disable-line
}

export const getLastPhoto = (user) => {
  let photo = user && getField(user, 'profile.profilePhoto')
  photo = photo && photo[Object.keys(photo).slice(-1)[0]]
  photo =
    (photo && getField(photo, 'metaData.s3.extra.Location')) ||
    getField(photo, 'location')
  return photo
}

export const parseDocuments = (documents = {}) =>
  Object.keys(documents).map((key) => ({
    name: getField(documents[key], 'fileName'),
    type: getField(documents[key], 'fileType'),
    size: Number(getField(documents[key], 'fileSize')),
    url: getField(documents[key], 'location'),
    id: getField(documents[key], 'id'),
  }))

export const parseS3Documents = (documents = {}) =>
  Object.keys(documents).map((key) => parseS3Document(documents[key]))

export const parseS3Document = (document = {}) => {
  return {
    id: getField(document, 'id'),
    name:
      getField(document, 'document.fileName') ||
      getField(document, 'document.fileName'),
    type:
      getField(document, 'document.fileType') ||
      getField(document, 'document.metaData.s3.type'),
    size:
      getField(document, 'document.fileSize') ||
      getField(document, 'document.metaData.s3.size'),
    url:
      getField(document, 'document.location') ||
      getField(document, 'document.metaData.s3.extra.Location'),
  }
}

export const resetState = (state, initialState) => ({
  ...Object.keys(state).reduce((state, key) => ({ ...state, [key]: null }), {}),
  ...initialState,
})

export const shuffleArray = (array) => {
  const shuffled = [...array]

  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
  }

  return shuffled
}

export const deepMemoCompare = (prev, next) =>
  JSON.stringify(prev) === JSON.stringify(next)

export const updateLastSection = (url, update, sections = 1) => {
  const updatedUrl = [...url.split('/').slice(0, -1 * sections), update].join(
    '/',
  )
  return updatedUrl.slice(-1)[0] === '/' ? updatedUrl.slice(0, -1) : updatedUrl
}

export const updatePathParams = (path = '', params = {}) => {
  let result = path
  for (const key in params) {
    result = result.replace(`:${key}`, params[key])
  }
  return result
}

export const sortByKey = (arr, key) =>
  arr.sort(function (a, b) {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })

export const getUrlFriendlyString = (str) =>
  str
    .replace(/[^\w\s]/gi, '')
    .toLowerCase()
    .split(' ')
    .join('-')

export const removeDoubleSlash = (str) => str.split('//').join('/')

export const formatMsToHHMMSS = (ms, suffix = true) => {
  const hours = Math.floor(ms / (60 * 60 * 1000))
  const minutes = Math.floor((ms / (60 * 1000)) % 60)
  const seconds = Math.floor((ms / 1000) % 60)

  const hourPrefix = !hours ? '' : hours < 10 ? `0${hours}:` : `${hours}:`
  return `${hourPrefix}${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }${suffix ? 's' : ''}`
}

export const truncateStringAfter = (limit, str) =>
  str.length > limit ? str.slice(0, limit - 3) + '...' : str

export const eventsSort = pipe(
  applySpec({
    upcoming: pipe(
      filter(pipe(propOr(0, 'endTime'), lte(Date.now()))),
      sort(ascend(propOr(0, 'startTime'))),
    ),
    completed: pipe(
      filter(pipe(propOr(0, 'endTime'), gt(Date.now()))),
      sort(descend(propOr(0, 'startTime'))),
    ),
  }),
  juxt([propOr([], 'upcoming'), propOr([], 'completed')]),
  flatten,
)
