import { useCallback, useState } from 'react'
import { useToggle } from 'react-use'
import { createContainer } from 'unstated-next'
import {
  changePassword,
  updateProfileDetails,
} from '../../../../../../../API/Dashboard'
import useSWR from 'swr'
import { notify } from '../../../../../../../Helpers/Notifications'
import { slsCoreBaseUrl } from '../../../../../../../API/config'

const StudentProfileStore = createContainer(() => {
  const [userId, updateUserId] = useState('')
  const [editing, toggleEditing] = useToggle(false)
  const [passwordEdit, togglePasswordEdit] = useToggle(false)
  const [uploadPercentage, updateUploadPercentage] = useState(0)
  const { data: profileData, error, mutate } = useSWR(
    userId ? `${slsCoreBaseUrl}/user/profile` : null,
  )

  const updateProfilePicture = useCallback(() => {
    return mutate()
  }, [mutate])

  const updateProfile = useCallback(
    async ({ picture, ...values }) => {
      const profile = await updateProfileDetails(values)

      if (profile) {
        await mutate()
        toggleEditing(false)
      }

      return profile
    },
    [mutate, toggleEditing],
  )

  const updatePassword = useCallback(
    async (values) => {
      const { currentPassword, newPassword, confirmPassword } = values

      if (
        !newPassword ||
        !currentPassword ||
        !confirmPassword ||
        newPassword !== confirmPassword
      )
        return

      const data = {
        newPassword,
        currentPassword,
      }

      const success = await changePassword(data)

      if (success) {
        notify('success', 'Password changed successfully.')
        togglePasswordEdit(false)
      }
    },
    [togglePasswordEdit],
  )

  return {
    loading: !profileData,
    loadFailed: Boolean(error),
    profileData,
    uploadPercentage,
    editing,
    passwordEdit,
    toggleEditing,
    togglePasswordEdit,
    updateProfilePicture,
    updateProfile,
    updateUploadPercentage,
    updatePassword,
    mutate,
    updateUserId,
  }
})

export default StudentProfileStore
