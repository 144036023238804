import React from 'react'
import './SocialLinks.sass'

const SocialLinks = () => (
  <div className='socialLinks'>
    <div
      className='icon fb'
      onClick={() => window.open('https://www.facebook.com/LivDemy', '_blank')}
    />
    <div
      className='icon tw'
      onClick={() => window.open('https://www.twitter.com/LivDemy', '_blank')}
    />
    <div
      className='icon in'
      onClick={() => window.open('https://www.instagram.com/LivDemy', '_blank')}
    />
  </div>
)

export default SocialLinks
