import React from 'react'
import './Card.sass'
import PropTypes from 'prop-types'

const FeatureCard = ({ icon, title, description }) => (
  <div className='featureCard'>
    <div className='icon'>
      <img src={icon} alt='Feature Icon' />
    </div>
    <div className='info'>
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  </div>
)

FeatureCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default FeatureCard
