export const showInstallPrompt = () => {
  const prompt = document.createElement('div')
  prompt.setAttribute('class', 'newVersionPrompt')
  prompt.innerHTML = `
     <h2>A new update is available. Press refresh to update.</h2>
     <button onclick="(function(){
        worker.postMessage({action: 'skipWaiting'});
     })();">Refresh</button>
  `
  document.getElementById('root').appendChild(prompt)
}
