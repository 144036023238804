export const UserStoreSchema = () => ({
  name: '',
  email: '',
  emailVerified: false,
  authToken: '',
  refreshToken: '',
  city: '',
  id: '',
  role: '',
  picture: '',
  profile: {
    name: '',
  },
  isProfileComplete: false,
  passwordLogin: false,
  loggedIn: false,
  signUpRequest: false,
  status: '',
  redirectTo: null,
  whatsappNumber: '',
})
