import React from 'react'
import PropTypes from 'prop-types'
import StarRatingComponent from 'react-star-rating-component'
import './Rating.sass'

const Rating = ({
  value,
  showValue,
  ratings,
  onChange = () => {},
  inputMode,
}) => (
  <div className={'rating' + (inputMode ? ' inputMode' : '')}>
    <StarRatingComponent
      starCount={5}
      name={new Date().getTime().toString()}
      renderStarIcon={(index, value) =>
        index <= value ? (
          <div className='star' />
        ) : (
          <div className='star hidden' />
        )
      }
      renderStarIconHalf={() => <div className='halfStar' />}
      value={value}
      starColor={'#FFC808'}
      onStarClick={onChange}
    />

    {showValue && <h1 className='value'>{value.toFixed(1)}</h1>}

    {ratings !== undefined && (
      <p className={'ratingsNumber'}>({ratings} ratings)</p>
    )}
  </div>
)

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  ratings: PropTypes.number,
  showValue: PropTypes.bool,
  inputMode: PropTypes.bool,
}

Rating.defaultProps = {
  value: 0,
  showValue: false,
  inputMode: false,
}

export default Rating
