import React from 'react'
import { getField, rotateArray } from '../../../Helpers/Misc'
import { throttle } from 'throttle-debounce'
import date from 'date-and-time'

export const slotSelectionSteps = 30 //minutes
export const slotDuration = 60 //minutes

export const startOfTheWeek = (day) =>
  new Date(
    new Date(day.getTime()).setDate(
      new Date(day.getTime()).getDate() -
        ((new Date(day.getTime()).getDay() + 6) % 7),
    ),
  )

export const getMinutesOffset = (time) => {
  const date = new Date(time)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return hours * 60 + minutes
}

export const getWeek = (day) => {
  const monday = startOfTheWeek(day)

  return Array.from({ length: 7 }).map((_, i) => {
    const day = new Date(monday.getTime())
    day.setDate(day.getDate() + i)
    return day
  })
}

export const createUnitCalendarSlot = (
  startTime,
  type,
  hoverInfo,
  duration = 60 * 60 * 1000,
) => ({
  exactStartTime: startTime,
  exactEndTime: startTime + duration,
  startDay:
    new Date(startTime).getDay() - 1 < 0
      ? 7 + new Date(startTime).getDay() - 1
      : new Date(startTime).getDay() - 1,
  endDay:
    new Date(startTime + duration).getDay() - 1 < 0
      ? 7 + new Date(startTime + duration).getDay() - 1
      : new Date(startTime + duration).getDay() - 1,
  startTime: getMinutesOffset(startTime),
  start: getMinutesOffset(startTime) / slotSelectionSteps,
  endTime: getMinutesOffset(startTime + duration),
  end:
    getMinutesOffset(startTime + duration) / slotSelectionSteps ||
    (24 * 60) / slotSelectionSteps,
  type,
  hoverInfo,
})

export const groupSlotsByDay = (slots) =>
  Array.from({ length: 7 }).map((_, dayIndex) =>
    slots.filter((slot) => slot.startDay === dayIndex),
  )

export const splitToFitInDays = (slots) =>
  slots
    .map((slot) =>
      slot.startDay !== slot.endDay && slot.endTime
        ? [
            {
              ...slot,
              endDay: slot.startDay,
              endTime: 24 * 60,
              end: (24 * 60) / slotSelectionSteps,
            },
            { ...slot, startDay: slot.endDay, startTime: 0, start: 0 },
          ]
        : [slot],
    )
    .reduce((reduced, current) => [...reduced, ...current], [])

export const parseBlockingSlots = (slots, type) =>
  groupSlotsByDay(
    splitToFitInDays(
      slots
        .filter((slot) => getField(slot, 'info.status') !== 'DRAFT')
        .map((slot) =>
          createUnitCalendarSlot(slot.startTime, type, slot.courseInfo),
        ),
    ),
  )

export const parseMarkedSlots = (teacherSlots) =>
  rotateArray(
    groupSlotsByDay(
      splitToFitInDays(
        teacherSlots.map((slot) => ({
          startDay: new Date(slot.startTime).getDay(),
          endDay: new Date(slot.endTime).getDay(),
          startTime: getMinutesOffset(slot.startTime),
          start: getMinutesOffset(slot.startTime) / slotSelectionSteps,
          endTime: getMinutesOffset(slot.endTime),
          end:
            getMinutesOffset(slot.endTime) / slotSelectionSteps ||
            (24 * 60) / slotSelectionSteps,
          type: 'availability',
          exactStartTime: new Date(slot.startTime),
          exactEndTime: new Date(slot.endTime),
        })),
      ),
    ),
    1,
  )

export const slotsInRange = (slots, start, end) =>
  slots.map((day) => {
    return day.filter((slot) => {
      return (
        new Date(slot.exactStartTime) > start.getTime() &&
        new Date(slot.exactEndTime) <= end.getTime()
      )
    })
  })

export const slotsInWeek = (slots, currentWeek) => {
  const week = currentWeek.map((day) => new Date(day.getTime()))
  const startOfTheWeek = new Date(week[0].setHours(0, 0, 0, 0))
  const endOfTheWeek = new Date(week.slice(-1)[0].setHours(24, 0, 0, 0))
  return slotsInRange(slots, startOfTheWeek, endOfTheWeek)
}

export const createTempCalendarRange = (startTime, type, hoverInfo) => ({
  exactStartTime: startTime,
  exactEndTime: new Date(startTime.getTime() + 30 * 60 * 1000),
  startDay:
    new Date(startTime).getDay() - 1 < 0
      ? 7 + new Date(startTime).getDay()
      : new Date(startTime).getDay(),
  endDay:
    new Date(startTime + 30 * 60 * 1000).getDay() - 1 < 0
      ? 7 + new Date(startTime + 30 * 60 * 1000).getDay() - 1
      : new Date(startTime + 30 * 60 * 1000).getDay() - 1,
  startTime: getMinutesOffset(startTime),
  start: getMinutesOffset(startTime) / slotSelectionSteps,
  initialStart: getMinutesOffset(startTime) / slotSelectionSteps,
  endTime: getMinutesOffset(startTime + 30 * 60 * 1000),
  end:
    getMinutesOffset(startTime.getTime() + 30 * 60 * 1000) /
      slotSelectionSteps || (24 * 60) / slotSelectionSteps,
  initialEnd:
    getMinutesOffset(startTime.getTime() + 30 * 60 * 1000) /
      slotSelectionSteps || (24 * 60) / slotSelectionSteps,
  type,
  hoverInfo,
  temp: true,
})

export const generateLayerData = (calendarStore) => [
  {
    slots: Array.from({ length: 7 }).map(() => []),
    slotType: 'hour',
    tag: {
      marked: '',
      unmarked: 'base',
    },
    action: () => {},
    data: {},
  },
  {
    slots: calendarStore.state.marked,
    slotType: 'weekly-range',
    tag: {
      marked: 'available',
      unmarked: '',
    },
    action: calendarStore.pickSlot,
    label: <p>Available</p>,
    data: {},
  },
  {
    slots: calendarStore.state.teacherAvailabilitySlots,
    slotType: 'weekly-range',
    tag: {
      marked: 'teacherAvailability',
      unmarked: '',
    },
    action: () => {},
    label: <p>Available</p>,
    data: {},
  },
  calendarStore.state.mode === 'becomeTeacher' ||
  calendarStore.state.mode === 'editAvailability'
    ? {
        slots: calendarStore.state.tempMarked,
        slotType: 'weekly-range',
        tag: {
          marked: 'tempAvailable',
          unmarked: 'tempAvailable unmarked',
        },
        action: {
          pressed: calendarStore.onSlotPressed,
          move: throttle(100, calendarStore.onSlotMove),
          released: calendarStore.onSlotReleased,
        },
        label: <p>Available</p>,
        data: {},
      }
    : {
        slots: Array.from({ length: 7 }).map(() => []),
        slotType: 'hour',
        tag: {
          marked: '',
          unmarked: '',
        },
        action: () => {},
        label: '',
        data: {},
      },
  {
    slots: calendarStore.state.userBookings,
    slotType: 'hour',
    tag: {
      marked: 'userBooking',
      unmarked: '',
    },
    action: () => {},
    label: <p>Active Class</p>,
    data: {},
  },
  {
    slots: calendarStore.state.teacherBookings,
    slotType: 'hour',
    tag: {
      marked: 'teacherBooking',
      unmarked: '',
    },
    action: () => {},
    label: <p>Filled</p>,
    data: {},
  },
  {
    slots: calendarStore.state.studentApprovedSlots,
    slotType: 'hour',
    tag: {
      marked: 'studentApprovedSlots',
      unmarked: '',
    },
    action: () => {},
    label: (day, index, weekOffset, info, hoverInfo) => {
      return (
        <div className='pickedSlotLabel'>
          <p>{hoverInfo.title}</p>
          <p>
            {getSlotTimeRangeFromIndex(index, calendarStore.state.slotDuration)}
          </p>
        </div>
      )
    },
    data: {},
  },
  {
    slots: calendarStore.state.studentPendingSlots,
    slotType: 'hour',
    tag: {
      marked: 'studentPendingSlots',
      unmarked: '',
    },
    action: () => {},
    label: (day, index, weekOffset, info, hoverInfo) => {
      return (
        <div className='pickedSlotLabel'>
          <p>{hoverInfo.title}</p>
          <p>
            {getSlotTimeRangeFromIndex(index, calendarStore.state.slotDuration)}
          </p>
        </div>
      )
    },
    data: {},
  },
  {
    slots: calendarStore.state.teacherSlots,
    slotType: 'hour',
    tag: {
      marked: 'teacherSlots',
      unmarked: '',
    },
    action: () => {},
    label: (day, index, weekOffset, info, hoverInfo) => {
      return (
        <div className='pickedSlotLabel'>
          <p>{hoverInfo.info.title}</p>
          <p>
            {getSlotTimeRangeFromIndex(index, calendarStore.state.slotDuration)}
          </p>
        </div>
      )
    },
    data: {},
  },
  {
    slots: calendarStore.state.pickedSlots,
    slotType: 'hour',
    tag: {
      marked: 'pickedSlot',
      unmarked: '',
    },
    action: (day, index) => calendarStore.removeSlot(day, index),
    label: (day, index, weekOffset, info) => (
      <div className='pickedSlotLabel'>
        <p>{info.name}</p>
        <p>
          {getSlotTimeRangeFromIndex(index, calendarStore.state.slotDuration)}
        </p>
      </div>
    ),
    data: {},
  },
]

export const getSlotsForTheDay = (allSlots, slotType, currentWeek, day) => {
  // if (slotType === "hour") {
  //   console.log({
  //     allSlots,
  //     currentWeek,
  //     siw: slotsInWeek(allSlots, currentWeek)
  //   });
  // }
  return slotType === 'hour'
    ? slotsInWeek(allSlots, currentWeek)[day]
    : slotType === 'weekly-range'
    ? allSlots[day]
    : Array.from({ length: 7 }).map(() => [])
}

export const getFirstWeekSlots = (slots) =>
  slots
    .reduce(
      (weeklySlots, currentSlot) =>
        weeklySlots.find(
          (slot) =>
            new Date(slot.startTime).toLocaleTimeString() ===
              new Date(currentSlot.startTime).toLocaleTimeString() &&
            new Date(slot.startTime).getDay() ===
              new Date(currentSlot.startTime).getDay(),
        )
          ? weeklySlots
          : [...weeklySlots, currentSlot],
      [],
    )
    .sort((a, b) => a.startTime - b.startTime)

export const cleanAndMergeSlots = (slots, endSlot) => {
  const getOverlappingSlots = (slots) => {
    let overlappingSlots = null

    slots.find((testSlot, testIndex) => {
      overlappingSlots = null
      const overlappingSlot = slots.findIndex(
        (slot, index) =>
          index !== testIndex &&
          (slot.end === testSlot.start ||
            slot.start === testSlot.end ||
            (slot.start >= testSlot.start && slot.start <= testSlot.end) ||
            (slot.end >= testSlot.start && slot.end <= testSlot.end)),
      )

      if (overlappingSlot !== -1) {
        overlappingSlots = [overlappingSlot, testIndex]
        return true
      }

      return false
    })

    return overlappingSlots
  }

  const mergeSlots = (slots, firstIndex, secondIndex) => {
    const firstItem = slots[firstIndex]
    const secondItem = slots[secondIndex]

    const result = { ...firstItem }

    if (secondItem.start < firstItem.start) {
      result.start = secondItem.start
      result.startTime = secondItem.startTime
      result.exactStartTime = secondItem.exactStartTime
      result.startDay = secondItem.startDay
    }
    if (secondItem.end > firstItem.end) {
      result.end = secondItem.end
      result.endTime = secondItem.endTime
      result.exactEndTime = secondItem.exactEndTime
      result.endDay = secondItem.endDay
    }

    slots.splice(firstIndex, 1, result)
    slots.splice(secondIndex, 1)

    return slots
  }

  const merge = (slots) => {
    let overlapping = false
    do {
      const overlappingSlots = getOverlappingSlots(slots)
      if (overlappingSlots) {
        overlapping = true
        slots = mergeSlots(slots, ...overlappingSlots)
      } else {
        overlapping = false
      }
    } while (overlapping)
  }

  return slots.map((day, dayIndex) =>
    day.reduce((merged, current) => {
      let result = [...merged, current]

      merge(result)

      if (endSlot && dayIndex === endSlot.day) {
        result = result.map((result) => {
          if (result.start === endSlot.index && result.end - result.start < 2) {
            if (result.end !== 48) {
              result.end = result.end + 1
            }
          }
          return result
        })
      }

      merge(result)

      result = result.filter((range) => range.end - range.start >= 2)

      return result
    }, []),
  )

  // slots.map(day => {
  //   return day
  //     .reduce((merged, current) => {
  //       let result = [...merged];
  //       if (!result.length) {
  //         result.push(current);
  //       } else {
  //         const before = result.findIndex(slot => slot.end === current.start);
  //
  //         if (before !== -1) {
  //           result[before].end = current.end;
  //         }
  //
  //         const after = result.findIndex(slot => slot.start === current.end);
  //
  //         if (after !== -1) {
  //           if (before !== -1) {
  //             result[after].start = result[before].start;
  //             result.splice(before, 1);
  //           } else {
  //             result[after].start = current.start;
  //           }
  //         }
  //
  //         if (before === -1 && after === -1) {
  //           result.push(current);
  //         }
  //       }
  //
  //       return result;
  //     }, [])
  //     .filter(ranges => ranges.end - ranges.start >= 2);
  // });
}

export const getSlotTimeRangeFromIndex = (index, duration = 60) => {
  const start = new Date()
  start.setHours(0, 30 * index, 0, 0)
  const end = new Date()
  end.setHours(0, 30 * (index + Number(duration) / 30), 0, 0)

  return `${date.format(start, `hh:mm A`)} - ${date.format(end, `hh:mm A`)}`
    .split('.')
    .join('')
    .toUpperCase()
}

export const checkForTakenSlots = (group, testSlot) =>
  group
    .reduce((merged, current) => [...merged, ...current])
    .find(
      (slot) =>
        slot.exactStartTime === testSlot.exactStartTime ||
        (slot.exactStartTime < testSlot.exactStartTime &&
          slot.exactEndTime > testSlot.exactStartTime) ||
        (slot.exactStartTime < testSlot.exactEndTime &&
          slot.exactEndTime > testSlot.exactEndTime),
    )

export const parseAvailability = (slots = []) =>
  slots &&
  slots
    .map((slot) => ({
      day: date.format(new Date(slot.startTime), 'dddd'),
      time: [
        {
          start: date.format(new Date(slot.startTime), 'h:mmA').toUpperCase(),
          end: date.format(new Date(slot.endTime), 'h:mmA').toUpperCase(),
        },
      ],
    }))
    .reduce((reduced, current, index, array) => {
      const uniqueIndex = array.findIndex((slot) => slot.day === current.day)
      const uniqueDaysIndex = array
        .filter(
          (slot, index) => array.findIndex((s) => s.day === slot.day) === index,
        )
        .findIndex((slot) => slot.day === current.day)
      if (uniqueIndex === index) {
        return [...reduced, current]
      } else {
        let updated = reduced
        updated[uniqueDaysIndex] = {
          ...updated[uniqueDaysIndex],
          time: [...updated[uniqueDaysIndex].time, ...current.time],
        }
        return updated
      }
    }, [])
    .map(
      (day) =>
        `${day.day}: ${day.time
          .reduce((string, slot) => `${string}, ${slot.start}-${slot.end}`, ``)
          .substr(2)}`,
    )
