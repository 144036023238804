import React from 'react'
import { CardEl } from '../elements'
import { motion } from 'framer-motion'
import Avatar from '../../../../../Components/Avatar'
import { H4 } from '../../../../../Styles/typography'

const Card = ({
  id,
  user,
  artist,
  courseName,
  course,
  userName,
  userProfilePicture,
  review,
  text,
  teacherName,
  position,
}) => {
  return (
    <CardEl
      key={id}
      as={motion.div}
      initial={{ x: '250%' }}
      animate={{
        x: position * 115 + '%',
        scale: !position ? 1 : 0.85,
        transition: { duration: 0.5 },
      }}
      exit={{ x: '-250%' }}
    >
      <div className='courseInfo'>
        <H4 bold style={{ marginBottom: '0.25em' }}>
          {courseName || course}
        </H4>
        <p>
          By <span>{teacherName || artist?.name}</span>
        </p>
      </div>
      <p>
        <span className='quoteMarks' />
        {review || text}
      </p>
      <div className='student'>
        <Avatar
          name={userName || user?.name}
          image={userProfilePicture?.location || user?.picture}
          size={3}
        />
        <p>{userName || user?.name}</p>
      </div>
    </CardEl>
  )
}

export default Card
