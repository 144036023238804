import React, { useState } from 'react'
import './List.sass'
import PropTypes from 'prop-types'
import CourseCard from '../Cards/CourseCard'
import Button from '../Buttons/Button'
import Loading from '../Loading/Loading'
import { useHistory } from 'react-router-dom'
import { Event } from '../../Helpers/GoogleAnalytics'
import ResumeCourseCard from '../Cards/ResumeCourseCard'

const CourseList = ({
  data,
  name,
  loading,
  loadFailed,
  onCardClick,
  hideViewMore = false,
  type = 'courses',
  limit = 6,
  redirect = false,
}) => {
  const history = useHistory()
  const [nCourses, setNCourses] = useState(limit)
  return (
    <>
      {loading && <Loading full />}
      {loadFailed && <p>Failed to load {name}</p>}
      {!loading &&
        !loadFailed &&
        (data.length ? (
          data.length >= limit && !redirect ? (
            <>
              <div className='courseList'>
                {data
                  .slice(0, nCourses)
                  .map((course, i) =>
                    type === 'resume' ? (
                      <ResumeCourseCard
                        key={`courseCard${i}`}
                        index={i}
                        {...course}
                        onClick={onCardClick}
                      />
                    ) : (
                      <CourseCard
                        key={`courseCard${i}`}
                        index={i}
                        {...course}
                        onClick={onCardClick}
                      />
                    ),
                  )}
              </div>
              {!hideViewMore && nCourses !== data.length ? (
                <div className='viewMore'>
                  <Button
                    onClick={() => {
                      setNCourses((prev) => Math.min(prev + limit, data.length))
                    }}
                    name={'View More'}
                    type={'primary big'}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className='courseList'>
                {data
                  .slice(0, limit)
                  .map((course, i) =>
                    type === 'resume' ? (
                      <ResumeCourseCard
                        key={`courseCard${i}`}
                        index={i}
                        {...course}
                        onClick={onCardClick}
                      />
                    ) : (
                      <CourseCard
                        key={`courseCard${i}`}
                        index={i}
                        {...course}
                        onClick={onCardClick}
                      />
                    ),
                  )}
              </div>
              {!hideViewMore && type !== 'resume' && (
                <div className='viewMore'>
                  <Button
                    onClick={() => {
                      history.push('/' + type)
                      Event('View More', 'Clicked View More Course')
                    }}
                    name={'View More'}
                    type={'primary big'}
                  />
                </div>
              )}
            </>
          )
        ) : (
          <p className={'noItems'}>No {name}</p>
        ))}
    </>
  )
}

CourseList.propTypes = {
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  limit: PropTypes.number,
  loadFailed: PropTypes.bool.isRequired,
  viewMore: PropTypes.func.isRequired,
}

CourseList.defaultProps = {
  data: [],
  name: '',
  loading: false,
  loadFailed: false,
  limit: 9,
  viewMore: () => {},
  onCardClick: () => {},
}

export default CourseList
