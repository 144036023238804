export const getInitials = (name = '') => {
  const words = name.split(' ')

  if (words.length >= 2) {
    const first = words[0]
    const last = words.slice(-1)[0]
    return first.charAt(0) + last.charAt(0)
  } else {
    return words[0].slice(0, 2)
  }
}
