import { useEffect, useState } from 'react'

const useTime = (updateInterval) => {
  const [time, setTime] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), updateInterval)
    return () => clearInterval(interval)
  }, [updateInterval])

  return time
}

export default useTime
