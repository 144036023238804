import React from 'react'
import ReactDOM from 'react-dom'
import './Styles/index.sass'
import App from './App/App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'unstated'
import { linkedStores } from './Helpers/Stores'
import { SWRConfig } from 'swr'
import { API } from './API/config'
import ms from 'ms'
import { StyleSheetManager } from 'styled-components'

const stores = linkedStores()

ReactDOM.render(
  <Provider inject={stores}>
    <SWRConfig
      value={{
        focusThrottleInterval: ms('15m'),
        refreshInterval: ms('30m'),
        fetcher: (url, parser = (x) => x, method = 'get') =>
          API[method](url)
            .then((res) => res.data.data)
            .then(parser),
      }}
    >
      <StyleSheetManager disableCSSOMInjection>
        <App />
      </StyleSheetManager>
    </SWRConfig>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.register()
