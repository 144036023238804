import { API } from './config'
import { getField } from '../Helpers/Misc'
import { debounce } from 'debounce'

export const initTeacherDashboard = (crid) =>
  API.get(`/courses/teacher/${crid}`)
    .then(({ data }) => ({
      courseName: data.data.course.name,
      courseDescription: data.data.course.description,
      courseFee: data.data.course.fee,
      courseId: data.data.course.id,
      chapters: data.data.course.chapters,
      classes: data.data.course.classes,
      students: data.data.course.students.map((student) => ({
        ...student,
        classes: Object.keys(student.classes).map(
          (key) => student.classes[key],
        ),
      })),
      teacher: data.data.teacher,
      role: 'teacher',
    }))
    .catch(() => false)

export const initStudentDashboard = (crid) =>
  API.get(`/courses/student/${crid}`)
    .then(({ data }) => ({
      courseName: data.data.course.name,
      courseDescription: data.data.course.description,
      courseFee: data.data.course.fee,
      chapters: data.data.course.chapters,
      crid,
      classes: Object.keys(data.data.course.classes).map(
        (key) => data.data.course.classes[key],
      ),
      teacher: data.data.teacher,
      courseId: data.data.course.id,
      role: 'student',
    }))
    .catch(() => false)

export const initClassroom = (registrationID, classID) =>
  API.put(`/course-registrations/${registrationID}/classes/${classID}/join`)
    .then((response) => getField(response, 'data.data'))
    .catch(() => false)

export const extendClass = (registrationID, classID, batchId) =>
  API.put(
    batchId
      ? `/batch/${batchId}/classes/${classID}/extend`
      : `/course-registrations/${registrationID}/classes/${classID}/extend`,
  )
    .then(() => true)
    .catch(() => false)

export const uploadedRecordedSession = (data) =>
  API.post(`/upload/raw`, data, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
    timeout: 30000,
  })
    .then((response) => response)
    .catch(() => false)

export const updateClassRating = (data) =>
  API.post(`/feedback/class`, data)
    .then((response) => response)
    .catch(() => false)

export const updateClassNote = (registrationID, classId, content) =>
  API.put(`/course-registrations/${registrationID}/classes/${classId}/notes`, {
    studentClassNote: content,
  })
    .then(() => true)
    .catch(() => false)

export const sendToStudent = (
  courseID,
  teacherID,
  studentID,
  document,
  type,
  uploadProgress,
) => {
  if (type === 'document' || type === 'blob') {
    return API.post(
      `/sendToStudent/course/${courseID}/student/${studentID}/teacher/${teacherID}`,
      document,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${document._boundary}`,
        },
        onUploadProgress: uploadProgress,
        timeout: 30000,
      },
    )
      .then((response) => response)
      .catch(() => false)
  } else {
    return API.post(
      `/sendToStudent/course/${courseID}/student/${studentID}/teacher/${teacherID}`,
      document,
    )
      .then((response) => response)
      .catch(() => false)
  }
}

export const addToClass = (
  studentID,
  courseId,
  chapterId,
  lessonId,
  document,
  type,
  uploadProgress,
) => {
  if (type === 'document' || type === 'blob') {
    return API.post(
      `/course/${courseId}/chapter/${chapterId}/lesson/${lessonId}/documents/?studentId=${studentID}`,
      document,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${document._boundary}`,
        },
        onUploadProgress: uploadProgress,
        timeout: 30000,
      },
    )
      .then((response) => response)
      .catch(() => false)
  } else {
    return API.post(
      `/course/${courseId}/chapter/${chapterId}/lesson/${lessonId}/links/?studentId=${studentID}`,
      document,
    )
      .then((response) => response)
      .catch(() => false)
  }
}

export const updateClassNoteDebounced = debounce(updateClassNote, 500)

export const setActiveLessonChapter = (data, crid) =>
  API.put(`/course-registrations/${crid}/current-lesson`, data)
    .then((response) => response)
    .catch(() => false)
