import { createContainer } from 'unstated-next'
import { useEffect, useState } from 'react'
import { Realtime } from 'ably'

const RealtimeEventsStore = createContainer(() => {
  const [userId, updateUserId] = useState('')
  const [connection, setConnection] = useState(null)
  const [channel, updateChannel] = useState(null)

  useEffect(() => {
    if (userId) {
      const connection = new Realtime(process.env.REACT_APP_ABLY_KEY)
      setConnection(connection)
      updateChannel(connection.channels.get(userId))
    } else {
      updateChannel(null)
    }
  }, [userId])

  return { connection, channel, updateUserId }
})

export default RealtimeEventsStore
