import { getField } from './Misc'

export const parseCourseListData = (
  courses = [],
  recorded = false,
  groupOnly = false,
) =>
  courses
    .filter(({ isArchived }) => !isArchived)
    .filter(({ isGroupCourse }) => (!groupOnly ? true : isGroupCourse))
    .map((course) => ({
      name: getField(course, 'info.title'),
      teacher: getField(course, 'teacherName'),
      type: getField(course, 'type'),
      duration: getField(course, 'info.noOfClasses') || 0,
      discount: getField(course, 'discount'),
      fee: getField(course, 'fee'),
      cover: getField(course, 'info.courseCover.location'),
      url: `/${getField(course, 'teacherLivdemyId')}/course/${getField(
        course,
        'info.courseUrl',
      )}`,
      isGroupCourse: getField(course, 'isGroupCourse'),
    }))

export const parseInstrumentsListData = (course) => ({
  name: getField(course, 'info.title'),
  teacher: getField(course, 'teacher.teacherProfile.name'),
  duration: getField(course, 'info.noOfClasses') || 0,
  fee: getField(course, 'fee'),
  cover: getField(course, 'info.courseCover.location'),
  url: `/${getField(course, 'teacher.livdemyId')}/${getField(
    course,
    'info.courseUrl',
  )}`,
})

export const parseDraftCourseData = (course) => ({
  id: course.id,
  type: course.type,
  isGroupCourse: getField(course, 'isGroupCourse'),
  name: getField(course, 'info.title'),
  description: getField(course, 'info.description'),
  fee: getField(course, 'fee.amount'),
  currency: getField(course, 'fee.currency'),
  status: getField(course, 'status'),
  numberOfClasses: getField(course, 'info.noOfClasses'),
  courseUrl: getField(course, 'info.courseUrl'),
  cover: {
    file: getField(course, 'info.courseCover'),
    progress: 0,
    uploading: false,
  },
  instruments: getField(course, 'info.instruments') || [],
  courseObjectives: getField(course, 'info.courseObjectives'),
  preRequisites: getField(course, 'info.preRequisites'),
  chapters: (course.chapters || []).map((chapter) => ({
    ...chapter,
    name: chapter.title,
    lessons: (chapter.lessons || []).map((lesson) => ({
      ...lesson,
      name: lesson.title,
      documents: (lesson.media || []).map((document) => ({
        ...document,
        name: document.fileName,
        type: document.fileType,
        uploaded: true,
        size: document.fileSize,
        url: document.location,
      })),
      attachments: Object.keys(lesson.links || {}).map(
        (key) => lesson.links[key],
      ),
    })),
  })),
  startTime: getField(course, 'startTime'),
})

export const parseTeacherData = (teacherData) =>
  teacherData.map((teacher) => ({
    name: teacher.name,
    place: teacher.city,
    picture: getField(teacher, 'picture.location'),
    rating: 3.8,
    instruments: teacher.instruments,
    url: `/${teacher.livdemyId}`,
    sessionTypes: getField(teacher, 'sessionTypes', []),
  }))

export const parseCourseData = (data) => ({
  teacher: {
    ...data.data.course.teacherDetails,
    id: data.data.course.teacherId,
    teaches: (data.data.course.teacherDetails.skills || [])
      .reduce((merged, current) => `${merged}, ${current}`, '')
      .slice(2),
    image: getField(
      data.data.course.teacherDetails,
      'coverImage.document.metaData.s3.extra.Location',
    ),
  },
  metaDescription: data.data.course.metaDescription,
  metaTitle: data.data.course.metaTitle,
  metaSchema: data.data.course.metaSchema,
  metaKeywords: data.data.course.metaKeywords,
  hasCertification: data.data.course.hasCertification,
  certificationFeePremium: data.data.course.certificationFeePremium,
  forumId: data.data.course.forumId,
  discount: data.data.course.discount,
  coverVideoUrl: data.data.course.coverVideoUrl,
  type: data.data.course.type || 'live',
  doFollow: data.data.doFollow,
  isGroupCourse: data.data.course.isGroupCourse,
  batches: data.data.batches,
  objectives: data.data.course.info.courseObjectives,
  preRequisites: data.data.course.info.preRequisites,
  name: data.data.course.info.title,
  enrollments: data.data.courseEnrollments
    .filter((enrollment) =>
      data.data.course.type === 'live' || data.data.course.isGroupCourse
        ? enrollment.status === 'APPROVED'
        : true,
    )
    .reverse(),
  pendingEnrollmentRequest: data.data.courseEnrollments.find(
    (enrollment) => enrollment.status === 'DRAFT',
  ),
  isEnrolled: !!data.data.courseEnrollments
    .filter((enrollment) =>
      data.data.course.type === 'live' || data.data.course.isGroupCourse
        ? enrollment.courseEndTime > new Date().getTime() &&
          enrollment.status === 'APPROVED'
        : true,
    )
    .filter((enrollment) => {
      if (data.data.course.type === 'live' || data.data.course.isGroupCourse) {
        if (enrollment?.billStatus) return enrollment.billStatus === 'PAID'
        return enrollment.status === 'APPROVED'
      }
      return enrollment.status === 'PAID'
    }).length,
  startTime: data.data.course.startTime,
  description: data.data.course.info.description,
  instruments: data.data.course.info.instruments,
  fee: data.data.course.fee,
  id: data.data.course.id,
  chapters: data.data.course.chapters,
  cover: getField(data, 'data.course.info.courseCover.location'),
  noOfClasses: data.data.course.info.noOfClasses,
  rating: {
    value: parseFloat(data.data.rating.rating || 0),
    number: parseFloat(data.data.rating.noOfReviews),
  },
  wishListed: data.data.wishlisted,
  relatedCourses: parseCourseListData(data.data.similarCourses),
  studentsEnrolled: data.data.noOfStudents,
  isAuditionVideoReq:
    data.data.course?.isAuditionVideoReq === undefined
      ? true
      : data.data.course.isAuditionVideoReq,
  isApprovalReq:
    data.data.course?.isApprovalReq === undefined
      ? true
      : data.data.course.isApprovalReq,
  coverVideo: data.data.course?.info?.courseCoverVideo?.location || '',
})

export const parseProfileInfo = (data) => {
  return {
    name: data.profile.name || '',
    email: data.email || '',
    phone: data.profile.phoneNumber || data.phoneNumber || '',
    about: data.about || '',
    gender: data.profile.gender || data.gender || '',
    city: data.profile.city || '',
    picture: getField(data, 'profile.profilePhoto.location', ''),
    id: data.id || '',
    whatsappNumber: data.whatsappNumber || '',
    whatsappNotifPref:
      data?.notif_prefs?.bc_reminder?.channels?.whatsapp || false,
    timeZone: data?.timeZone || '',
    emailPref: data?.notif_prefs?.marketing?.channels?.email || false,
    isTeacher: Boolean(data?.roles?.TEACHER),
  }
}

export const parseSchoolData = (data) => {
  const teachers = data.teachers || {}
  delete teachers.createdAt
  delete teachers.updatedAt
  return {
    name: data.name || '',
    location: data.location || '',
    teachers,
    schoolHandle: data.schoolHandle || '',
    id: data.id || '',
  }
}
