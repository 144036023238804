import React from 'react'
import { withRouter } from 'react-router-dom'
import CourseList from '../../../Components/Lists/CourseList'
import HomeStore from '../Store/home-store'

const FeaturedRecordedCourses = () => {
  const { loading, loadFailed, data } = HomeStore.useContainer()

  return (
    <div className='featuredRecordedCourses'>
      <div className='sectionHeaderContainer'>
        <h1 className={'sectionHeader'}>Premium Courses</h1>

        <p className='sectionSubHeader'>
          Experience musical mastery with our meticulously structured recorded
          course, a comprehensive journey through theory, composition, and
          production.
        </p>
      </div>
      <CourseList
        data={data?.recordedCourses || []}
        loading={loading}
        loadFailed={loadFailed}
        name={'Featured Courses'}
        limit={3}
        onCardClick={() => {}}
        type='premium'
        redirect={true}
      />
    </div>
  )
}

export default withRouter(FeaturedRecordedCourses)
