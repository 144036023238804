import { API } from './config'
import { getField } from '../Helpers/Misc'
import { parseProfileInfo } from '../Helpers/API'
import { classesToArray } from '../App/Course/Helpers/Timings'
import { getLocalCurrency } from './Billing'
import axios from 'axios'

export const getProfileData = () =>
  API.get('/profile')
    .then((res) => parseProfileInfo(res.data.data))
    .catch(() => false)

export const uploadProfilePicture = (data) =>
  API.put(`/profile-picture/upload`, data)
    .then((res) => getField(res, 'data.data'))
    .catch(() => false)

export const getUserCalendar = () =>
  API.get('/profile/calendar/').then(console.log).catch(console.log)

export const updateProfileDetails = (data) =>
  API.put('/profile', data)
    .then((res) => parseProfileInfo(res.data.data))
    .catch(console.log)

export const changePassword = (data) =>
  API.put('/auth/change-password', data)
    .then((response) => response)
    .catch(console.log)

export const getInvoices = async () =>
  API.get('/bills', {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then(async (res) => {
      const localCurrency = await getLocalCurrency()
      return getField(res, 'data.data').map((invoice) => ({
        ...invoice,
        localCurrency,
      }))
    })
    .catch(() => false)

export const getInvoice = (id) =>
  API.get(`/bill/${id}`)
    .then((res) => getField(res, 'data.data'))
    .catch(() => false)

export const listDemoClasses = () =>
  API.get(`/dashboard/demo-class`)
    .then((res) => res.data.data)
    .catch(() => false)

export const getDemoClasses = (mode, userID) =>
  API.get(`/list-demo-class`)
    .then((res) => {
      const { demoClasses, users } = res.data.data
      return [
        ...demoClasses
          .filter(({ [mode + 'Id']: id }) => id === userID)
          .filter(({ startTime }) => startTime + 30 * 60 * 1000 > new Date())
          .map((demoClass) => ({
            ...demoClass,
            participants: Object.keys(demoClass.participants).map((key) => ({
              id: key,
              name: getField(users[key], 'name'),
            })),
            studentName: users[demoClass.studentId].name,
            teacherName: users[demoClass.teacherId].name,
          }))
          .sort((a, b) => a.startTime - b.startTime),
        ...demoClasses
          .filter(({ [mode + 'Id']: id }) => id === userID)
          .filter(({ startTime }) => startTime + 30 * 60 * 1000 < new Date())
          .map((demoClass) => ({
            ...demoClass,
            participants: Object.keys(demoClass.participants).map((key) => ({
              id: key,
              name: getField(users[key], 'name'),
            })),
            studentName: users[demoClass.studentId].name,
            teacherName: users[demoClass.teacherId].name,
          }))
          .sort((a, b) => a.startTime - b.startTime),
      ]
    })
    .catch(() => false)

export const getAllDemoClasses = () =>
  API.get(`/list-demo-class`)
    .then((res) => {
      const { demoClasses, users } = res.data.data
      return (
        demoClasses
          // .filter(({ startTime }) => startTime + 30 * 60 * 1000 > new Date())
          .map((demoClass) => ({
            ...demoClass,
            participants: Object.keys(demoClass.participants).map((key) => ({
              id: key,
              name: getField(users[key], 'name'),
            })),
            studentName: users[demoClass.studentId].name,
            teacherName: users[demoClass.teacherId].name,
          }))
          .sort((a, b) => a.startTime - b.startTime)
      )
    })
    .catch(() => false)

export const getStudentCalendar = () =>
  API.get('/profile/student/courses/all')
    .then((res) => ({
      approved: res.data.data
        .filter(({ status }) => status === 'APPROVED')
        .map((enrollment) => ({
          classes: classesToArray(enrollment.courseRegistration.classes),
          courseInfo: enrollment.courseInfo,
          teacherInfo: enrollment.teacherInfo,
        })),
      pending: res.data.data
        .filter(({ status }) => status === 'DRAFT')
        .map((enrollment) => ({
          classes: classesToArray(enrollment.courseRegistration.classes),
          courseInfo: enrollment.courseInfo,
          teacherInfo: enrollment.teacherInfo,
        })),
    }))
    .catch(() => false)

export const getTeacherCalendar = () =>
  API.get('/profile/teacher/courses/all')
    .then((res) => {
      return res.data.data
        .reduce((merged, current) => {
          const { enrollments, ...courseInfo } = current
          return [
            ...merged,
            ...(enrollments.map((enr) => ({ ...enr, courseInfo })) || []),
          ]
        }, [])
        .filter(({ status }) => status === 'APPROVED')
        .map((enrollment) => {
          return {
            classes: classesToArray(enrollment.classes),
            courseInfo: enrollment.courseInfo,
            teacherInfo: enrollment.teacherInfo,
          }
        })
    })
    .catch(() => false)

export const getLivDemyID = () =>
  API.get('/teacher-info/')
    .then((res) => getField(res, 'data.data.livdemyId.value'))
    .catch(() => false)

export const getMyCalendar = () =>
  API.get(`/dashboard/calendar`)
    .then((res) => res.data.data)
    .catch(() => false)

export const getTipsAndTricks = (type) =>
  API.get(`/random-tnt?type=${type}`)
    .then((res) => res.data.data || {})
    .catch(() => false)

export const centerAnalytics = (type) =>
  API.get(`/${type}/center-analytics`)
    .then((res) => res.data.data || {})
    .catch(() => false)

export const badgeAnalytics = (type) =>
  API.get(`/${type}/badge-analytics`)
    .then((res) => res.data.data || {})
    .catch(() => false)

export const getCountryCode = () => {
  const saved = window.sessionStorage.getItem('countryCode')
  if (saved) {
    return saved
  }
  return axios
    .get('https://ipapi.co/json')
    .then(({ data: { country } }) => {
      window.sessionStorage.setItem('countryCode', country)
      return country
    })
    .catch(() => false)
}

export const getBankingTemplate = async () =>
  API.get(`/banking-template?countryCode=${await getCountryCode()}`)
    .then((res) => res.data.data)
    .catch(() => false)

export const saveBankingDetails = async (data) =>
  API.post(`/teacher/banking-details`, {
    countryCode: await getCountryCode(),
    bankingDetails: data,
  })
    .then((res) => res.data.data.bankingDetails)
    .catch(() => false)

export const getTeacherBills = () =>
  API.get('/teacher/bills')
    .then((res) =>
      res.data.data.map(
        ({
          id,
          currency,
          amount,
          createdAt: date,
          course: { title: courseName } = {},
          broadcastClass: { name: broadcastName } = {},
          user: { id: studentID, name: studentName },
          name,
          status,
        }) => ({
          id,
          date,
          courseName: courseName || broadcastName || name,
          studentName,
          studentID,
          currency,
          amount,
          status,
        }),
      ),
    )
    .catch(() => false)

export const getBankingDetails = () =>
  API.get('/teacher-info')
    .then((res) => res.data.data.bankingDetails)
    .catch(() => false)

export const cancelSubscriptionRequest = () =>
  API.delete('/subscription')
    .then(() => true)
    .catch(() => false)
