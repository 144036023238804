import { API } from './config'
import { parseBecomeTeacherStatusData } from '../Helpers/BecomeTeacher'
import { generateOptions, getField } from '../Helpers/Misc'

const debounce = require('debounce-promise')

export const getBecomeTeacherStatus = (impersonateID) =>
  API.get('/teacher-info/' + (impersonateID ? `?userId=${impersonateID}` : ''))
    .then((res) => parseBecomeTeacherStatusData(res.data.data))
    .catch(() => false)

export const getAvailability = () =>
  API.get(`/teacher-info/calendar`)
    .then((res) => getField(res, 'data.data.markings') || [])
    .catch(() => false)

export const getInstruments = () =>
  API.get('/teacher-info/teaching-categories/')
    .then((res) => generateOptions(getField(res, 'data.data')))
    .catch(() => false)

export const getLanguages = () =>
  API.get('/teacher-info/teaching-languages')
    .then((res) => generateOptions(getField(res, 'data.data')))
    .catch(() => false)

export const livDemyIDAvailability = debounce(
  (id) =>
    API.get(`/livdemy-id/${id}/is-available`)
      .then((res) => res.data.data)
      .catch(() => false),
  1000,
)

export const updateRequestData = (data) =>
  API.put(`/teacher-info/update/`, data)
    .then(() => true)
    .catch(() => false)

export const updateCalendarData = (slots) =>
  API.post(`/teacher-info/calendar`, { slots })
    .then(() => true)
    .catch(() => false)

export const submitBecomeTeacherRequest = (impersonateID) =>
  API.post(`/teacher-info/submit`, { userId: impersonateID })
    .then(() => true)
    .catch(() => false)

export const clearTeacherEdits = () =>
  API.put(`/teacher-info/cancel-edits/`)
    .then(() => true)
    .catch(() => false)
