import React from 'react'
import { Link } from 'react-router-dom'

const InstrumentSuggestion = ({ label, value, noIcon }) => (
  <Link to={value}>
    <div className='instrumentSuggestion'>
      {!noIcon && <span className='searchIcon instrumentIcon' />}
      <p>{label}</p>
    </div>
  </Link>
)

export default InstrumentSuggestion
