import date from 'date-and-time'
import { database } from 'firebase'
import { Container } from 'unstated'
import { getField } from '../../../Helpers/Misc'
import {
  customMessageActions,
  getParticipantInfo,
  sendChatMessage,
  updateLastSeen,
} from '../API/Messages'

const initialState = () => ({
  loaded: false,
  unreadCount: 0,
  conversations: [],
  tempConversations: [],
  message: '',
  pendingRequest: null,
  filterEnrollments: false,
  connectedToDB: false,
  lastSeen: Date.now(),
  me: {
    name: '',
    picture: '',
    id: '',
  },
})

export default class MessagingCoreStore extends Container {
  name = 'MessagingCoreStore'

  state = initialState()

  linkedStores = {}

  init = async () => {
    const user = this.linkedStores.UserStore.state

    if (!user || this.state.me.id === user.id) {
      return
    }

    await this.setState({
      ...initialState(),
      me: {
        name: user.profile.name,
        picture: user.picture,
        id: user.id,
      },
    })

    this.connectToDB(user.id)

    await this.setState({ loaded: true })
  }

  updateMessage = (message) => this.setState({ message })

  sendMessage = async (conversationID) => {
    const { pendingRequest, message } = this.state
    if (pendingRequest) {
      await customMessageActions(pendingRequest)
      await this.setState({ pendingRequest: null })
    }

    if (!message.trim()) {
      return
    }

    const success = await sendChatMessage(conversationID, { message })

    if (success) {
      await this.setState({ message: '' })
      await this.updateLastSeen(conversationID)
    }
  }

  customMessageAction = async (action, chatID, messageID) => {
    if (action.behaviour === 'DOCUMENT') {
      this.linkedStores.DocumentsViewerStore.openDocument(action.data)
    } else {
      const requestConfig = {
        method: action.method.toLowerCase(),
        data: {
          ...action.body,
          chatId: chatID,
          chatMessageId: messageID,
        },
        url: action.url,
      }

      if (action.shouldHaveReply) {
        await this.setState({
          pendingRequest: requestConfig,
          message: action.template,
        })
      } else {
        await customMessageActions(requestConfig)
      }
    }

    // eslint-disable-next-line no-useless-escape
    const isProcessCourse = /^\/course-registrations\/(?:([^\/]+?))\/process$/i

    if (isProcessCourse.test(action.url)) {
      const {
        state: {
          id: userID,
          role,
          profile: { name: userName },
        },
      } = this.linkedStores.UserStore

      this.linkedStores.UpcomingClassStore.init(userID, role, userName)
    }
  }

  connectToDB = (userID) => {
    let db = database()
    let conversations = db.ref(`/users/${userID}/conversations`)

    conversations.on('value', async (snap) => {
      let { metaData, lastSeenChatTimestamp, ...conversations } =
        snap.val() || {}

      const unreadCount = getField(metaData, 'lastSeenCount')

      await this.setState({ unreadCount })

      this.linkedStores.DashboardStore.updateCounters(
        'student',
        'messages',
        unreadCount,
      )

      this.linkedStores.DashboardStore.updateCounters(
        'teacher',
        'messages',
        unreadCount,
      )

      if (!conversations) {
        await this.setState({ connectedToDB: true })
        return
      }

      await this.setState({
        tempConversations: Object.keys(conversations).map((key) => ({
          ...conversations[key],
          id: key,
        })),
      })

      await Promise.all(
        Object.keys(conversations).map((key) =>
          this.updateConversationData(key),
        ),
      )

      const chatParticipants = await getParticipantInfo(
        this.state.tempConversations.map(({ id }) => id),
        userID,
      )

      await this.setState((state) => ({
        connectedToDB: true,
        conversations: state.tempConversations
          .map((conversation) => ({
            ...conversation,
            read: !!conversation.lastSeenFlag,
            chatParticipants: chatParticipants[conversation.id],
          }))
          .sort(
            (a, b) =>
              (getField(b, 'lastMessage.createdAt') || 0) -
              (getField(a, 'lastMessage.createdAt') || 0),
          ),
      }))

      Object.keys(conversations).map((key) => this.getMessages(key, userID))
    })
  }

  updateConversationData = async (id) => {
    let db = database()
    let conversation = db.ref(`/messaging/chats/${id}`)
    const conversationData = (await conversation.once('value')).val()

    const { tempConversations } = this.state

    const index = tempConversations.findIndex(
      (conversation) => conversation.id === id,
    )

    if (!conversationData) {
      tempConversations.splice(index, 1)
    } else {
      tempConversations[index] = {
        ...tempConversations[index],
        ...conversationData,
      }
    }

    await this.setState({ tempConversations })
  }

  getMessages = async (chatID, userID) => {
    let db = database()
    let conversationMessagesDB = db.ref(`/messaging/chatMessages/${chatID}`)

    //Get existing messages
    conversationMessagesDB.on('value', async (snap) => {
      const conversationMessages = snap.val() || {}
      const state = {}

      state[chatID] = Object.keys(conversationMessages).map((key) => {
        let actions =
          conversationMessages[key].special &&
          conversationMessages[key].special[userID] &&
          conversationMessages[key].special[userID].actions
        const data =
          conversationMessages[key].special &&
          conversationMessages[key].special[userID] &&
          conversationMessages[key].special[userID].data
        if (actions && data) {
          actions = actions.map((action) => ({
            ...action,
            icon: action.behaviour === 'DOCUMENT' && data.type,
          }))
        }
        return {
          ...conversationMessages[key],
          id: key,
          received: conversationMessages[key].sender !== userID,
          specialMessage:
            conversationMessages[key].special &&
            conversationMessages[key].special[userID] &&
            this.addParams(
              conversationMessages[key].special[userID].text ||
                conversationMessages[key].special[userID].message,
              conversationMessages[key].special[userID].data,
            ),
          actions,
          data,
        }
      })

      await this.setState(state)
    })
  }

  addParams = (str, data) => {
    let result = str
    if (data && str) {
      Object.keys(data).forEach((key) => {
        let value = data[key]
        if (key.toLowerCase().includes('date')) {
          let regExp = new RegExp(`@${key}\\(([^)]+)\\)`)
          if (!regExp.exec(str)) {
            return
          }
          value = date.format(new Date(data[key]), regExp.exec(str)[1])
          result = result.replace(regExp.exec(str)[0], value)
        } else {
          result = result.replace(`@${key}`, value)
        }
      })
    }
    return result
  }

  toggleEnrollmentFilter = (state) =>
    this.setState({
      filterEnrollments:
        state === undefined ? !this.state.filterEnrollments : state,
    })

  resetStore = async () => {
    const resetState = Object.entries(this.state).map(([key]) => [
      key,
      initialState()[key] || undefined,
    ])

    await this.setState(Object.fromEntries(resetState))
  }

  updateLastSeen = async (conversationID) => {
    await this.setState({ lastSeen: Date.now() })
    const success = await updateLastSeen(conversationID)
    await this.setState({ lastSeen: Date.now() })
    return success
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
