import ReactGA from 'react-ga'

let initialized = false

export const initGA = async () => {
  if (initialized) {
    return
  }

  initialized = true

  if (process.env.REACT_APP_ENV === 'staging') {
    await ReactGA.initialize('UA-113155193-2')
  } else {
    await ReactGA.initialize('UA-113155193-1')
  }
}

export const Event = (category, action, label, value) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
    nonInteraction: true,
  })
}

export const PageView = (page) => {
  ReactGA.pageview(page)
}
