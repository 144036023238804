import axios from 'axios'
import { API } from './config'
import { getField } from '../Helpers/Misc'

export const getLocalCurrency = async () => {
  const currency = window.localStorage.getItem('currency')
  if (currency) {
    return currency
  } else {
    const currency = await axios
      .get('https://ipapi.co/json/')
      .then((res) => getField(res, 'data.currency'))
      .catch(() => false)

    window.localStorage.setItem('currency', currency)
    return currency
  }
}

export const getExchangeRate = (local, foreign) =>
  API.get(`/currency?local=${local}&foreign=${foreign}`)
    .then((res) => 1 / res.data.data)
    .catch(() => false)

export const createTransaction = (
  billId,
  paymentGateway,
  additionalData = {},
) =>
  API.post(`/payment/start`, {
    billId,
    paymentGateway,
    ...additionalData,
    newKeys: true,
  })
    .then((res) => {
      return res.data.data.transactions.latest
    })
    .catch(() => false)

export const makePayment = (pgData, paymentGateway, billId) =>
  API.post(`/payment/end`, {
    pgData,
    paymentGateway,
    billId,
    newKeys: true,
  })
    .then(() => true)
    .catch(() => false)

export const paymentFailed = (
  amount,
  currency,
  transactionId,
  pgData,
  paymentGateway,
) => {
  pgData['status'] = 'failed'
  return API.post(`/end`, {
    amount,
    currency,
    transactionId,
    pgData,
    paymentGateway,
  }).catch(() => false)
}

export const submitCardDetails = (stripe, name) =>
  stripe
    .createToken({ name })
    .then((res) => res)
    .catch(() => {
      return false
    })
