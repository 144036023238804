import React, { useState } from 'react'
import UserContextMenuList from './UserContextMenuList'
import { withRouter } from 'react-router-dom'
import StudentProfileStore from '../../../App/Dashboard/Components/Student/Components/StudentProfile/Store/StudentProfileStore'

const UserContextMenu = ({ location: { pathname } }) => {
  const [showMenu, toggleMenu] = useState(false)

  const {
    profileData: { picture: profilePicture } = {},
  } = StudentProfileStore.useContainer()

  const blockedRoutes = ['/class', '/classroom']

  return (
    <div className='userContextMenu'>
      <div
        className='icon'
        onClick={() => {
          toggleMenu(true)
        }}
        style={
          profilePicture
            ? {
                background: `url(${profilePicture}) center center /cover no-repeat`,
                border: 'none',
                filter: 'none',
              }
            : {}
        }
      />
      <UserContextMenuList
        showMenu={showMenu}
        toggleMenu={toggleMenu}
        blockedRoute={blockedRoutes.find((route) => pathname.endsWith(route))}
      />
    </div>
  )
}

export default withRouter(UserContextMenu)
