import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import TopNav from '../Components/TopNav/TopNav'
import Landing from './Landing/Landing'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Route from '../Components/Router/Route'
import UserStore from './UserAuth/Store/UserStore'
import withStore from '../Components/Unstated/withStore'
import Loading from '../Components/Loading/Loading'
import { initGA } from '../Helpers/GoogleAnalytics'
import ReactHintFactory from 'react-hint'
import UpcomingClass from '../Modules/UpcomingClass/UpcomingClass'
import 'react-hint/css/index.css'
import asyncComponent from '../Components/AsyncComponent/asyncComponents'
import ErrorBoundary from './ErrorBoundary/ErrorBoundary'
import ResetPassword from './UserAuth/Components/ResetPassword'
import { withStoreProvider } from '../Helpers/withStoreProvider'
import TeacherInfoStore from './UserAuth/Store/TeacherInfoStore'
import pluralize from 'pluralize'
import RealtimeEventsStore from '../Modules/RealtimeEvents/Store/RealtimeEventsStore'
import initPixel from '../Helpers/Pixel'
import TrackNavigationStore from '../Modules/TrackNavigation/stores/TrackNavigationStore'
import StudentProfileStore from './Dashboard/Components/Student/Components/StudentProfile/Store/StudentProfileStore'
import AllBroadcastClassRecordings from './AllBroadcastClassRecordings'
import UserStoreNext from './UserAuth/Store/user-store-next'
import EmailUnsubscribe from '../Modules/EmailUnsubscribe'
import PixelTrackingStore from '../Modules/tracking/stores/pixel-tracking-store'
import PaymentCoupon from '../Modules/payment-coupon'
import PremiumCourse from './PremiumCourse'

pluralize.addIrregularRule('masterclass', 'masterclasses')
pluralize.addIrregularRule('Masterclass', 'Masterclasses')

if (process.env.NODE_ENV !== 'production') {
  const UNSTATED = require('unstated-debug')
  UNSTATED.logStateChanges = false
}

const AllBroadcastClasses = asyncComponent(() =>
  import('./AllBroadcastClasses'),
)
const BroadcastClassSeries = asyncComponent(() =>
  import('./BroadcastClassSeries'),
)
const Auth = asyncComponent(() => import('./UserAuth/Auth'))
const Embed = asyncComponent(() => import('../Embed'))
const About = asyncComponent(() => import('./About'))
const ContactUsAndSupport = asyncComponent(() =>
  import('./ContactUsAndSupport/ContactUsAndSupport'),
)
const DocumentsViewer = asyncComponent(() =>
  import('../Modules/Documents/Viewer/DocumentsViewer'),
)
const Classroom = asyncComponent(() => import('./Classroom/Classroom'))
const ValidateEmail = asyncComponent(() =>
  import('../Modules/ValidateEmail/ValidateEmail'),
)
const Sitemap = asyncComponent(() => import('./Sitemap'))
const BroadcastClass = asyncComponent(() => import('./BroadcastClass'))
const BroadcastClassV2 = asyncComponent(() => import('./broadcast-class-v2'))
const Course = asyncComponent(() => import('./Course/Course'))
const AllCourses = asyncComponent(() => import('./AllCourses'))
const Instruments = asyncComponent(() => import('./Categories'))
const SearchResults = asyncComponent(() =>
  import('./SearchResults/SearchResults'),
)
const Teacher = asyncComponent(() => import('./Teachers/TeachersPage/Teacher'))
const Dashboard = asyncComponent(() => import('./Dashboard'))
const MeetOurTeachers = asyncComponent(() =>
  import('./Teachers/MeetOurTeachers/MeetOurTeachers'),
)
const BecomeTeacher = asyncComponent(() => import('./BecomeTeacher'))
const Payments = asyncComponent(() => import('./Payments/Payments'))
const PrivacyPolicy = asyncComponent(() =>
  import('./PolicyAndTerms/PrivacyPolicy'),
)
const TermsAndConditions = asyncComponent(() =>
  import('./PolicyAndTerms/TermsAndConditions'),
)
const Pricing = asyncComponent(() => import('./Pricing/home'))
const PricingPayment = asyncComponent(() => import('./Pricing/payment'))
const Faq = asyncComponent(() => import('./Faq'))

const ReactHint = ReactHintFactory(React)

const App = ({ userStore: { state: userState, checkForLoggedInUser } }) => {
  const { id: userID } = userState
  const {
    updateUserId: updateRealtimeUserId,
  } = RealtimeEventsStore.useContainer()
  const { update } = UserStoreNext.useContainer()
  const {
    updateUserId: updateProfileUserId,
  } = StudentProfileStore.useContainer()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    ;(async () => {
      await checkForLoggedInUser()
      await initGA()
      initPixel()
      setLoaded(true)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkForLoggedInUser])

  useEffect(() => {
    update(userState)
  }, [update, userState])

  useEffect(() => {
    updateRealtimeUserId(userID)
    updateProfileUserId(userID)
  }, [updateProfileUserId, updateRealtimeUserId, userID])

  return !loaded ? (
    <Loading full />
  ) : (
    <>
      <BrowserRouter>
        <PaymentCoupon>
          <TrackNavigationStore.Provider>
            <ErrorBoundary>
              <PixelTrackingStore.Provider>
                <div className='App'>
                  <UpcomingClass />
                  <TopNav />
                  <Switch>
                    <Route exact path='/' render={() => <Landing />} />
                    <Route
                      exact
                      path='/unsubscribe/email/:code'
                      render={() => <EmailUnsubscribe />}
                    />
                    <Route path='/aboutus' render={() => <About />} />
                    <Route path='/auth' render={() => <Auth />} ifGuest />
                    <Route
                      path='/dashboard'
                      render={() => <Dashboard />}
                      isPrivate
                    />
                    <Route
                      path='/billing'
                      render={() => <Payments />}
                      isPrivate
                    />
                    <Route
                      path='/search/:query'
                      render={() => <SearchResults />}
                    />
                    <Route
                      path='/classroom'
                      render={() => <Classroom />}
                      isPrivate
                    />
                    <Route path='/courses' render={() => <AllCourses />} />
                    <Route path='/premium' render={() => <PremiumCourse />} />
                    <Route
                      path='/discover/courses/group'
                      render={() => <AllCourses />}
                    />
                    <Route
                      path='/masterclasses'
                      render={() => <AllBroadcastClasses />}
                    />
                    <Route
                      path='/demos'
                      render={() => <AllBroadcastClasses />}
                    />
                    <Route
                      path='/btms'
                      render={() => <AllBroadcastClasses />}
                    />
                    <Route
                      path='/concerts'
                      render={() => <AllBroadcastClasses />}
                    />
                    <Route
                      path='/qnas'
                      render={() => <AllBroadcastClasses />}
                    />
                    <Route
                      path='/live-classes'
                      render={() => <AllBroadcastClasses />}
                    />
                    <Route
                      path='/recorded-classes'
                      render={() => <AllBroadcastClassRecordings />}
                    />
                    <Route
                      path='/workshops'
                      render={() => <AllBroadcastClasses />}
                    />
                    <Route
                      path='/categories/:category?'
                      render={() => <Instruments />}
                    />
                    <Route
                      path='/becomeTeacher'
                      render={() => <BecomeTeacher />}
                      isPrivate
                    />
                    <Route path='/artists' render={() => <MeetOurTeachers />} />
                    <Route path='/sitemap' render={() => <Sitemap />} />
                    <Route path='/pricing' render={() => <Pricing />} exact />
                    <Route
                      path='/pricing/payment/:id'
                      render={() => <PricingPayment />}
                    />
                    <Route
                      path='/privacyPolicy'
                      render={() => <PrivacyPolicy />}
                    />
                    <Route path='/privacy' render={() => <PrivacyPolicy />} />
                    <Route
                      path='/termsOfUse'
                      render={() => <TermsAndConditions />}
                    />
                    <Route
                      path='/contactus'
                      render={() => <ContactUsAndSupport />}
                    />
                    <Route
                      path='/support'
                      render={() => <ContactUsAndSupport />}
                    />
                    <Route path='/faq' render={() => <Faq />} />
                    <Route path='/embed' render={() => <Embed />} />
                    <Route
                      path='/forgot-password/:token'
                      render={() => <ResetPassword />}
                    />
                    <Route
                      path='/verifyemail/:token'
                      render={() => <ValidateEmail />}
                    />
                    <Route
                      path='/:livDemyID/course/:courseURL'
                      render={() => <Course />}
                    />
                    <Route
                      path='/:livdemyId/:type/series/:url/:version?'
                      render={() => <BroadcastClassSeries />}
                    />
                    <Route
                      exact
                      path='/:livdemyId/:type/:url/:version'
                      render={() => <BroadcastClassV2 />}
                    />
                    <Route
                      path='/:livDemyID/:type/:broadcastClassURL/:version?'
                      render={() => <BroadcastClass />}
                    />
                    <Route
                      path='/:livDemyID/:courseURL'
                      render={() => <Course />}
                    />
                    <Route path='/:livDemyID' render={() => <Teacher />} />
                  </Switch>
                  <ToastContainer />
                  <DocumentsViewer />
                  <ReactHint autoPosition events />
                </div>
              </PixelTrackingStore.Provider>
            </ErrorBoundary>
          </TrackNavigationStore.Provider>
        </PaymentCoupon>
      </BrowserRouter>
    </>
  )
}

export default withStore([UserStore])(
  withStoreProvider([
    TeacherInfoStore,
    RealtimeEventsStore,
    StudentProfileStore,
    UserStoreNext,
  ])(App),
)
