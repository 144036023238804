import { API } from './config'
import { getField } from '../Helpers/Misc'

export const fbAuth = (data) =>
  API.post('/auth/signup/fb/step1', data)
    .then((response) => {
      const user = getField(response, 'data.data')
      if (user.message === 'You have not registered yet. Please register.') {
        return {
          userInfo: getField(response, 'data.data.dataFromFB'),
          create: true,
        }
      } else
        return (
          {
            ...user,
            picture: getField(user, 'profile.profilePhoto.location'),
          } || false
        )
    })
    .catch(() => false)

export const googleAuth = (data) =>
  API.post('/auth/signup/google/step1', data)
    .then((response) => {
      const user = getField(response, 'data.data')
      if (!user)
        return {
          userInfo: getField(response, 'data.messages'),
          create: true,
        }
      else
        return (
          {
            ...user,
            picture: getField(user, 'profile.profilePhoto.location'),
          } || false
        )
    })
    .catch(() => false)

export const passwordAuth = (email, password) =>
  API.post('/auth/login/regular/step1', { email, password })
    .then((response) => ({
      ...response.data.data,
      emailLogin: true,
      picture: getField(response, 'data.data.profile.profilePhoto.location'),
    }))
    .catch(() => false)

export const getLocationFromCoordinate = ({ lat, lng }) =>
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=administrative_area_level_2&key=AIzaSyCYbV1OkCSxhMpd-pb9QjO-KAhJaioc_hk`,
  )
    .then((res) => res.json())
    .then((data) =>
      getField(data, 'results[0].address_components[0].long_name'),
    )

export const register = ({
  name,
  email,
  phone,
  place,
  password,
  timezone,
  gender,
}) =>
  API.post('/auth/register', {
    name,
    email,
    phoneNumber: phone,
    place,
    password,
    timeZone: timezone,
    gender,
  })
    .then((res) => ({ ...res.data.data, emailLogin: true }))
    .catch(() => false)

export const registerForOtp = (email) =>
  API.post(`/auth/email-registration/step1`, { email })
    .then(() => true)
    .catch(() => false)

export const verifyOTP = (otp) =>
  API.post(`/auth/email-registration/step2`, { otp })
    .then((res) => res.data.data)
    .catch(() => false)

export const getLocationAndTz = () =>
  fetch('https://ipapi.co/json')
    .then((res) => res.json())
    .then(({ timezone, city, region, country_name }) => ({
      timezone,
      place: `${city}, ${region}, ${country_name}`,
    }))
    .catch(() => false)

export const requestPasswordReset = ({ email }) =>
  API.post(`/auth/forgot-password/step1`, { email })
    .then(() => true)
    .catch(() => false)

export const resetPassword = ({ password, token }) =>
  API.post(`/auth/forgot-password/step2`, { code: token, password })
    .then((res) => res.data.data)
    .catch(() => false)
