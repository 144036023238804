import styled from 'styled-components'
import {
  background,
  overlay,
  square,
  vFlex,
} from '../../../Styles/style-helpers'
import { Text } from '../../../Styles/typography'
import { darkAccent } from '../../../Styles/colors'

export const AvatarEl = styled.div`
  ${({ size }) => square((size ? size : 7.5) + 'em')};
  position: relative;
  background: ${({ image }) => (image ? 'none' : darkAccent)};
  color: white;
  border-radius: 0.5em;
`

export const AvatarImageEl = styled.div`
  ${({ image }) => background(image, 'cover')};
  ${overlay};
  border-radius: 0.5em;
`

export const AvatarNameEl = styled(Text)`
  font-size: ${({ size }) => (size ? size / 2.5 : 3.052)}em;
  ${overlay};
  ${vFlex};
  text-transform: uppercase;
  letter-spacing: 0.0625em;
`

export const SubscriberBadgeEl = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background: #c7402d;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  transform: translate(40%, -50%);
  font-size: 0.75em;
`
