import React, { useState } from 'react'

const SimpleInput = ({
  placeholder,
  field = {},
  form = {},
  type,
  value,
  editstate,
  inputRef,
  ...props
}) => {
  const [loading, setLoading] = useState(false)

  const onChange = (e) => {
    form.setFieldValue && form.setFieldValue(field.name, e.target.value)
    form.setFieldTouched && form.setFieldTouched(field.name, true)
    return props.onChange
      ? props.onChange(e)
      : field.onChange
      ? field.onChange(e)
      : null
  }

  let className =
    loading && editstate
      ? 'loading'
      : !loading && editstate && form.touched[field.name]
      ? 'saved'
      : props.className

  return (
    <div className={'inputWrapper' + (props.disabled ? ' disabled' : '')}>
      <input
        ref={inputRef}
        type={type || 'text'}
        placeholder=' '
        minLength={props.minLength}
        maxLength={props.limit}
        value={value}
        {...props}
        {...field}
        onChange={async (e) => {
          setLoading(true)
          await onChange(e)
          setLoading(false)
        }}
        className={className}
      />
      <p className={'placeholder'}>{placeholder}</p>
      <p className={'error'}>
        {form.errors && form.touched[field.name] && form.errors[field.name]}
      </p>
    </div>
  )
}

export default SimpleInput
