import { generateOptions, parseDocuments } from './Misc'

export const parseBecomeTeacherStatusData = (data) => {
  if (data) {
    const {
      id = '',
      status = '',
      documents = { value: {} },
      coverImagesRaw = {},
      coverImage = { value: {} },
      livdemyId = { value: '' },
      teacherDescription = { value: '' },
      teachingAreas = { value: [] },
      teachingLanguages = { value: [] },
      note = { adminNote: '' },
      experience = { value: '' },
      artistType = '',
      sessionTypes = [],
      externalProfileVideo = '',
      whatsappNumber,
    } = data

    return {
      status,
      id,
      docs: Object.values(documents.value).map(
        ({ fileName, fileType, fileSize, location, id }) => ({
          name: fileName,
          type: fileType,
          size: fileSize,
          url: location,
          id,
        }),
      ),
      coverImagesRaw: parseDocuments(coverImagesRaw).map((doc) => ({
        ...doc,
        id: doc.id + '/raw',
      })),
      externalProfileVideo,
      sessionTypes: sessionTypes,
      artistType: artistType,
      cover: coverImage.value,
      livDemyID: livdemyId.value,
      description: teacherDescription.value,
      instruments: generateOptions(teachingAreas.value),
      languages: generateOptions(teachingLanguages.value),
      rejectMessage: note.adminNote,
      experience: experience.value.toString(),
      whatsappNumber: whatsappNumber,
    }
  }
  return {}
}
