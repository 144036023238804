import { darkAccent } from '../../../Styles/colors'

const config = [
  {
    type: 'master-class',
    url: 'masterclass',
    searchKey: 'MASTER_CLASS',
    shortName: 'masterclass',
    name: 'masterclass',
    fullName: 'live masterclass',
  },
  {
    type: 'workshop',
    url: 'workshop',
    searchKey: 'WORKSHOP',
    shortName: 'workshop',
    name: 'workshop',
    fullName: 'live workshop',
  },
  {
    type: 'demo',
    url: 'demo',
    searchKey: 'DEMO',
    shortName: 'demo',
    name: 'demo',
    fullName: 'live demo',
  },
  {
    type: 'btm',
    url: 'btm',
    searchKey: 'BTM',
    shortName: 'behind the music',
    name: 'behind the music session',
    fullName: 'behind the music',
  },
  {
    type: 'qna',
    url: 'qna',
    searchKey: 'QNA',
    shortName: 'QnA',
    name: 'QnA Session',
    fullName: 'Question & Answer',
  },
  {
    type: 'concert',
    url: 'concert',
    searchKey: 'CONCERT',
    shortName: 'concert',
    name: 'Concert',
    fullName: 'Live Concert',
  },
  {
    type: 'gig',
    url: 'gig',
    searchKey: 'GIG',
    shortName: 'gig',
    name: 'Gig',
    fullName: 'Live Gig',
  },
]

export const bcTypes = config

export const parseTypeFromUrl = (url) => config.find((x) => x.url === url)?.type

export const parseFromSearchKey = (key) =>
  config.find((x) => x.searchKey === key)

export const getUrlFromType = (type) => {
  return config.find((x) => x.type === type)?.url
}

export const getShortNameFromType = (type) =>
  config.find((x) => x.type === type)?.shortName

export const getNameFromType = (type) =>
  config.find((x) => x.type === type)?.name

export const getFullNameFromType = (type) =>
  config.find((x) => x.type === type)?.fullName

const style2 = {
  background: '#fff',
  bannerText: '#000',
  bannerBackground: '#fff',
  bannerShadow:
    '0 0 5px rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.5), 0 0 10px rgba(255, 255, 255, 0.5)',
  bannerBorderRadius: '0.5em',
  classLevelBackground: '#ededed',
  timeColor: darkAccent,
  actionButtonColor: '#000',
  sectionHeading: '#000',
  faqQuestion: 'rgba(0, 0, 0, 0.8)',
  faqAnswer: '#000',
  faqBorder: 'rgba(0, 0, 0, 0.2)',
  askQuesText: '#000',
  askQuestion: 'rgba(0, 0, 0, 0.8)',
  askAnswerText: darkAccent,
  style: 2,
}

export const getBroadcastClassTheme = () => style2
