import { groupBy } from '../../../Helpers/Misc'
import { always, join, juxt, map, pipe, propOr, uniq } from 'ramda'
import date from 'date-and-time'

const uniqid = require('uniqid')

export const getNextClass = (enrollments = []) => {
  let nextClass = null
  const now = new Date().getTime()

  enrollments.forEach(({ classes }) => {
    if (classes) {
      const tempNextClass = Object.keys(classes)
        .map((key) => ({ ...classes[key], key }))
        .sort((a, b) => a.startTime - b.startTime)
        .find((_class) => _class.endTime > now)

      if (!nextClass || tempNextClass?.startTime < nextClass?.startTime) {
        nextClass = tempNextClass
      }
    }
  })

  return nextClass
}

export const getNextClassBySlots = (slots) =>
  getNextClass([
    {
      classes: (slots || []).reduce(
        (classes, current) => ({
          ...classes,
          [uniqid()]: current,
        }),
        {},
      ),
    },
  ])

export const getWeekNumber = (date) => {
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  )
  const dayNum = d.getUTCDay() || 7
  d.setUTCDate(d.getUTCDate() + 4 - dayNum)
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  return (
    date.getFullYear() * 54 + Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
  )
}

export const splitClassesInWeeks = (classes) =>
  groupBy(classesToArray(classes), 'week')

export const classesToArray = (classes) => {
  if (classes) {
    const sorted = Object.keys(classes).sort(
      (a, b) => classes[a].startTime - classes[b].startTime,
    )

    const mapped = sorted.map((key, i) => ({
      ...classes[key],
      key,
      week: getWeekNumber(new Date(classes[key].startTime)),
    }))

    const notCancelled = mapped
      .filter((cls) => cls.status !== 'CANCELLED')
      .map((cls, i, array) => ({
        ...cls,
        index: i,
        totalClasses: array.length,
      }))
    const cancelled = mapped
      .filter((cls) => cls.status === 'CANCELLED')
      .map((cls) => ({ ...cls, totalClasses: notCancelled.length }))

    return [...cancelled, ...notCancelled].sort(
      (a, b) => a.startTime - b.startTime,
    )
  } else return []
}

export const allowJoinClass = (
  time,
  duration = 60 * 60 * 1000,
  earlyEntryThreshold = 15 * 60 * 1000,
) => {
  const startTime = parseInt(time)

  const start = startTime - earlyEntryThreshold
  const end = startTime + duration
  const now = new Date().getTime()

  // if (duration !== 60 * 60 * 1000) {
  //   console.log({
  //     start: new Date(start),
  //     end: new Date(end),
  //     now: new Date(now)
  //   });
  // }

  return now > start && now < end
}

export const getLastClassRecording = (enrollments = []) => {
  let lastClassWithRecording = null

  enrollments.forEach(({ classes }) => {
    if (classes) {
      const classWithRecording = Object.values(classes)
        .sort((a, b) => b.startTime - a.startTime)
        .find((cls) => {
          return cls.compositionVideos
        })

      if (!classWithRecording) {
        return null
      }

      if (
        !lastClassWithRecording ||
        Number(classWithRecording.endTime) >
          Number(lastClassWithRecording.endTime)
      ) {
        lastClassWithRecording = classWithRecording
      }
    }
  })

  return lastClassWithRecording && lastClassWithRecording.compositionVideos
}

export const getBatchSchedule = pipe(
  map(
    pipe(
      juxt([
        pipe(propOr(0, 'startTime'), (dt) => date.format(new Date(dt), 'ddd')),
        always(': '),
        pipe(propOr(0, 'startTime'), (t) => date.format(new Date(t), 'h:mm A')),
        always(' - '),
        pipe(propOr(0, 'endTime'), (t) => date.format(new Date(t), 'h:mm A')),
      ]),
      join(''),
    ),
  ),
  uniq,
)
