export const routeData = [
  {
    route: '/',
    title: 'Your Live Music Academy | Livdemy',
    description:
      'Livdemy connects you to a music teacher online and learn music from them. Learn vocals, guitar, piano or any instrument from the comfort of your home.',
    image: `${process.env.REACT_APP_SITE_URL}/logo.png`,
  },
  {
    route: '/dashboard/student/overview',
    title: 'Overview | LivDemy',
  },
  {
    route: '/dashboard/student/profile',
    title: 'Student Profile | LivDemy',
  },
  {
    route: '/dashboard/student/courses',
    title: 'Courses | LivDemy',
  },
  {
    route: '/dashboard/student/billing',
    title: 'Billing | LivDemy',
  },
  {
    route: '/dashboard/teacher/overview',
    title: 'Overview | LivDemy',
  },
  {
    route: '/dashboard/teacher/profile',
    title: 'Teacher Profile | LivDemy',
  },
  {
    route: '/dashboard/teacher/courses',
    title: 'Courses | LivDemy',
  },
  {
    route: '/dashboard/teacher/billing',
    title: 'Billing | LivDemy',
  },
  {
    route: '/dashboard/messages',
    title: 'Participants | LivDemy',
  },
  {
    route: '/auth',
    title: 'Auth | LivDemy',
  },
  {
    route: '/teachers',
    title: 'Meet our Artists | LivDemy',
  },
  {
    route: '/contact',
    title: 'Contact | LivDemy',
  },
  {
    route: '/support',
    title: 'Support | LivDemy',
  },
  {
    route: '/aboutUs',
    title: 'About Us | LivDemy',
  },
  {
    route: '/privacyPolicy',
    title: 'Privacy Policy | LivDemy',
  },
  {
    route: '/termsOfUse',
    title: 'Terms & Conditions | LivDemy',
  },
  {
    route: '/livdemyID/demo',
    title: 'Request a Demo | LivDemy',
  },
  {
    route: '/masterclasses',
    title: 'Live Masterclasses | LivDemy',
  },
]

export const defaultKeywords =
  'Online music, Learn Guitar, Learn Piano, Learn Singing, Guitar Lessons, live music classes, online music teacher, guitar tutorial, violin, flute, drums, vocals'
