import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { unsubscribeEmail } from '../../API/EmailUnsubscribe'
import Button from '../../Components/Buttons/Button'
import Logo from '../../Components/Logo/Logo'
import Links from '../../Components/TopNav/Components/Links'
import '../../Components/TopNav/TopNav.sass'
import { H2, Text } from '../../Styles/typography'
import SearchBox from '../Search/SearchBox/SearchBox'
import { EmailUnsubscribeEl } from './Elements'

function EmailUnsubscribe({
  match: {
    params: { code },
  },
  history,
}) {
  const [loading, setLoading] = useState(false)
  const [unsubscribed, setUnsubscribed] = useState(false)

  useEffect(() => {
    if (!code) history.push('/')
  }, [code, history])

  return (
    <EmailUnsubscribeEl>
      <div className='topNav'>
        <Logo />
        <Links loggedIn={false} />
        <div className='flexSpread' />
        <SearchBox />
      </div>
      <div className='content'>
        <H2>Unsubscribe from Emails</H2>
        {unsubscribed ? (
          <>
            <Text>You have been unsubscribed successfully.</Text>
            <div className='btns'>
              <Button
                name='Back to Home Page'
                onClick={() => history.replace('/')}
                type='primary big'
              />
            </div>
          </>
        ) : (
          <>
            <Text>
              On clickling unsubscribe, you will stop receiving emails from
              Livdemy.
            </Text>
            <div className='btns'>
              <Button
                name='Cancel'
                onClick={() => history.replace('/')}
                type='primary big'
              />
              <Button
                name={'Unsubscribe'}
                onClick={async () => {
                  setLoading(true)
                  const success = await unsubscribeEmail(code)
                  setLoading(false)
                  if (success) setUnsubscribed(true)
                  else history.replace('/')
                }}
                type='primary big'
                disabled={loading}
                loading={loading}
              />
            </div>
          </>
        )}
      </div>
    </EmailUnsubscribeEl>
  )
}

export default withRouter(EmailUnsubscribe)
