import { API, slsCoreBaseUrl } from '../../../API/config'
import { getField } from '../../../Helpers/Misc'

export const getParticipantInfo = async (chatIds) =>
  API.post('/chats/userInfos', {
    chatIds,
  })
    .then((res) => res.data.data)
    .catch(() => false)

export const customMessageActions = ({ method, url, data }) =>
  API[method.toLowerCase()]({
    url,
    data,
  })
    .then((res) => getField(res, 'data.data'))
    .catch(() => false)

export const sendChatMessage = (conversationID, data) =>
  API.post(`${slsCoreBaseUrl}/chat/id/${conversationID}/message`, data)
    .then(() => true)
    .catch(() => false)

export const updateLastSeen = (chatID) =>
  API.post(`chats/${chatID}/lastSeen`)
    .then(() => true)
    .catch(() => false)
