import React from 'react'
import PropTypes from 'prop-types'
import date from 'date-and-time'

const NotificationElement = ({
  picture,
  message,
  read,
  createdAt,
  onClick,
}) => (
  <div className='notificationElement' onClick={onClick}>
    <div
      className='picture'
      style={{ background: `url(${picture}) center center /cover no-repeat` }}
    />
    <p className='message'>{message}</p>
    {!read && <p className='new'>New</p>}
    <p className='time'>
      {date.format(new Date(createdAt), 'HH:mm A, DD MMM')}
    </p>
  </div>
)

NotificationElement.propTypes = {
  picture: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  createdAt: PropTypes.number.isRequired,
}

export default NotificationElement
