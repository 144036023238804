import React, { useEffect } from 'react'
import ComponentLoader from '../../Components/Loading/ComponentLoader'
import withStore from '../../Components/Unstated/withStore'
import AllBroadcastClassRecordingsStore from './Stores/AllBroadcastClassRecordingsStore'
import Footer from '../../Components/Footer/Footer'
import FacebookChat from '../../Components/FacebookChat'
import './AllBroadcastClasses.sass'
import MasterclassList from '../../Components/Lists/MasterclassList'
import Helmet from '../../Components/Helmet/Helmet'

const AllBroadcastClassRecordings = ({
  allBroadcastClassesStore: {
    state: { loading, loadFailed, recordings },
    init,
  },
}) => {
  useEffect(() => {
    init()
  }, [init])
  console.log(recordings)
  return (
    <ComponentLoader loading={loading} loadFailed={loadFailed}>
      <div className='allBroadcastClasses'>
        <Helmet route={`/masterclasses`} />
        <h1 className='sectionHeader'>Live Class Recordings</h1>
        <p className='sectionIntro'>
          LivDemy offers a wide range of{' '}
          <strong>Live Masterclasses and Workshops</strong> by industry experts
          in various aspects of music. From performing arts, to composition, to
          production, the entire range. We bring in renown artists, composers,
          producers, engineers to talk about their craft.
        </p>

        {Boolean(recordings.length) && (
          <MasterclassList data={recordings} name={``} />
        )}
        <Footer />
      </div>
      <FacebookChat />
    </ComponentLoader>
  )
}

export default withStore([AllBroadcastClassRecordingsStore])(
  AllBroadcastClassRecordings,
)
