import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Helmet as ReactHelmet } from 'react-helmet'
import { defaultKeywords, routeData } from '../../Assets/Meta'

const Helmet = ({ route, data }) => {
  const { title, description, keywords, image, schema } =
    data || routeData.find((page) => page.route === route) || {}

  return (
    <ReactHelmet>
      <title>{title}</title>
      {description && <meta name='description' content={description} />}
      {image && <meta name='og:image' content={image} />}
      {image && <meta name='og:image:secure_url' content={image} />}
      {image && <meta name='og:image:secure' content={image} />}
      {title && <meta name='og:title' content={title} />}
      {description && <meta name='og:description' content={description} />}
      {schema && <script type='application/ld+json'>{schema}</script>}
      <meta name='keywords' content={keywords || defaultKeywords} />
    </ReactHelmet>
  )
}

Helmet.propTypes = {
  route: PropTypes.string,
}

Helmet.defaultProps = {
  data: null,
}

export default memo(Helmet)
