import { API } from './config'
import { getField } from '../Helpers/Misc'
import { parseCourseData } from '../Helpers/API'
import { getLocalCurrency } from './Payment'
import { MASTERCLASS } from '../Constants/broadcasts'

export const initCourse = (courseUrl, livdemyId) =>
  API.get(`/course/${livdemyId}/${courseUrl}`)
    .then(({ data }) => parseCourseData(data))
    .catch(() => false)

export const getCalendarData = (livDemyID) =>
  API.get(`/course-registrations/calendar?livdemyID=${livDemyID}`)
    .then((res) => getField(res, 'data.data'))
    .catch(() => false)

export const addToWishlist = (courseID) =>
  API.post(`/course/${courseID}/wishlist`)
    .then(() => true)
    .catch(() => false)

export const removeFromWishlist = (courseID) =>
  API.delete(`/course/${courseID}/wishlist`)
    .then(() => true)
    .catch(() => false)

export const enrollRequest = async (courseId, data) =>
  API.post(`/course/${courseId}/register`, data, {
    headers: {
      'local-currency': await getLocalCurrency(),
    },
  })
    .then((res) => res.data.data)
    .catch(() => false)

export const enrollRecordedRequest = (courseId, registrationType) =>
  API.post(`/course/${courseId}/registrationType/${registrationType}/register`)
    .then((res) => res.data.data)
    .catch(() => false)

export const unlockLesson = (enrollmentID, chapterIndex, lessonIndex) =>
  API.post(
    `/course-registration/${enrollmentID}/unlock-lesson/${chapterIndex}/${lessonIndex}`,
  )
    .then(() => true)
    .catch(() => false)

export const submitCourseFeedback = async ({ teacher, course, ...data }) =>
  (
    await Promise.all([
      API.post(`/teacher/reviews`, { ...teacher, ...data })
        .then(() => true)
        .catch(() => false),
      API.post(`/course/reviews`, { ...course, ...data })
        .then(() => true)
        .catch(() => false),
    ])
  ).every((value) => value === true)

export const submitClassFeedback = (data) =>
  API.post(`/feedback/class`, data)
    .then(() => true)
    .catch(() => false)

export const cancelRegistration = (id) =>
  API.delete(`/course-registrations/${id}`)
    .then(() => true)
    .catch(() => false)

export const scheduleMakeUpClass = (data) =>
  API.put(`/course/make-up-class`, data)
    .then(() => true)

    .catch(() => false)

export const rescheduleAllClasses = (crId, slots) =>
  API.post(`/course/${crId}/reschedule`, {
    rescheduleMessage: 'Your classes have been rescheduled.',
    dateSlots: slots,
  })
    .then(() => true)
    .catch(() => false)

export const submitTeacherReview = (data) =>
  API.post(`/teacher/reviews`, data)
    .then(() => true)
    .catch(() => false)

export const submitTeacherReview2 = (data) =>
  API.post(`/review/v2`, data)
    .then(() => true)
    .catch(() => false)

export const submitCourseReview = (data) =>
  API.post(`/course/reviews`, data)
    .then(() => true)
    .catch(() => false)

export const submitMasterclassReview = (data, type = MASTERCLASS) =>
  API.post(`/broadcast-class/${type}/reviews`, data)
    .then(() => true)
    .catch(() => false)

export const submitBcReview = (data) =>
  API.post(`/review/v2`, data)
    .then(() => true)
    .catch(() => false)

export const getStudentNotes = (registrationId) =>
  API.get(`/courses/student/${registrationId}`).then(({ data }) =>
    data.data.course.classes.map((cls) => cls.studentClassNote || ''),
  )

export const updateNotes = (url, data) =>
  API.put(url, data)
    .then(() => true)
    .catch(() => false)

export const processCourseRegistration = (id, data) =>
  API.post(`/course-registrations/${id}/process`, data)
    .then(() => true)
    .catch(() => false)

export const classReschedule = (data) =>
  API.put(`/course/make-up-class`, data)
    .then(() => true)
    .catch(() => false)

export const batchNotifyEmail = (batchId, data) =>
  API.post(`/batch/${batchId}/communication`, data)
    .then(() => true)
    .catch(() => false)

export const getRecording = (batchId, classId) =>
  API.get(`/course/batch/${batchId}/class/${classId}/recording`).then(
    ({ data }) => data.data,
  )

export const uploadAssignmentSubmission = (
  courseID,
  chapterID,
  lessonID,
  registrationID,
  assignmentMediaUrl,
  progress,
) =>
  API.post(
    `/courses/${courseID}/chapters/${chapterID}/lessons/${lessonID}/registrations/${registrationID}/assignment-submission`,
    { assignmentMediaUrl },
  )
    .then((res) => getField(res, 'data.data'))
    .catch(() => false)

export const submitAssignmentReview = ({
  courseId,
  chapterId,
  lessonId,
  submissionId,
  reviewMessage,
}) =>
  API.post(`/course/${courseId}/review-assignment-submission`, {
    chapterId,
    lessonId,
    submissionId,
    reviewMessage,
  })
    .then((res) => getField(res, 'data.data'))
    .catch(() => false)
