import { Container } from 'unstated'
import { API, initApis } from '../../../API/config'
import { UserStoreSchema } from './UserStoreSchema'
import {
  fbAuth,
  getLocationAndTz,
  googleAuth,
  passwordAuth,
  register,
  registerForOtp,
  requestPasswordReset,
  resetPassword,
  verifyOTP,
} from '../../../API/UserAuth'
import { getField, resetState } from '../../../Helpers/Misc'
import * as Sentry from '@sentry/browser'
import { Event } from '../../../Helpers/GoogleAnalytics'

export default class UserStore extends Container {
  name = 'UserStore'

  subscribed = false

  state = UserStoreSchema()

  linkedStores = {}

  authUpdates = null

  checkForLoggedInUser = async () => {
    const savedUserInfo = window.localStorage.getItem('user')
      ? JSON.parse(window.localStorage.getItem('user'))
      : null

    if (savedUserInfo) {
      await this.userLoggedIn(savedUserInfo)
      return true
    }
    initApis(
      {},
      () => {},
      () => {},
    )

    setInterval(async () => {
      const savedUserInfo = JSON.parse(window.localStorage.getItem('user'))
      if (savedUserInfo && this.state.email !== savedUserInfo.email) {
        await this.userLoggedIn(savedUserInfo)
      } else if (!savedUserInfo && this.state.loggedIn) {
        await this.logout()
      }
    }, 1000)

    return false
  }

  userLoggedIn = async (userData) => {
    const data = {
      ...userData,
      isProfileComplete: userData.is_profile_complete,
      loggedIn: !!userData.authToken,
      signUpRequest: !userData.authToken && userData.email,
    }

    initApis(userData, this.userLoggedIn, this.logout)

    if (data.loggedIn) {
      window.localStorage.setItem('user', JSON.stringify(data))

      API.defaults.headers.common['authorization'] = 'bearer '.concat(
        data.authToken,
      )

      Sentry.configureScope(function (scope) {
        scope.setUser({
          email: userData.email,
          id: userData.id,
          username: userData.profile.name,
        })
      })

      const { history, redirectTo } = this.state

      if (redirectTo && getField(history, 'push')) {
        history.push(redirectTo)
      }

      await this.setState({ redirectTo: null })
    } else {
      API.defaults.headers.common['authorization'] = ''

      Sentry.configureScope(function (scope) {
        scope.setUser({
          email: '',
          id: '',
          username: '',
        })
      })
    }

    await this.setState(data)
  }

  googleAuth = async (response) => {
    const name = getField(response, 'profileObj.name')
    const token = getField(response, 'tokenId')

    const { timezone: timeZone, place } = await getLocationAndTz()

    const user = await googleAuth({ token, place, timeZone })

    if (!user.dataFromGoogle) {
      await this.userLoggedIn({ ...user, name })
    } else {
      return user
    }
  }

  facebookAuth = async ({ accessToken: token }) => {
    if (!token) {
      return false
    }

    const { timezone: timeZone, place } = await getLocationAndTz()

    const user = await fbAuth({ token, timeZone, place })
    if (!user.create) {
      await this.userLoggedIn(user)
    } else {
      return user.userInfo
    }
  }

  passwordAuth = async ({ email, password }) => {
    const user = await passwordAuth(email.trim(), password)

    if (user) {
      Event('conversion', 'authenticate', 'login')
      await this.userLoggedIn(user)
    }
  }

  logout = async (broadcast = true, reload = true) => {
    API.defaults.headers.common['authorization'] = ''

    this.subscribed = false

    window.localStorage.removeItem('user')
    await this.setState(resetState(this.state, UserStoreSchema()))

    await this.linkedStores.DocumentsViewerStore.closeDocument()

    if (reload) {
      window.location.reload()
    }

    if (broadcast) {
      this.authUpdates && this.authUpdates.postMessage(false)
    }
  }

  register = async (data) => {
    const { timezone: timeZone, place } = await getLocationAndTz()

    const userData = await register({
      ...data,
      email: data.email.trim(),
      place,
      timeZone,
    })

    if (userData) {
      Event('conversion', 'authenticate', 'signup')
      await this.userLoggedIn(userData)
      return userData
    }
    return false
  }

  registerOtp = async ({ email }) => {
    await this.setState({ email: email.trim() })
    return await registerForOtp(email.trim())
  }

  verifyOtp = async ({ otp }) => {
    const userData = await verifyOTP(otp)

    if (userData) {
      await this.userLoggedIn(userData)
      return userData
    }
    return false
  }

  updateUserProfilePicture = async (url) => {
    await this.setState({
      profile: {
        ...this.state.profile,
        profilePhoto: {
          location: url,
        },
      },
    })

    await this.userLoggedIn(this.state)
  }

  forgotPassword = async ({ email }) => {
    return await requestPasswordReset({ email: email.trim() })
  }

  resetPassword = async (password, token) => {
    return await resetPassword({ token, password })
  }

  setRedirectURL = (redirectTo, history) =>
    this.setState({ redirectTo, history })

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
