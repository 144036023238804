import React from 'react'

const LoadFailed = () => {
  return (
    <div className='loadFailed'>
      <p>Load Failed</p>
    </div>
  )
}

export default LoadFailed
