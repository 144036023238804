import React from 'react'
import { Link } from 'react-router-dom'

const links = [
  {
    name: 'About Us',
    route: '/aboutus',
  },
  {
    name: 'Pricing',
    route: '/pricing',
  },
  {
    name: 'Careers',
    route: 'https://angel.co/company/livdemy/',
  },
  {
    name: 'Privacy Policy',
    route: '/privacyPolicy',
  },
  {
    name: 'Terms of Use',
    route: '/termsOfUse',
  },
  {
    name: 'Contact Us',
    route: '/contactus',
  },
]

const Company = () => (
  <div className='company'>
    <h1>Company</h1>
    <div className='links'>
      {links.map(({ name, route }, i) =>
        route.startsWith('https://') ? (
          <a
            href={route}
            key={'ContactLinks' + i}
            target='_blank'
            rel='noreferrer noopener'
          >
            {name}
          </a>
        ) : (
          <Link to={route} key={'ContactLinks' + i}>
            {name}
          </Link>
        ),
      )}
    </div>
  </div>
)

export default Company
