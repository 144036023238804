import { Container } from 'unstated'
import { getLivDemyID, getMyCalendar } from '../../../../../API/Dashboard'
import {
  createUnitCalendarSlot,
  parseMarkedSlots,
  startOfTheWeek,
} from '../../../../../Modules/Calendar/Helpers/Calendar'
import { updateCalendarData } from '../../../../../API/BecomeTeacher'

const initialState = () => ({
  loading: true,
  loadFailed: false,
  studentClassesCalendar: [],
  studentPendingCalendar: [],
  teacherClassesCalendar: [],
  teacherAvailabilityCalendar: [],
  tempAvailabilityData: [],
  livDemyID: '',
})

export default class MyCalendarStore extends Container {
  name = 'MyCalendarStore'

  state = initialState()

  linkedStores = {}

  init = async () => {
    await this.setState({ ...initialState(), loading: true })

    const calendarData = await getMyCalendar()
    if (calendarData) {
      const {
        studentClasses,
        teacherClasses,
        teacherMarkingSlots,
      } = calendarData

      const studentClassesCalendar = [],
        studentPendingCalendar = []

      studentClasses.forEach((studentClass) =>
        (studentClass.status === 'APPROVED'
          ? studentClassesCalendar
          : studentPendingCalendar
        ).push(
          createUnitCalendarSlot(
            new Date(studentClass.startTime).getTime(),
            'approvedClasses',
            { title: studentClass.title },
          ),
        ),
      )

      await this.setState({
        studentClassesCalendar,
        studentPendingCalendar,
      })

      if (teacherClasses.length || teacherMarkingSlots.length) {
        const livDemyID = await getLivDemyID()

        await this.setState({
          livDemyID,
          teacherClassesCalendar: teacherClasses.map(({ startTime, title }) =>
            createUnitCalendarSlot(
              new Date(startTime).getTime(),
              'approvedClasses',
              { info: { title } },
            ),
          ),
          teacherAvailabilityCalendar: parseMarkedSlots(teacherMarkingSlots),
        })
      }

      await this.setState({ loading: false })
    } else {
      await this.setState({
        loading: false,
        loadFailed: true,
      })
    }
  }

  updateAvailabilityData = async (data) => {
    const monday = startOfTheWeek(new Date())
    monday.setHours(0, 0, 0, 0)

    const ranges = data
      .reduce((merged, current) => [...merged, ...current], [])
      .map((range) => {
        const day = new Date(monday.getTime()).setDate(
          monday.getDate() + range.startDay - 1,
        )
        return {
          startTime: new Date(day).setHours(0, range.start * 30),
          endTime: new Date(day).setHours(0, range.end * 30),
        }
      })

    await this.setState({ tempAvailabilityData: ranges })
  }

  saveAvailabilityData = async () => {
    const { tempAvailabilityData } = this.state

    if (!tempAvailabilityData.length) {
      return
    }

    const success = await updateCalendarData(tempAvailabilityData)

    if (success) {
      await this.init('teacher')
      return success
    }
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
