import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { withErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/browser'

function FacebookChat() {
  return (
    <div>
      <MessengerCustomerChat
        pageId={process.env.REACT_APP_FB_PAGEID}
        appId={process.env.REACT_APP_FB_APPID}
        shouldShowDialog={false}
        greetingDialogDisplay={'show'}
        greetingDialogDelay={10}
        themeColor={'#C7402D'}
        loggedInGreeting={
          'Hi! Thank you for reaching out. Are you looking for any information regarding a class or an artist at LivDemy? Tell us about it!'
        }
        htmlRef={window.location.pathname}
        loggedOutGreeting={
          'Hi! Thank you for reaching out. Are you looking for any information regarding a class or an artist at LivDemy? Tell us about it!'
        }
      />
    </div>
  )
}

export default withErrorBoundary(FacebookChat, {
  FallbackComponent: null,
  onError(error) {
    Sentry.captureException(error)
  },
})
