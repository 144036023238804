import React from 'react'
import './Input.sass'
import Radio from './Components/Radio'
import TextArea from './Components/Textarea'
import PlacesInput from './Components/PlacesInput'
import Dropdown from './Components/Dropdown'
import RTFEditor from './Components/RTFEditor'
import Password from './Components/Password'
import Toggle from './Components/Toggle'
import SimpleInput from './Components/SimpleInput'
import Rating from './Components/Rating'
import DatePicker from './Components/DatePicker'

const Input = (props) => {
  const { type } = props

  return type === 'radio' ? (
    <Radio {...props} />
  ) : type === 'textarea' ? (
    <TextArea {...props} />
  ) : type === 'rating' ? (
    <Rating {...props} />
  ) : type === 'select' ? (
    <Dropdown {...props} />
  ) : type === 'editor' ? (
    <RTFEditor {...props} />
  ) : type === 'place' ? (
    <PlacesInput
      {...props}
      field={props.field}
      form={props.form}
      placeholder={props.placeholder}
    />
  ) : type === 'password' ? (
    <Password {...props} />
  ) : type === 'datepicker' ? (
    <DatePicker {...props} />
  ) : type === 'switch' ? (
    <Toggle {...props} />
  ) : (
    <SimpleInput {...props} />
  )
}

export default Input
