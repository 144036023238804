import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './Logo.sass'
import LogoLight from '../../Assets/Icons/Logo/logo_white_beta.svg'
import LogoDark from '../../Assets/Icons/Logo/logo_black.svg'

const Logo = ({ mode = 'light' }) => (
  <Link to={'/'} className='fullLogo'>
    <img src={mode === 'dark' ? LogoDark : LogoLight} alt='LivDemy Logo' />
  </Link>
)

export default withRouter(Logo)
