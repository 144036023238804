import { Container } from 'unstated'
import { getStudentNotes, updateNotes } from '../../../API/Course'

const initialState = () => ({
  loading: true,
  loadFailed: false,
  notes: [],
})

export default class NotesStore extends Container {
  name = 'NotesStore'

  state = initialState()

  linkedStores = {}

  init = async (courseId, registrationId, userType) => {
    console.log('initializing NotesStore')

    if (userType === 'teacher') {
      const notes = this.linkedStores.ClassroomStore.state.upcomingClass.course.chapters
        .map(({ lessons, id: chapterId, title: chapterTitle }) =>
          lessons.map(
            ({ lessonNote: note, id: lessonId, title: lessonTitle }) => ({
              note,
              title: `${chapterTitle} - ${lessonTitle}`,
              updateNote: (updated) =>
                updateNotes(
                  `/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}/notes`,
                  { lessonNote: updated },
                ),
            }),
          ),
        )
        .reduce((notes, chapterNotes) => [...notes, ...chapterNotes], [])

      await this.setState({ notes, loading: false })
    } else {
      const notes = ((await getStudentNotes(registrationId)) || []).map(
        (note, i) => ({
          note,
          title: `Class ${i + 1}`,
          updateNote: (updated) =>
            updateNotes(
              `/course-registrations/${registrationId}/classes/class_${i}/notes`,
              { studentClassNote: updated },
            ),
        }),
      )

      await this.setState({ notes, loading: false })
    }
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
