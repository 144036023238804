import styled from 'styled-components'
import {
  hFlex,
  overlay,
  square,
  vFlex,
} from '../../../../../Styles/style-helpers'
import { H2, H5 } from '../../../../../Styles/typography'
import { darkAccent } from '../../../../../Styles/colors'

export const HeaderEl = styled.div`
  position: relative;
  width: 100%;
  height: 40em;
  background: black;
  ${vFlex};
  align-items: flex-start;
`

export const BackgroundEl = styled.img`
  ${overlay};
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const InfoEl = styled.div`
  width: 35%;
  height: 100%;
  margin-left: 12.5%;
  ${vFlex};
  align-items: flex-start;
  justify-content: flex-end;

  & ${H2} {
    font-weight: bold;
    color: white;
    margin-bottom: 1em;

    & > span {
      overflow: hidden;
      display: block;

      & > span {
        display: block;
        white-space: nowrap;
      }
    }
  }

  & ${H5} {
    color: white;
    opacity: 0.35;
    line-height: 1.5;
    margin-bottom: 2em;
  }
`

export const DotsEl = styled.div`
  position: relative;
  margin: 5em 12.5% 10em;
  ${hFlex};
  justify-content: flex-start;

  & span {
    ${square('1em')};
    border-radius: 50%;
    background: ${darkAccent};
    margin-right: 0.75em;
  }
`
