import { Container } from 'unstated'
import { getAllBroadcastClasses } from '../../../API/Broadcast'

const initialState = () => ({
  loading: true,
  loadFailed: false,
  recordings: [],
})

export default class AllBroadcastClassRecordingsStore extends Container {
  name = 'AllBroadcastClassesStore'

  state = initialState()

  linkedStores = {}

  init = async () => {
    await this.setState(initialState())

    const [
      masterclasses,
      workshops,
      btms,
      demos,
      concerts,
      qnas,
    ] = await Promise.all([
      getAllBroadcastClasses('master-class'),
      getAllBroadcastClasses('workshop'),
      getAllBroadcastClasses('btm'),
      getAllBroadcastClasses('demo'),
      getAllBroadcastClasses('concert'),
      getAllBroadcastClasses('qna'),
    ])

    if (masterclasses && workshops) {
      await this.setState({
        recordings: [
          ...masterclasses,
          ...workshops,
          ...btms,
          ...demos,
          ...concerts,
          ...qnas,
        ].filter((c) => c.canSellRecording),
        loading: false,
      })
    } else {
      await this.setState({ loading: false, loadFailed: true })
    }
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
