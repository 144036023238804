export const notificationTypes = [
  {
    type: 'BECOME_TEACHER_REQUEST_APPROVED',
    route: '/dashboard/teacher/profile',
    icon: '',
  },
  {
    type: 'BECOME_TEACHER_REQUEST_REJECTED',
    route: '/becomeTeacher',
    icon: '',
  },
  {
    type: 'PAID_MASTER_CLASS_STUDENT',
    route: '/@livdemyId/@bcType/@masterClassURL/@version',
    icon: '',
  },
  {
    type: 'PAID_MASTER_CLASS_TEACHER',
    route: '/@livdemyId/@bcType/@masterClassURL/@version',
    icon: '',
  },
  {
    type: 'MASTER_CLASS_MADE_LIVE',
    route: '/@livdemyId/@bcType/@url/@version',
    icon: '',
  },
  {
    type: 'MASTER_CLASS_APPROVED',
    route: '/@livdemyId/@bcType/@url/@version',
    icon: '',
  },
  {
    type: 'COURSE_CREATION_APPROVED',
    route: '/dashboard/teacher/courses',
    icon: '',
  },
  {
    type: 'COURSE_META_DATA_APPROVED',
    route: '/dashboard/teacher/courses',
    icon: '',
  },
  {
    type: 'COURSE_CREATION_REJECTED',
    route: '/dashboard/teacher/courses',
    icon: '',
  },
  {
    type: 'COURSE_ENROLLMENT_SUBMITTED',
    route: '/dashboard/messages/@chatId',
    icon: '',
  },
  {
    type: 'COURSE_ENROLLMENT_APPROVED',
    route: '/dashboard/messages/@chatId',
    icon: '',
  },
  {
    type: 'COURSE_ENROLLMENT_REJECTED',
    route: '/dashboard/messages/@chatId',
    icon: '',
  },
  {
    type: 'DEMO_CLASS_REQUESTED',
    route: '/dashboard/messages/@chatId',
    icon: '',
  },
  {
    type: 'DEMO_CLASS_REQUESTED_APPROVED',
    route: '/dashboard/messages/@chatId',
    icon: '',
  },
]
