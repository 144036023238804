import { API } from './config'
import { getField } from '../Helpers/Misc'
import {
  apply,
  ascend,
  concat,
  filter,
  gte,
  juxt,
  lte,
  pipe,
  propOr,
  sort,
  uniqBy,
} from 'ramda'
import ms from 'ms'

export const joinClass = (crId, classId, batchId) =>
  API.put(
    `/join`,
    batchId
      ? {
          crId,
          classId,
          batchId,
        }
      : {
          crId,
          classId,
        },
  )
    .then((response) => getField(response, 'data.data'))
    .catch(() => false)

export const getClassroomClasses = () =>
  API.get(`/calendar/upcoming`)
    .then((res) => {
      const {
        studentClasses = [],
        teacherClasses = [],
        masterClasses = [],
        concerts = [],
        workshops = [],
        demos = [],
        btms = [],
        qnas = [],
        gigs = [],
      } = res?.data?.data || {}

      const Classes = [...studentClasses, ...teacherClasses]
      const brodcastClass = [
        ...masterClasses,
        ...concerts,
        ...workshops,
        ...demos,
        ...btms,
        ...qnas,
        ...gigs,
      ]
      const brodcastClassmodified = brodcastClass.map((cls) => {
        return {
          ...cls,
          course: {
            title: cls?.name,
            cover: cls?.coverImage,
          },
          type: 'BRC',
          batchId: cls?.id,
        }
      })
      const allClasses = [...Classes, ...brodcastClassmodified]
      const activeClasses = pipe(
        sort(ascend(propOr(0, 'startTime'))),
        filter(pipe(propOr(0, 'endTime'), lte(Date.now() - ms('6h')))),
        filter(pipe(propOr(0, 'startTime'), gte(Date.now()))),
        uniqBy(
          pipe(
            juxt([propOr('', 'batchId'), propOr('', 'classId')]),
            apply(concat),
          ),
        ),
      )(allClasses)

      const upcomingClasses = pipe(
        sort(ascend(propOr(0, 'startTime'))),
        filter(pipe(propOr(0, 'startTime'), lte(Date.now()))),
        uniqBy(
          pipe(
            juxt([propOr('', 'batchId'), propOr('', 'classId')]),
            apply(concat),
          ),
        ),
      )(allClasses)

      return {
        allClasses: upcomingClasses,
        activeClasses,
      }
    })
    .catch(() => false)

export const screenShareStatus = (registrationID, classID, enabled) =>
  API.post(`${registrationID}/screen-sharing/${classID}/${enabled}`)
    .then(() => true)
    .catch(() => false)

export const startRecording = ({ registrationID, classID }) =>
  API.put(`/class/archive`, {
    crId: registrationID,
    classId: classID,
  })
    .then(() => true)
    .catch(() => false)

export const startRecordingGroup = ({ batchId, registrationID, classID }) =>
  API.put(`/course/batch/${batchId}/class/${classID}/archive`, {
    crId: registrationID,
    classId: classID,
  })
    .then(() => true)
    .catch(() => false)

export const sendRecordingToStudent = ({
  courseID,
  studentID,
  teacherID,
  data,
}) =>
  API.post(
    `/sendToStudent/course/${courseID}/student/${studentID}/teacher/${teacherID}`,
    data,
  )
    .then((res) => res)
    .catch(() => false)

export const raiseHandRequest = (batchId, classId) =>
  API.post(`/course/batch/chat/live`, {
    batchId,
    classId,
  })
    .then(() => true)
    .catch(() => false)

export const grantLiveChatAccess = (batchId, classId, liveRequestId, grant) =>
  API.post(`/course/batch/chat/live/grant`, {
    batchId,
    classId,
    liveRequestId,
    grant,
  })
    .then(() => true)
    .catch(() => false)

export const endLiveChat = (batchId, classId) =>
  API.post(`/course/batch/live/end`, {
    batchId,
    classId,
  })
    .then(() => true)
    .catch(() => false)

export const lowerHandRequest = (batchId, classId) =>
  API.delete(`/course/batch/chat/live`, {
    data: {
      batchId,
      classId,
    },
  })
    .then(() => true)
    .catch(() => false)
