export const string = `Entered value is not a valid string`
export const url = `Entered value is not a valid url`
export const number = `Entered value is not a number`
export const isRequired = (field) => `${field} is required`
export const min = `Enter at least 6 characters`
export const email = `Please enter a valid Email address`
export const emailNotRegistered = `The entered email was not associated with any registered account`
export const emailAlreadyInUse = `The entered email is already associated with an account.`
export const maxNumberOfClasses = `Max 25 classes allowed`
export const phoneNumber = `Entered value is not a valid phone number`
export const otp4digit = `Please eneter valid 4 digit OTP`
