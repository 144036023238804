import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import Button from '../../Components/Buttons/Button'
import './ErrorBoundary.sass'

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorReason: '',
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV !== 'staging') {
      Sentry.init({
        dsn: 'https://a1929ddd131e4fbd905237dc02a20802@sentry.io/1472056',
        environment: process.env.REACT_APP_ENV,
      })
    }
  }

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_ENV !== 'staging') {
      Sentry.captureException(error)
    }
  }

  render() {
    const { errorReason, hasError } = this.state
    const { children } = this.props

    // noinspection PointlessBooleanExpressionJS
    if (false && hasError) {
      return (
        <div className='errorBoundary'>
          <h1>Oh Snap!</h1>
          <h2>
            Something went wrong. Our developers have been notified and will fix
            it asap. Thank you for your patience.
          </h2>
          <p>{errorReason}</p>
          <div className='errorImage'>
            <span className='icon illustration' />
          </div>
          <div className='errorBoundaryButtons'>
            <Button
              name={'Go Home'}
              type={'primary big'}
              className={'course-btns'}
              onClick={() => {
                this.setState({ hasError: false, errorReason: {} })
                window.open('/', '_self')
              }}
            />
          </div>
        </div>
      )
    } else {
      return children
    }
  }
}

export default ErrorBoundary
