import date from 'date-and-time'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Subscribe } from 'unstated'
import UserStore from '../../App/UserAuth/Store/UserStore'
import './Card.sass'
import { getField } from '../../Helpers/Misc'
import { getUrlFromType } from '../../App/BroadcastClass/Helpers'
import { getPriceFromMatrix } from '../../Helpers/Price'

const MasterclassCard = ({
  isSeries,
  noOfBroadcastClasses = 1,
  name,
  teacher = {},
  livdemyId,
  coverImage,
  cover,
  currency,
  discount,
  fee,
  startTime,
  endTime,
  onClick = () => {},
  url,
  version,
  index,
  type,
  coHosts,
  priceMatrix,
  canSellRecording,
  teacherName,
  artist,
  isEmbed,
  ...rest
}) => {
  const duration = (Number(endTime) - Number(startTime)) / (1000 * 60 * 60)
  const fees = getPriceFromMatrix(priceMatrix, {
    currency,
    amount: fee,
  })

  if (!teacherName && !artist) {
    console.log({ rest })
  }

  return (
    <Subscribe to={[UserStore]}>
      {({ state: { id: userID } }) => {
        return (
          <Link
            to={
              isEmbed
                ? '#'
                : !teacher.livdemyId
                ? url
                : `/${teacher.livdemyId || livdemyId}/${getUrlFromType(type)}${
                    isSeries ? '/series' : ''
                  }/${url}${version === 1 ? '/1' : `/${version}`}`
            }
          >
            <div
              className={
                'masterclassCard type-' + type + (isSeries ? ' isSeries' : '')
              }
              onClick={() => onClick(index)}
            >
              <div
                className='courseImage'
                style={{
                  background: `url(${
                    cover ||
                    getField(coverImage, 'location') ||
                    getField(teacher, 'coverImage.location')
                  }) center center /cover no-repeat`,
                }}
              />
              <div className='courseDetails'>
                <h1 className='courseName'>{name}</h1>
                <p className='teacherName'>
                  By{' '}
                  <span>
                    {artist ||
                      teacherName ||
                      teacher.name ||
                      teacher.userInfo?.name}{' '}
                    {Boolean(coHosts?.length) &&
                      `ft. ${coHosts
                        .map((host) => host.userInfo?.name)
                        .join(', ')}`}
                  </span>
                </p>
                <div className='row'>
                  <div className='duration'>
                    {!isSeries && <span className='icon' />}
                    {isSeries ? (
                      <span>{noOfBroadcastClasses} Class Series</span>
                    ) : (
                      `${duration} hour${duration > 1 ? 's' : ''}`
                    )}
                    <br />
                    {Number(startTime) > Date.now() ? (
                      <>
                        {date.format(
                          new Date(Number(startTime)),
                          `ddd DD MMM YYYY, h:mm A`,
                        )}{' '}
                        {new Date()
                          .toLocaleTimeString('en-us', { timeZoneName: 'long' })
                          .split(' ')
                          .slice(2)
                          .join(' ')}
                      </>
                    ) : Number(endTime) > Date.now() ? (
                      'Ongoing'
                    ) : canSellRecording ? (
                      'Recording Available'
                    ) : (
                      'Completed'
                    )}
                  </div>
                  {!!userID && (
                    <div className={'fee' + (discount ? ' withDiscount' : '')}>
                      <span className='original'>
                        {fees.currency}{' '}
                        {Math.ceil(fees.amount / noOfBroadcastClasses)}{' '}
                        {isSeries && '/ class'}
                      </span>
                      <br />
                      {!!discount && (
                        <span className={'discounted'}>
                          {fees.currency}{' '}
                          {Math.ceil(
                            (fees.amount * (1 - discount / 100)) /
                              noOfBroadcastClasses,
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Link>
        )
      }}
    </Subscribe>
  )
}

export default withRouter(MasterclassCard)
