import { Container } from 'unstated'

const initialState = {
  openDocument: null,
}

export default class DocumentsViewerStore extends Container {
  name = 'DocumentsViewerStore'

  state = initialState

  openDocument = (document) => {
    return this.setState({ openDocument: document })
  }

  closeDocument = () => this.setState({ openDocument: null })
}
