import { useCallback, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { useLocation } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'

const PixelTrackingStore = createContainer(() => {
  let location = useLocation()

  const track = useCallback((title = '', data = {}) => {
    ReactPixel.track(title, data)
  }, [])

  useEffect(() => {
    track('PageView')
  }, [location, track])

  return { track }
})

export default PixelTrackingStore
