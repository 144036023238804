import React, { useState } from 'react'
import TimeKeeper from 'react-timekeeper'
import { Portal } from 'react-portal'
import date from 'date-and-time'
import { default as DatePickerModule } from 'react-date-picker'

const DatePicker = ({
  placeholder,
  field = {},
  form = {},
  value = '',
  mode = 'time',
  showTimePlaceholder = false,
  ...props
}) => {
  const [show, toggle] = useState(false)

  const onChange = (time) => {
    form.setFieldValue && form.setFieldValue(field.name, time)
    form.setFieldTouched && form.setFieldTouched(field.name, true)
    return props.onChange ? props.onChange(time) : field.onChange(time)
  }

  return mode === 'time' ? (
    <div
      className={
        'inputWrapper datePicker' + (props.disabled ? ' disabled' : '')
      }
    >
      {show && (
        <Portal>
          <div className='timeOverlay'>
            <TimeKeeper
              {...props}
              switchToMinuteOnHourSelect={true}
              time={{
                hour: new Date(value || field.value).getHours(),
                minute: new Date(value || field.value).getMinutes(),
              }}
              onChange={({ hour, minute }) => {
                const update = new Date(value || field.value)
                update.setHours(hour, minute, 0, 0)
                onChange(update.getTime())
              }}
              onDoneClick={() => toggle(false)}
            />
          </div>
        </Portal>
      )}
      {showTimePlaceholder && (
        <p className={'placeholder' + (value || field.value ? ' active' : '')}>
          {placeholder}
        </p>
      )}
      <input
        type={'text'}
        placeholder=' '
        value={date.format(new Date(value || field.value), 'hh:mm A')}
        onClick={() => toggle(true)}
      />
      <p className={'error'}>
        {form.errors &&
          form.touched[field.name] &&
          form.errors[field.name] &&
          props.error}
      </p>
    </div>
  ) : (
    <div
      className={
        'inputWrapper datePicker' + (props.disabled ? ' disabled' : '')
      }
    >
      <DatePickerModule
        {...props}
        onChange={(time) => {
          const oldTime = new Date(value || field.value)
          const newTime = time
          newTime.setHours(oldTime.getHours(), oldTime.getMinutes(), 0, 0)
          onChange(newTime.getTime())
        }}
        value={new Date(value || field.value)}
        clearIcon={null}
        showLeadingZeros={false}
      />
      <p className={'placeholder' + (value || field.value ? ' active' : '')}>
        {placeholder}
      </p>
      <p className={'error'}>
        {form.errors &&
          form.touched[field.name] &&
          form.errors[field.name] &&
          props.error}
      </p>
    </div>
  )
}

export default DatePicker
