import React from 'react'
import { InfoEl } from '../elements'
import { H2, H5 } from '../../../../../Styles/typography'
import Button from '../../../../../Components/Buttons/Button'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

const fadeIn = (max) => ({
  initial: { opacity: 0 },
  animate: { opacity: max, transition: { duration: 1.5 } },
  exit: { opacity: 0 },
})

const slideUp = {
  initial: { opacity: 1, y: '100%' },
  animate: { opacity: 1, y: '0%' },
  exit: { opacity: 0, y: '0%' },
}

const Info = ({ heading, para, cta, index }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <InfoEl
        key={`info-${index}`}
        as={motion.div}
        variants={{
          initial: { opacity: 0 },
          animate: {
            opacity: 1,
            transition: {
              when: 'beforeChildren',
              staggerChildren: 0.15,
            },
          },
          exit: { opacity: 0 },
        }}
        initial='initial'
        animate='animate'
        exit='exit'
      >
        <H2 as={motion.h2}>
          {heading.map((h, i) => (
            <span key={`heading-${i}`}>
              <motion.span transition={{ duration: 0.35 }} variants={slideUp}>
                {h}
              </motion.span>
            </span>
          ))}
        </H2>
        <H5 as={motion.h5} variants={fadeIn(0.75)}>
          {para.map((p, i) => (
            <span key={`para-${i}`}>
              {p} <br />
            </span>
          ))}
        </H5>
        {cta && (
          <motion.div variants={fadeIn(1)}>
            {cta.external || cta.route.startsWith('http') ? (
              <a href={cta.route} target='_blank' rel='noopener noreferrer'>
                <Button name={cta.name} type='primary' onClick={() => {}} />
              </a>
            ) : (
              <Link to={cta.route}>
                <Button name={cta.name} type='primary' onClick={() => {}} />
              </Link>
            )}
          </motion.div>
        )}
      </InfoEl>
    </AnimatePresence>
  )
}

export default Info
