import React from 'react'
import { toast } from 'react-toastify'
import date from 'date-and-time'
import { getUrlFromType } from '../App/BroadcastClass/Helpers'

export const notify = (
  type = 'notify',
  message = '',
  interaction = () => {},
  id = '',
) =>
  toast(
    () => (
      <div className={'toast ' + type}>
        <span className={'icon ' + type} />
        <div className='info'>
          <h1 className={'header ' + type}>
            {type === 'success'
              ? 'Success'
              : type === 'warning'
              ? 'Warning'
              : type === 'error'
              ? 'Error'
              : ''}
          </h1>
          <p className='text' onClick={interaction}>
            {message}
          </p>
        </div>
      </div>
    ),
    {
      autoClose: 5000,
      closeButton: false,
      hideProgressBar: true,
      className: type,
      ...(id ? { toastId: id } : { toastId: message }),
    },
  )

export const parseNotificationText = (text, data) => {
  let result = text

  if (data && text) {
    Object.keys(data).forEach((key) => {
      let value = data[key]
      if (key.toLowerCase().includes('time')) {
        value = date.format(new Date(data[key]), 'h:mm A, DD MMM YYYY')
      }
      if (key === 'bcType') {
        value = getUrlFromType(data[key])
      }
      result = result.replace(`@${key}`, value)
    })
  }

  return result
}

window.notify = notify
