import { API } from './config'

export const requestDemoClass = (livdemyId, teacherName, startTime, message) =>
  API.post(`/demo-class-request`, {
    livdemyId,
    teacherName,
    startTime,
    message,
  })
    .then(() => true)
    .catch(() => false)

export const joinDemoClass = (demoClassId) =>
  API.post(`/join-demo-class`, { demoClassId })
    .then(({ data: { data: { sessionId, token } } }) => ({
      tokboxSession: sessionId,
      tokboxToken: token,
    }))
    .catch(() => false)
