import React from 'react'
import { Link } from 'react-router-dom'

const links = [
  {
    name: 'Support',
    route: '/support',
  },
  {
    name: 'FAQ',
    route: '/faq',
  },
  {
    name: 'Blog',
    route: 'https://blog.livdemy.com',
  },
  {
    name: 'Courses',
    route: '/courses',
    rel: 'canonical',
  },
  {
    name: 'Categories',
    route: '/categories',
    rel: 'canonical',
  },
  {
    name: 'Sitemap',
    route: '/sitemap',
  },
  {
    name: 'Become a Teacher',
    route: '/becomeTeacher',
  },
]

const Resources = () => (
  <div className='resources'>
    <h1>Resources</h1>
    <div className='links'>
      {links.map(({ name, route, rel }, i) =>
        route.startsWith('https://') ? (
          <a
            href={route}
            key={'ResourcesLinks' + i}
            target='_blank'
            rel='noreferrer noopener'
          >
            {name}
          </a>
        ) : (
          <Link to={route} key={'ResourcesLinks' + i} rel={rel}>
            {name}
          </Link>
        ),
      )}
    </div>
  </div>
)

export default Resources
