import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { getLocalCurrency } from '../../../API/Billing'
import useSWR from 'swr'
import applySpec from 'ramda/src/applySpec'
import {
  ascend,
  assoc,
  descend,
  map,
  pathOr,
  pipe,
  propOr,
  slice,
  sort,
} from 'ramda'
import { slsPublicBaseUrl } from '../../../API/config'

const uniqid = require('uniqid')

const addId = map((item) => assoc('id', uniqid(), item))

const landingParser = applySpec({
  artists: pipe(pathOr([], ['artists']), addId),
  btm: pipe(pathOr([], ['btm']), addId),
  concert: pipe(pathOr([], ['concert']), addId),
  demo: pipe(pathOr([], ['demo']), addId),
  liveCourses: pipe(pathOr([], ['liveCourses']), addId),
  masterclasses: pipe(pathOr([], ['masterclass']), addId),
  qna: pipe(pathOr([], ['qna']), addId),
  recordedCourses: pipe(
    pathOr([], ['recordedCourses']),
    slice(1, Infinity),
    addId,
  ),
  workshops: pipe(pathOr([], ['workshop']), addId),
  gigs: pipe(pathOr([], ['gig']), addId),
})

export const landingPageArtist = applySpec({
  cover: propOr('', 'coverImage'),
  id: propOr('', 'id'),
  livdemyId: propOr('', 'livdemyId'),
  name: propOr('', 'name'),
})

export const landingBannerParser = pipe(
  addId,
  map((x) => ({ ...x, position: Number(x.position) })),
  sort(ascend(propOr(0, 'position'))),
)

export const landingReviewsParser = pipe(
  addId,
  sort(descend(pathOr(0, ['text', 'length']))),
)
const HomeStore = createContainer(() => {
  const [localCurrency, setLocalCurrency] = useState('INR')

  useEffect(() => {
    ;(async () => {
      setLocalCurrency(await getLocalCurrency())
    })()
  }, [])

  const { data, error } = useSWR([
    `${slsPublicBaseUrl}/home/landing`,
    landingParser,
  ])

  const { data: bannerData, error: bannerError } = useSWR([
    `${slsPublicBaseUrl}/banner/all`,
    landingBannerParser,
  ])

  const exr = useSWR(`currency?local=${localCurrency}&foreign=INR`)

  const reviews = useSWR([
    `review/v3/WHITELISTED/limit/15`,
    landingReviewsParser,
  ])

  return {
    loading: (!data && !error) || (!bannerData && !bannerError),
    loadFailed: Boolean(error || bannerError),
    data,
    exr,
    localCurrency,
    bannerData,
    reviews,
  }
})

export default HomeStore
