import React from 'react'
import { DotsEl } from '../elements'
import { motion } from 'framer-motion'
import { darkAccent } from '../../../../../Styles/colors'

const Dots = ({ count, index, setIndex }) => {
  return (
    <DotsEl>
      {Array.from({ length: count }).map((_, i) => (
        <motion.span
          key={`dot-${i}`}
          style={{ background: index === i ? darkAccent : '#444' }}
          onClick={() => setIndex(i)}
        />
      ))}
    </DotsEl>
  )
}

export default Dots
