import React from 'react'
import './Loading.sass'
import { PulseLoader } from 'react-spinners'

const Loading = ({ color, full, small }) => (
  <div
    className={'loadingAnimation'}
    style={{
      minHeight: full ? '30em' : 0,
    }}
  >
    {small ? (
      <PulseLoader loading={true} color={color || '#ec9871'} size={10} />
    ) : (
      <svg
        width='57px'
        height='60px'
        viewBox='0 0 57 60'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <title>Group 24</title>
        <desc>Created with Sketch.</desc>
        <g
          id='new-user'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Meet-our-teacher-1'
            transform='translate(-772.000000, -2168.000000)'
          >
            <g id='Group-24' transform='translate(772.000000, 2168.000000)'>
              <path
                d='M24.8258872,42.2104878 C22.8269897,42.2104878 21.2071179,40.5880488 21.2071179,38.5865854 C21.2071179,36.5860976 22.8269897,34.9636585 24.8258872,34.9636585 C26.8247846,34.9636585 28.4446564,36.5860976 28.4446564,38.5865854 C28.4446564,40.5880488 26.8247846,42.2104878 24.8258872,42.2104878 L24.8258872,42.2104878 Z M39.0992718,27.1290244 L28.5016564,16.5178049 L28.5016564,20.6587805 L28.5016564,31.9582927 C24.8468359,29.9246341 20.2371436,31.2426829 18.2060923,34.9017073 C16.1745538,38.5612195 17.4904256,43.1763415 21.1452462,45.2104878 C24.7995795,47.2441463 29.4092718,45.9270732 31.4408103,42.267561 C32.0629385,41.1460976 32.3912974,39.885122 32.3942205,38.6026829 L32.3942205,25.9334146 L36.3389128,29.8856098 L39.0992718,27.1290244 Z'
                id='Fill-1'
                fill='#EC9871'
              />
              <g id='Group-23'>
                <path
                  d='M45.1528179,13.3285366 C43.1129974,13.8197561 41.062459,12.5626829 40.5723564,10.5202439 C40.0817667,8.47878049 41.3377154,6.42560976 43.3765615,5.93439024 C45.4158949,5.44317073 47.4669205,6.7002439 47.9570231,8.74268293 C48.4476128,10.7841463 47.1921513,12.8373171 45.1528179,13.3285366'
                  id='Fill-3'
                  fill='#B74135'
                />
                <path
                  d='M52.4780974,44.3846341 C50.8095077,44.7865854 49.1316615,43.7578049 48.7307128,42.0870732 C48.3287897,40.4163415 49.3567385,38.7363415 51.0253282,38.334878 C52.6934308,37.9329268 54.3712769,38.9617073 54.7727128,40.632439 C55.1736615,42.3026829 54.1466872,43.9831707 52.4780974,44.3846341'
                  id='Fill-5'
                  fill='#C45233'
                />
                <path
                  d='M15.3087872,7.00658537 C15.4422744,7.56414634 15.0997872,8.12365854 14.5439154,8.25731707 C13.9875564,8.39146341 13.4287615,8.04853659 13.2947872,7.49146341 C13.1608128,6.93487805 13.5033,6.37487805 14.059659,6.24121951 C14.6155308,6.10707317 15.1753,6.4495122 15.3087872,7.00658537'
                  id='Fill-7'
                  fill='#EC9871'
                />
                <path
                  d='M34.1089462,3.17941463 C34.6443564,5.40673171 33.2744077,7.64673171 31.0499462,8.18282927 C28.8249974,8.71795122 26.5878692,7.34673171 26.0529462,5.11941463 C25.5180231,2.89160976 26.8879718,0.651609756 29.1124333,0.116 C31.3373821,-0.419609756 33.5740231,0.952097561 34.1089462,3.17941463'
                  id='Fill-9'
                  fill='#C7402D'
                />
                <path
                  d='M28.6828872,56.7826829 C28.9951692,58.0821951 28.1961949,59.3890244 26.8983487,59.7007317 C25.6005026,60.0134146 24.2953487,59.2134146 23.9835538,57.9139024 C23.671759,56.6143902 24.4707333,55.3085366 25.7685795,54.9958537 C27.0659385,54.6831707 28.3706051,55.4831707 28.6828872,56.7826829'
                  id='Fill-11'
                  fill='#C45B43'
                />
                <path
                  d='M2.13019231,38.3380488 C1.20357692,38.5614634 0.271115385,37.9897561 0.048474359,37.0619512 C-0.174653846,36.1336585 0.395833333,35.2 1.3229359,34.9770732 C2.25003846,34.7536585 3.18201282,35.3253659 3.40514103,36.2536585 C3.62778205,37.1819512 3.05680769,38.115122 2.13019231,38.3380488'
                  id='Fill-13'
                  fill='#D37153'
                />
                <path
                  d='M4.30773846,20.0658537 C3.56625128,20.2443902 2.82037949,19.7873171 2.64207179,19.0443902 C2.4637641,18.302439 2.92025128,17.5556098 3.66222564,17.3765854 C4.40322564,17.1985366 5.14958462,17.6556098 5.32789231,18.3980488 C5.50571282,19.1409756 5.04922564,19.8873171 4.30773846,20.0658537'
                  id='Fill-15'
                  fill='#E58965'
                />
                <path
                  d='M11.3899154,53.210878 C10.2776846,53.4786829 9.15912051,52.7933171 8.89165897,51.6791707 C8.62419744,50.5655122 9.30917179,49.4455122 10.4214026,49.1772195 C11.5336333,48.9099024 12.6526846,49.5957561 12.9201462,50.7094146 C13.1871205,51.8230732 12.5021462,52.9430732 11.3899154,53.210878'
                  id='Fill-17'
                  fill='#CC5E40'
                />
                <path
                  d='M41.3947641,56.566439 C39.9117897,56.9230244 38.4205333,56.008878 38.0639179,54.524 C37.7073026,53.039122 38.6202769,51.5454634 40.1037385,51.188878 C41.5867128,50.8313171 43.0779692,51.7459512 43.4345846,53.2308293 C43.7912,54.7157073 42.8782256,56.2093659 41.3947641,56.566439'
                  id='Fill-19'
                  fill='#C9573D'
                />
                <path
                  d='M54.2678974,27.8907317 C52.4136923,28.3370732 50.5497436,27.1936585 50.1039744,25.3380488 C49.6582051,23.4814634 50.7996667,21.6146341 52.6538718,21.1682927 C54.5075897,20.7219512 56.3715385,21.864878 56.8173077,23.7209756 C57.2630769,25.577561 56.1221026,27.4443902 54.2678974,27.8907317'
                  id='Fill-21'
                  fill='#BF4C3A'
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )}
  </div>
)

export default Loading
