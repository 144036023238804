import React from 'react'
import { withRouter } from 'react-router-dom'
import CourseList from '../../../Components/Lists/CourseList'
const ResumeVideos = () => {
  const data = JSON.parse(localStorage.getItem('resumeVideos')) || []
  const resumecoursedata = data.map((element) => {
    return { ...element.course, url: element.url }
  })
  if (resumecoursedata.length === 0) return null
  return (
    <div className='ResumeVideos'>
      <div className='sectionHeaderContainer'>
        <h1 className={'sectionHeader'}>Continue watching</h1>

        <p className='sectionSubHeader'>
          Continue watching from where you left off, or start something new from
          your registered courses.
        </p>
      </div>
      <CourseList
        data={resumecoursedata || []}
        loading={false}
        loadFailed={false}
        name={'Resume Courses'}
        limit={6}
        onCardClick={() => {}}
        type='resume'
        // hideViewMore
      />
    </div>
  )
}

export default withRouter(ResumeVideos)
