import React, { useEffect, useMemo, useState } from 'react'
import Background from './components/Background'
import Info from './components/Info'
import { HeaderEl } from './elements'
import useTime from '../../../../Helpers/CustomHooks/useTime'
import { headerSlides } from '../../Content/Header'
import Dots from './components/Dots'
import useSWR from 'swr'
import { slsPublicBaseUrl } from '../../../../API/config'

const cachedData = headerSlides.sort((a, b) => a.position - b.position)

const Header = () => {
  const updateInterval = useTime(7500)
  const [index, setIndex] = useState(-1)

  const { data: fetchedData = cachedData } = useSWR(
    `${slsPublicBaseUrl}/banner/all`,
  )

  const data = useMemo(
    () => fetchedData.sort((a, b) => Number(a.position) - Number(b.position)),
    [fetchedData],
  )

  useEffect(() => {
    setIndex((old) => (old + 1) % data.length)
  }, [data.length, updateInterval])

  useEffect(() => {
    setIndex(0)
  }, [data])

  return index !== -1 ? (
    <HeaderEl>
      <Background bg={data[index].image} />
      <Info {...data[index]} index={index} />
      <Dots count={data.length} index={index} setIndex={setIndex} />
    </HeaderEl>
  ) : null
}

export default Header
