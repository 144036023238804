import axios from 'axios'
import { notify } from '../Helpers/Notifications'
import { getLocalCurrency } from './Billing'

export const baseUrl = process.env.REACT_APP_API_URL
export const slsPublicBaseUrl = process.env.REACT_APP_PUBLIC_API_URL
export const slsCoreBaseUrl = process.env.REACT_APP_SLS_API_URL

const user = JSON.parse(window.localStorage.getItem('user'))
const authToken = user?.authToken

export const API = axios.create({
  baseURL: baseUrl,
  headers: authToken ? { authorization: `bearer ${authToken}` } : {},
})

export const ForumApi = axios.create({
  baseURL: process.env.REACT_APP_FORUM_API_URL,
  headers: authToken ? { authorization: `bearer ${authToken}` } : {},
})

window.api = API

export const initApis = (user, userLoggedIn, userLoggedOut) => {
  API.interceptors.request.use(async (request) => {
    const user = window.localStorage.getItem('user')
    const authToken = user ? JSON.parse(user).authToken : ''

    request.headers = {
      ...request.headers,
      authorization: authToken && `Bearer ${authToken}`,
      'local-currency': await getLocalCurrency(),
    }
    return request
  })
  API.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }
      if (error.message.includes('timeout of ')) {
        error.message =
          'The server took too long to respond. Please refresh the page to try again.'
      }
      const originalRequest = error.config
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true
        let { refreshToken } = user
        if (!refreshToken) {
          const user = JSON.parse(window.localStorage.getItem('user'))
          refreshToken = user.refreshToken
        }
        return API.post('/auth/get-auth-token', { refreshToken })
          .then((response) => {
            userLoggedIn({
              ...user,
              authToken: response.data.data.authToken,
            })

            axios.defaults.headers.common['authorization'] = 'bearer '.concat(
              response.data.data.authToken,
            )
            originalRequest.headers['authorization'] = 'bearer '.concat(
              response.data.data.authToken,
            )
            return axios(originalRequest)
          })
          .catch(() => {
            userLoggedOut()
            return false
          })
      } else if (
        error.response &&
        error.response.status === 401 &&
        originalRequest._retry
      ) {
        userLoggedOut()
      } else if (error.response && error.response.status === 400) {
        //Todo: fix this patch.
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.messages[0] === 'Invalid authorization token'
        ) {
          return Promise.reject(error)
        } else if (
          error.response.data.messages[0] !== "You can't access the course" &&
          error.response.data.messages[0] &&
          error.response.data.messages[0].message !==
            'You have not registered yet. Please register.' &&
          error.response.data.messages[0] !== 'The class is ended.'
        ) {
          const message =
            typeof error?.response?.data?.messages[0] === 'string'
              ? error?.response?.data?.messages[0]
              : 'Error'

          if (!message.includes('undefined')) {
            notify('error', message)
          }
        }

        return Promise.reject(error)
      } else {
        if (error?.response && error?.response?.data?.messages) {
          const message =
            typeof error?.response?.data?.messages[0] === 'string'
              ? error?.response?.data?.messages[0]
              : 'Error'

          if (!message.includes('undefined')) {
            notify('error', message)
          }
        } else {
          const message = error?.message || 'Error'
          if (!message.includes('undefined')) {
            notify('error', message)
          }
        }
        return Promise.reject(error)
      }

      return Promise.reject(error)
    },
  )

  ForumApi.interceptors.request.use(async (request) => {
    const user = window.localStorage.getItem('user')
    const authToken = user ? JSON.parse(user).authToken : ''

    request.headers = {
      ...request.headers,
      authorization: authToken && `Bearer ${authToken}`,
    }

    return request
  })

  ForumApi.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error?.response?.data?.error || error.message
      notify('error', message)

      return Promise.reject(error)
    },
  )
}

axios.defaults.timeout = 10 * 1000

export default baseUrl
