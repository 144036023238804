import { createContainer } from 'unstated-next'
import useSWR from 'swr'
import { useToggle } from 'react-use'
import { useCallback, useEffect } from 'react'
import { markNotificationRead } from '../../../API/Notifications'
import { notificationTypes } from '../Data/NotificationTypes'
import { notify, parseNotificationText } from '../../../Helpers/Notifications'
import { useHistory } from 'react-router-dom'
import RealtimeEventsStore from '../../RealtimeEvents/Store/RealtimeEventsStore'
import { descend, prop, sort } from 'ramda'

const NotificationStore = createContainer(() => {
  const { channel } = RealtimeEventsStore.useContainer()
  const [showList, toggleList] = useToggle(false)
  const history = useHistory()
  const { data, error, mutate } = useSWR(
    !showList ? null : [`sync-notifs`, sort(descend(prop('createdAt')))],
  )

  const markRead = useCallback(
    (id) => {
      markNotificationRead(id).then(() =>
        mutate((old) => {
          const notifications = [...old]
          const index = notifications.findIndex((x) => x.id === id)
          notifications[index] = { ...notifications[index], read: true }
          return notifications
        }),
      )
    },
    [mutate],
  )

  const markAllRead = useCallback(() => {
    return markNotificationRead().then(() => mutate())
  }, [mutate])

  const notificationClicked = useCallback(
    async (notification) => {
      const { read, id, type } = notification

      if (!read) {
        markRead(id)
      }

      const notificationType = notificationTypes.find(
        (notification) => notification.type === type,
      )

      if (!notificationType) {
        return
      }

      const route = parseNotificationText(
        notificationType.route,
        notification.data,
      )

      history.push(route)

      toggleList(false)
    },
    [history, markRead, toggleList],
  )

  useEffect(() => {
    if (data) {
      const handler = ({ data: notification }) => {
        notify(
          'notify',
          parseNotificationText(notification.message, notification.data),
          () => notificationClicked(notification),
          notification.id,
        )
      }
      channel.subscribe('SYNC_NOTIF', handler)

      return () => channel.unsubscribe('SYNC_NOTIF', handler)
    }
  }, [channel, data, notificationClicked])

  return {
    loading: !data,
    loadFailed: Boolean(error),
    data,
    showList,
    toggleList,
    markAllRead,
    notificationClicked,
  }
})

export default NotificationStore
