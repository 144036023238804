import { Container } from 'unstated'
import { allowJoinClass } from '../../../App/Course/Helpers/Timings'
import { getUpcomingClasses } from '../../../API/Upcoming'
// import { getClassroomClasses } from "../../../API/Classroom";

const initialState = () => ({
  loaded: false,
  upcoming: null,
  classes: [],
})

export default class UpcomingClassStore extends Container {
  name = 'UpcomingClassStore'

  state = initialState()

  timer = null

  linkedStores = {}

  init = async () => {
    const {
      state: {
        id: userID,
        role,
        profile: { name: userName },
      },
    } = this.linkedStores.UserStore

    if (!userID || !userName || !role) {
      this.clearTimer()
      await this.setState(initialState())
      return
    }

    const allClasses = await getUpcomingClasses()

    const classes = []

    const {
      teacherClasses = [],
      studentClasses = [],
      masterClasses = [],
      workshops = [],
      demos = [],
      btms = [],
      qnas = [],
      concerts = [],
      gigs = [],
    } = allClasses

    classes.push(
      ...masterClasses.map((cls) => ({
        startTime: Number(cls.startTime),
        endTime: Number(cls.endTime),
        type: 'masterclass',
        with: cls.teacher.userInfo.name,
        course: cls.name,
        masterclass: true,
        teaching: cls.teacherId === userID,
        url: `/${cls.teacher.livdemyId}/masterclass/${cls.url}/${cls.version}/entry`,
        preRollTime: cls.preRollTime || 15,
      })),
    )

    classes.push(
      ...workshops.map((cls) => ({
        startTime: Number(cls.startTime),
        endTime: Number(cls.endTime),
        type: 'workshop',
        with: cls.teacher.userInfo.name,
        course: cls.name,
        masterclass: true,
        teaching: cls.teacherId === userID,
        url: `/${cls.teacher.livdemyId}/workshop/${cls.url}/${cls.version}/entry`,
        preRollTime: cls.preRollTime || 15,
      })),
    )

    classes.push(
      ...btms.map((cls) => ({
        startTime: Number(cls.startTime),
        endTime: Number(cls.endTime),
        type: 'btm',
        with: cls.teacher.userInfo.name,
        course: cls.name,
        masterclass: true,
        teaching: cls.teacherId === userID,
        url: `/${cls.teacher.livdemyId}/btm/${cls.url}/${cls.version}/entry`,
        preRollTime: cls.preRollTime || 15,
      })),
    )

    classes.push(
      ...qnas.map((cls) => ({
        startTime: Number(cls.startTime),
        endTime: Number(cls.endTime),
        type: 'qna',
        with: cls.teacher.userInfo.name,
        course: cls.name,
        masterclass: true,
        teaching: cls.teacherId === userID,
        url: `/${cls.teacher.livdemyId}/qna/${cls.url}/${cls.version}/entry`,
        preRollTime: cls.preRollTime || 15,
      })),
    )

    classes.push(
      ...demos.map((cls) => ({
        startTime: Number(cls.startTime),
        endTime: Number(cls.endTime),
        type: 'demo',
        with: cls.teacher.userInfo.name,
        course: cls.name,
        masterclass: true,
        teaching: cls.teacherId === userID,
        url: `/${cls.teacher.livdemyId}/demo/${cls.url}/${cls.version}/entry`,
        preRollTime: cls.preRollTime || 15,
      })),
    )

    classes.push(
      ...concerts.map((cls) => ({
        startTime: Number(cls.startTime),
        endTime: Number(cls.endTime),
        type: 'concert',
        with: cls.teacher.userInfo.name,
        course: cls.name,
        masterclass: true,
        teaching: cls.teacherId === userID,
        url: `/${cls.teacher.livdemyId}/concert/${cls.url}/${cls.version}/entry`,
        preRollTime: cls.preRollTime || 15,
      })),
    )

    classes.push(
      ...gigs.map((cls) => ({
        startTime: Number(cls.startTime),
        endTime: Number(cls.endTime),
        type: 'gig',
        with: cls.teacher.userInfo.name,
        course: cls.name,
        masterclass: true,
        teaching: cls.teacherId === userID,
        url: `/${cls.teacher.livdemyId}/gig/${cls.url}/${cls.version}/entry`,
        preRollTime: cls.preRollTime || 15,
      })),
    )

    classes.push(
      ...teacherClasses.map((cls) => {
        const demo = cls.type === 'DEMO_CLASS'
        return {
          startTime: demo ? cls.demoClass.startTime : cls.startTime,
          endTime: demo
            ? parseInt(cls.demoClass.startTime) + 30 * 60 * 1000
            : cls.endTime,
          with: cls.student.name,
          teacher: cls.teacher,
          course: !demo && cls.course.title,
          cover: !demo && cls.course.cover.location,
          teaching: true,
          demo,
          url: '/classroom',
          preRollTime: cls.preRollTime || 15,
        }
      }),
    )

    classes.push(
      ...studentClasses.map((cls) => {
        const demo = cls.type === 'DEMO_CLASS'
        return {
          startTime: demo ? cls.demoClass.startTime : cls.startTime,
          endTime: demo
            ? parseInt(cls.demoClass.startTime) + 30 * 60 * 1000
            : cls.endTime,
          with: cls.teacher.name,
          teacher: cls.teacher,
          course: !demo && cls.course.title,
          cover: !demo && cls.course.cover.location,
          teaching: false,
          demo,
          url: '/classroom',
          preRollTime: cls.preRollTime || 15,
        }
      }),
    )

    await this.setState({
      classes: classes
        .filter(({ endTime }) => {
          return parseInt(endTime) > new Date().getTime() - 30 * 60 * 1000
        })
        .sort((a, b) => parseInt(a.startTime) - parseInt(b.startTime)),
    })

    await this.startTimer()

    await this.setState({ loaded: true })
  }

  startTimer = async () => {
    await this.checkForUpcomingClass()

    this.timer = setInterval(this.checkForUpcomingClass, 5000)
  }

  checkForUpcomingClass = async () => {
    const { classes } = this.state
    const upcoming =
      classes.find(({ startTime, endTime, type, preRollTime = 0 }) => {
        return allowJoinClass(
          startTime,
          Number(endTime) - Number(startTime) + 30 * 60 * 1000,
          preRollTime * 60 * 1000,
        )
      }) || null

    if (JSON.stringify(upcoming) !== JSON.stringify(this.state.upcoming)) {
      await this.setState({ upcoming })
    }
  }

  clearTimer = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
