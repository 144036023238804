import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../../../Components/Logo/Logo'

const SectionDesign = ({ subTitle, footer, children }) => (
  <div className='sectionDesign'>
    <h1>
      Welcome to <Logo mode={'dark'} />
    </h1>
    <h2>{subTitle}</h2>
    <div className='content'>{children}</div>
    <p>{footer}</p>
  </div>
)

export default SectionDesign

SectionDesign.propTypes = {
  subTitle: PropTypes.any.isRequired,
  footer: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
}
