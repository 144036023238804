import React, { useState } from 'react'
import './TopNav.sass'
import Links from './Components/Links'
import Logo from '../Logo/Logo'
import SearchBox from '../../Modules/Search/SearchBox/SearchBox'
import Notifications from '../../Modules/Notifications/Notifications'
import UserContextMenu from './Components/UserContextMenu'
import { Link, withRouter } from 'react-router-dom'
import withStore from '../Unstated/withStore'
import UserStore from '../../App/UserAuth/Store/UserStore'
import Button from '../Buttons/Button'

const { detect } = require('detect-browser')

const browser = detect()

const TopNav = ({
  userStore: {
    state: { loggedIn },
  },
  location,
}) => {
  const [hide, setHide] = useState(true)

  return location.pathname.startsWith('/embed/') ? null : (
    <div className='topNav'>
      <Logo />
      <Links loggedIn={loggedIn} />
      <div className='flexSpread' />
      <SearchBox />
      {loggedIn ? (
        <>
          <Notifications />
          <UserContextMenu />
        </>
      ) : (
        <>
          <Link to={'/auth/login'}>
            <Button type={'border'} onClick={() => {}} name={'Sign In'} />
          </Link>
        </>
      )}
      {!hide &&
        (!browser ||
          (browser &&
            browser.name !== 'chrome' &&
            browser.name !== 'edge-chromium' &&
            browser.name !== 'firefox')) && (
          <div className='browserSupport'>
            <div className='close' onClick={() => setHide(true)} />
            <p>
              This site is currently only supported on latest Desktop versions
              of <a href='https://www.google.com/chrome/'>Chrome</a> and{' '}
              <a href='https://www.mozilla.org/en-US/firefox/new/'>Firefox</a>.
              Please switch/update your browser if you're facing any issues.
            </p>
          </div>
        )}
    </div>
  )
}

export default withStore([UserStore])(withRouter(TopNav))
