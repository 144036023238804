import React from 'react'
import MasterclassList from '../../../Components/Lists/MasterclassList'
import { withRouter } from 'react-router-dom'
import { eventsSort } from '../../../Helpers/Misc'
import HomeStore from '../Store/home-store'
import { filter, pipe } from 'ramda'

const FeaturedMasterclasses = ({ history }) => {
  const {
    loading,
    loadFailed,
    data: {
      masterclasses = [],
      workshops = [],
      demo = [],
      btm = [],
      qna = [],
      concert = [],
      gigs = [],
    } = {},
  } = HomeStore.useContainer()

  return (
    <div className='featuredMasterclasses'>
      <div className='sectionHeaderContainer'>
        <h1 className={'sectionHeader'}>Masterclasses And Workshops</h1>

        <p className='sectionSubHeader'>
          Elevate your skills with live masterclasses and workshops featuring
          expert-led lectures, hands-on demonstrations, and interactive Q&A
          sessions for an immersive and dynamic learning experience.
        </p>
      </div>
      <MasterclassList
        data={pipe(
          filter((event) => !event?.comingSoon),
          eventsSort,
        )([...masterclasses, ...workshops])}
        loading={loading}
        loadFailed={loadFailed}
        name={`Live Classes`}
        limit={6}
        viewMore={() => history.push('/live-classes')}
        forceViewMore
      />

      <div className='sectionHeaderContainer'>
        <h1 className={'sectionHeader'}>Other Live Sessions</h1>

        <p className='sectionSubHeader'>
          Unlock the world of knowledge with our free live workshops—an
          invaluable resource offering expert-led sessions, enriching content,
          and a community-driven learning experience at no cost.
        </p>
      </div>
      <MasterclassList
        data={pipe(
          filter((event) => !event?.comingSoon),
          eventsSort,
        )([...demo, ...btm, ...qna, ...concert, ...gigs])}
        loading={loading}
        loadFailed={loadFailed}
        name={`Other Live Sessions`}
        limit={6}
        viewMore={() => history.push('/live-classes')}
        forceViewMore
      />
    </div>
  )
}

export default withRouter(FeaturedMasterclasses)
