import React from 'react'
import CourseSuggestion from './CourseSuggestion'
import TeacherSuggestion from './TeacherSuggestion'
import InstrumentSuggestion from './InstrumentSuggestion'

const Option = ({ getStyles, data, innerRef, innerProps, ...props }) => (
  <div
    className={'option'}
    style={getStyles('option', { innerProps, ...props })}
    onClick={() => document.activeElement.blur()}
  >
    {data.isCourse && (
      <CourseSuggestion {...data} {...innerProps} ref={innerRef} />
    )}
    {data.isTeacher && (
      <TeacherSuggestion {...data} {...innerProps} ref={innerRef} />
    )}
    {data.isInstrument && (
      <InstrumentSuggestion {...data} {...innerProps} ref={innerRef} />
    )}
    {!data.isCourse && !data.isTeacher && !data.isInstrument && (
      <TeacherSuggestion {...data} {...innerProps} ref={innerRef} noIcon />
    )}
  </div>
)

export default Option
