import { Container } from 'unstated'
import { submitBcReview } from '../../../../../API/Course'
import { notify } from '../../../../../Helpers/Notifications'

const initialState = () => ({
  loading: true,
  loadFailed: false,
  submittedReview: null,
  courseID: '',
  teacherID: '',
  registration: '',
  groupChatId: '',
  courseInfo: {},
  success: false,
})

export default class LeaveMasterclassReviewStore extends Container {
  name = 'LeaveMasterclassReviewStore'

  state = initialState()

  linkedStores = {}

  submitReview = async (values, type, classId, teacherId) => {
    let isValid = !!(values.courseReview || values.teacherReview)

    if (!isValid) {
      notify(
        'error',
        `At least one of 'Artist Review' or 'Course Review must be filled'`,
      )
      return
    }

    const success = await submitBcReview({
      entityId: classId,
      teacherId: teacherId,
      entityType: 'BROADCAST',
      entitySubType: type,
      teacherReview: values.teacherReview,
      teacherRating: values.teacherRating,
      entityReview: values.courseReview,
      entityRating: values.courseRating,
    })

    await this.setState({ success })

    return success
  }

  bindStore = (store) => {
    this.linkedStores[store.name] = store
  }
}
