import React from 'react'
import { Link } from 'react-router-dom'

const CourseSuggestion = ({ label, value }) => {
  return (
    <Link to={value}>
      <div className='courseSuggestion'>
        <span className='searchIcon courseIcon' />
        <p>{label}</p>
      </div>
    </Link>
  )
}

export default CourseSuggestion
