import React, { useState } from 'react'
import Textarea from 'react-textarea-autosize'
import { getField } from '../../../Helpers/Misc'

const TextArea = ({
  type,
  field = {},
  form = {},
  placeholder,
  limit,
  editstate,
  ...props
}) => {
  const [loading, setLoading] = useState(false)

  const onChange = async (e) => {
    setLoading(true)
    e.target.value = e.target.value.slice(0, limit)
    form.setFieldValue && form.setFieldValue(field.name, e.target.value)
    form.setFieldTouched && form.setFieldTouched(field.name, true)
    props.onChange ? await props.onChange(e) : await field.onChange(e)
    setLoading(false)
  }

  let className =
    loading && editstate
      ? 'loading'
      : !loading && editstate && form.touched[field.name]
      ? 'saved'
      : props.className

  return (
    <div className={'inputWrapper' + (props.disabled ? ' disabled' : '')}>
      <Textarea
        type={type || 'text'}
        placeholder=' '
        {...props}
        {...field}
        onChange={onChange}
        className={className}
      />
      <p className={'placeholder'}>
        {placeholder}
        {limit && (
          <span>
            {(getField(props, 'value') && getField(props, 'value').length) ||
              (getField(field, 'value') && getField(field, 'value').length) ||
              0}
            /{limit}
          </span>
        )}
      </p>
      <p className={'error'}>
        {(form.errors && form.touched[field.name] && form.errors[field.name]) ||
          props?.error?.message}
      </p>
    </div>
  )
}

export default TextArea
