import React from 'react'
import Switch from 'react-switch'

const Toggle = ({ placeholder, field = {}, form = {}, options, ...props }) => {
  return (
    <div className='inputWrapper switch'>
      <Switch
        onChange={() =>
          props.onChange
            ? props.onChange(!props.value)
            : form.setFieldValue(field.name, !field.value)
        }
        checked={props.value !== undefined ? props.value : field.value}
        offColor={'#c7c7c7'}
        onColor={'#EC9871'}
        offHandleColor={'#000'}
        onHandleColor={'#000'}
        boxShadow={'0 0 4px rgba(0,0,0,0.45)'}
        uncheckedIcon={false}
        checkedIcon={false}
        height={14}
        width={36}
        handleDiameter={18}
        {...props}
      />
      <p className={'placeholder'}>{placeholder}</p>
      <p className={'error'}>
        {form.errors && form.touched[field.name] && form.errors[field.name]}
      </p>
    </div>
  )
}

export default Toggle
